import { useDispatch, useSelector, batch } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { RootStore } from '../../../redux/store'
import { getDeskName, cleanUsername } from '../utils/utils'
import { BookingGridContainer } from '../components'
import { BookingContainer } from '../MyBookings'
import { BookinGridViewProps } from './types'
import { BookingStatus } from './enums'
import { BookingZone } from '../../../services/booking/types'
import {
  setBookingSliderPosition,
  setDeskBookingDashboardResults,
  setDeskBookingShowFloorplan,
  setDeskBookingShowGridView,
  setDeskBookingFocussedZoneID,
  setBookingFloorplanViewingDate,
  setDeskBookingLoading,
} from '../../../redux/reducers/deskBookingReducer'
import noDesk from '../../../assets/NoDesk.svg'
import Paragraph from '../../../shared/UI/Paragraph'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import { NoDesk } from './components'
import { BookingSliderPosition, MONTHS } from '../../../utils/constants'
import {
  setDeskBookingSearchParams,
  setDeskBookingShowSearch,
} from '../../../redux/reducers/deskBookingSearchReducer'
import { showComponents, defaultZoneIdIfNoneSelected } from '../utils'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'
import { checkIsOpenFloorplanArea } from './bookingFloorplanLogic'

const { CANCELLED } = BookingStatus

export default function BookingGridView({
  onCancel,
  onDataLoaded,
  onShowMeShared,
}: BookinGridViewProps) {
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const [setSelectedZone] = useState<BookingZone>()

  const dispatch = useDispatch()

  const { locations, floorplans, zones, gridViewingDate, dashboardResults, bookingIsLoading } =
    useSelector((state: RootStore) => state.deskBooking)

  const employeeFloorplans = useMemo(
    () =>
      floorplans.filter(f => f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY],
    [employeeDetails.location.id, floorplans]
  )

  const employeeZones = useMemo(
    () =>
      zones.filter(
        z =>
          employeeFloorplans.some(s => s.id === z.floorPlanId) &&
          z.belongsTo === employeeDetails.departmentId
      ),
    [employeeDetails.departmentId, employeeFloorplans, zones]
  )

  const [loading, setLoading] = useState<boolean>(true)

  const thisMonthName = MONTHS[gridViewingDate.getMonth()]

  useEffect(() => {
    setLoading(bookingIsLoading)

    if (!loading && !bookingIsLoading && dashboardResults && dashboardResults.results[0]) {
      onDataLoaded()
    }
  }, [dashboardResults, bookingIsLoading])

  return (
    <BookingGridContainer id="gridview">
      <Fade in={!loading} appear={false} timeout={200}>
        <Box
          width="auto"
          height={bookingIsLoading || !dashboardResults?.results[0] ? '0%' : '100%'}
        >
          {dashboardResults &&
            dashboardResults.results.map(m => {
              const { feature } = m
              const featureZone = feature.zone
              const deskName = getDeskName(feature.additionalInfo, feature.label)
              const floorplanId = feature.floorPlanId
              const floorplan = floorplans.find(f => f.id === floorplanId)
              const fromDate = new Date(m.fromDate)
              const isOpenFloorplanArea = checkIsOpenFloorplanArea(floorplan?.locationId || 0, floorplanId)
              const openFloorplanAreaOrZone = isOpenFloorplanArea ? '' : featureZone?.name || ''
              const checkForInvalidBooking = isOpenFloorplanArea ? false : !featureZone?.id && (m.department !== "" || feature.additionalInfo === "")
              const deskNameStartsWithFloorNumber = /^\d/.test(deskName)
              const floorplanBuildingName = floorplan?.name.split('-')[0].trim()

              if (m.statusId !== CANCELLED && gridViewingDate.getMonth() === fromDate.getMonth()) {
                return (
                  <BookingContainer
                    key={`${deskName}-${m.id}-`}
                    bookingID={m.id}
                    date={fromDate}
                    dateFrom={new Date(`${m.fromDate} ${m.fromTime}`)}
                    dateTo={new Date(`${m.fromDate} ${m.toTime}`)}
                    location={!isOpenFloorplanArea && !deskNameStartsWithFloorNumber ? `${floorplan?.name}` : `${floorplan?.locationName} (${floorplanBuildingName})`}
                    zone={openFloorplanAreaOrZone}
                    invalidBooking={checkForInvalidBooking}
                    featureLabel={deskName}
                    components={
                      m.feature.components ? m.feature.components?.map(c => c.name).join(', ') : ''
                    }
                    isCheckedIn={Boolean(m.checkInOut.length)}
                    bookedFor={
                      cleanUsername(m.displayName) !== cleanUsername(userInfo?.name)
                        ? m.displayName
                        : ''
                    }
                    onCancel={featureLabel => {
                      onCancel?.({
                        bookingID: m.id,
                        deskName,
                        floorPlanName: floorplan?.name || '',
                        location: floorplan?.locationName || '',
                        date: new Date(m.fromDate),
                        byManager: false,
                        onCallBack: success => {
                          if (success) {
                            dispatch(
                              setDeskBookingDashboardResults(
                                dashboardResults.results.filter(f => f.id !== m.id)
                              )
                            )
                          }
                        },
                      })
                    }}
                    onShowMe={() => {
                      dispatch(
                        setBookingSliderPosition({
                          slidePosition: BookingSliderPosition.FLOOR_PLAN,
                          buttonClick: false,
                        })
                      )
                      dispatch(setDeskBookingDashboardResults([]))

                      const { fromTime, toTime } = m
                      const { locationId, floorPlanId, zone } = feature

                      const zoneId: number =
                        zone?.id ||
                        zones.find(
                          f =>
                            // f.belongsTo === employeeDetails.departmentId &&
                            f.floorPlanId === floorPlanId
                        )?.id ||
                        defaultZoneIdIfNoneSelected(employeeZones) ||
                        0

                      const thisShowMeSearchParams = {
                        locationId,
                        floorplanId: floorPlanId,
                        date: new Date(fromDate),
                        from: fromTime,
                        to: toTime,
                        zoneId,
                        autoSearch: true,
                        // featureId: id,
                      }

                      batch(() => {
                        showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })
  
                        dispatch(setDeskBookingLoading(true))
                        dispatch(setDeskBookingFocussedZoneID(zoneId))
                        dispatch(setBookingFloorplanViewingDate(new Date(fromDate)))
                        dispatch(setDeskBookingSearchParams(thisShowMeSearchParams))

                        // dispatch(setBookingFloorplanViewingDate(new Date(m.fromDate)))
                        dispatch(setDeskBookingShowSearch(false))
                        dispatch(setDeskBookingShowFloorplan(true))
                        dispatch(setDeskBookingShowGridView(false))
                      })

                      onShowMeShared(thisShowMeSearchParams)
                    }}
                  />
                )
              }

              return ''
            })}
        </Box>
      </Fade>

      {(!dashboardResults || dashboardResults === null || !dashboardResults.results[0]) &&
        bookingIsLoading && (
          <Box display="flex" height="470px" flexDirection="column" justifyContent="top">
            <Box display="flex" alignItems="center" flexDirection="column">
              <LoadingIndicator show alignItems="center" margin="20px" />
              <Paragraph size="16px" weight="bold" color="#2c2965">
                Loading desk bookings for&nbsp;
                {thisMonthName}
                ...
              </Paragraph>
            </Box>
          </Box>
        )}
      {!dashboardResults ||
        (!dashboardResults.results[0] && !bookingIsLoading && (
          <Fade in appear timeout={400}>
            <Box display="flex" height="470px" flexDirection="column" justifyContent="center">
              <Box display="flex" alignItems="center" flexDirection="column">
                <NoDesk src={noDesk} />
                <Paragraph size="16px" weight="bold" color="#2c2965">
                  You don&apos;t have any desks booked for&nbsp;
                  {thisMonthName}
                </Paragraph>
              </Box>
            </Box>
          </Fade>
        ))}
    </BookingGridContainer>
  )
}
