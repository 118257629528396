import React, { createContext, useContext, useState, useRef, ReactNode, useMemo } from 'react';
import {
    BookingComponent,
    BookingFeature,
    BookingFloorPlans,
    BookingLocations,
    ZoneFeature
} from '../../../services/booking/types';

interface DesignerContextType {
    floorPlanFeatures: BookingFeature[];
    setFloorPlanFeatures: React.Dispatch<React.SetStateAction<BookingFeature[]>>;
    featureIndex: number;
    setFeatureIndex: React.Dispatch<React.SetStateAction<number>>;
    zones: ZoneFeature[];
    setZones: React.Dispatch<React.SetStateAction<ZoneFeature[]>>;
    floorPlanId: number;
    setFloorPlanId: React.Dispatch<React.SetStateAction<number>>;
    locationId: number;
    setLocationId: React.Dispatch<React.SetStateAction<number>>;
    nextZoneId: number;
    setNextZoneId: React.Dispatch<React.SetStateAction<number>>;
    availableFeatures: BookingComponent[];
    setAvailableFeatures: React.Dispatch<React.SetStateAction<BookingComponent[]>>;
    svgRef: React.RefObject<SVGSVGElement | null>;
    selectedFloorPlanName: string;
    setSelectedFloorPlanName: React.Dispatch<React.SetStateAction<string>>;
    floorPlans: BookingFloorPlans;
    setFloorPlans: React.Dispatch<React.SetStateAction<BookingFloorPlans>>;
    locations: BookingLocations;
    setLocations: React.Dispatch<React.SetStateAction<BookingLocations>>;
    isSelecting: boolean;
    setIsSelecting: React.Dispatch<React.SetStateAction<boolean>>;
    selectedFeatureIds: number[];
    setSelectedFeatureIds: React.Dispatch<React.SetStateAction<number[]>>;
    isBulkEditDialogOpen: boolean;
    setIsBulkEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesignerContext = createContext<DesignerContextType | null>(null);

interface DesignerProviderProps {
    children: ReactNode;
}

function DesignerProvider({ children }: DesignerProviderProps) {
    const [floorPlanFeatures, setFloorPlanFeatures] = useState<BookingFeature[]>([]);
    const [featureIndex, setFeatureIndex] = useState<number>(1000000);
    const [zones, setZones] = useState<ZoneFeature[]>([]);
    const [floorPlanId, setFloorPlanId] = useState<number>(0);
    const [locationId, setLocationId] = useState<number>(0);
    const [nextZoneId, setNextZoneId] = useState<number>(1);
    const [availableFeatures, setAvailableFeatures] = useState<BookingComponent[]>([]);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const [selectedFloorPlanName, setSelectedFloorPlanName] = useState<string>('');
    const [floorPlans, setFloorPlans] = useState<BookingFloorPlans>({ floorPlans: [] });
    const [locations, setLocations] = useState<BookingLocations>({ locations: [] });
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedFeatureIds, setSelectedFeatureIds] = useState<number[]>([]);
    const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false);

    const contextValue = useMemo(() => ({
        floorPlanFeatures,
        setFloorPlanFeatures,
        featureIndex,
        setFeatureIndex,
        zones,
        setZones,
        floorPlanId,
        setFloorPlanId,
        locationId,
        setLocationId,
        nextZoneId,
        setNextZoneId,
        availableFeatures,
        setAvailableFeatures,
        svgRef,
        selectedFloorPlanName,
        setSelectedFloorPlanName,
        floorPlans,
        setFloorPlans,
        locations,
        setLocations,
        isSelecting,
        setIsSelecting,
        selectedFeatureIds,
        setSelectedFeatureIds,
        isBulkEditDialogOpen,
        setIsBulkEditDialogOpen,
    }), [
        floorPlanFeatures,
        setFloorPlanFeatures,
        featureIndex,
        setFeatureIndex,
        zones,
        setZones,
        floorPlanId,
        setFloorPlanId,
        locationId,
        setLocationId,
        nextZoneId,
        setNextZoneId,
        availableFeatures,
        setAvailableFeatures,
        svgRef,
        selectedFloorPlanName,
        setSelectedFloorPlanName,
        floorPlans,
        setFloorPlans,
        locations,
        setLocations,
        isSelecting,
        setIsSelecting,
        selectedFeatureIds,
        setSelectedFeatureIds,
        isBulkEditDialogOpen,
        setIsBulkEditDialogOpen,
    ]);

    return (
        <DesignerContext.Provider value={contextValue}>
            {children}
        </DesignerContext.Provider>
    );
}

export const useDesignerContext = () => {
    const context = useContext(DesignerContext);
    if (!context) {
        throw new Error('useDesignerContext must be used within a DesignerProvider');
    }
    return context;
};

export { DesignerProvider };