import {useCallback} from "react";
import {BookingComponent, DeskFeature, LabelFeature} from "../../../../services/booking/types";
import {useDesignerContext} from "../DesignerContext";

export const useLabelFeatures = () => {
    const {
        setFloorPlanFeatures,
    } = useDesignerContext();

    const updateLabel = useCallback(
        (id: number, updates: Partial<Pick<LabelFeature, 'label' | 'color' | 'fontSize' | 'chip'>>) => {
            setFloorPlanFeatures((currentFeatures) =>
                currentFeatures.map((feature) => {
                    if (feature.id !== id) return feature;
                    if (feature.typeId === 9) {
                        return {...feature, ...updates};
                    }
                    return feature;
                })
            );
        },
        [setFloorPlanFeatures]
    );

    const updateLabelFeatureProperties = useCallback(
        (
            id: number,
            updates: {
                label: string;
                assetTag?: string;
                qrCode?: string;
                deskDescription?: string;
                components?: BookingComponent[];
            }
        ) => {
            setFloorPlanFeatures((currentFeatures) =>
                currentFeatures.map((feature) => {
                    if (feature.id !== id) return feature;

                    // Parse existing additionalInfo
                    let parsedAdditionalInfo: { [key: string]: any } = {};
                    try {
                        parsedAdditionalInfo = feature.additionalInfo ? JSON.parse(feature.additionalInfo) : {};
                    } catch (error) {
                        console.error('Error parsing additionalInfo:', error);
                    }

                    // Update additionalInfo properties
                    if (updates.assetTag !== undefined) {
                        parsedAdditionalInfo.assetTag = updates.assetTag;
                        (feature as DeskFeature).assetTag = updates.assetTag;
                    }
                    if (updates.qrCode !== undefined) {
                        parsedAdditionalInfo.qrCode = updates.qrCode;
                        (feature as DeskFeature).qrCode = updates.qrCode;
                    }
                    if (updates.deskDescription !== undefined) {
                        parsedAdditionalInfo.desc = { deskDesc: updates.deskDescription};
                        (feature as DeskFeature).desc = { deskDesc: updates.deskDescription};
                    }

                    // Stringify updated additionalInfo
                    const additionalInfoString = JSON.stringify(parsedAdditionalInfo);

                    return {
                        ...feature,
                        label: updates.label || feature.label,
                        additionalInfo: additionalInfoString,
                        components: updates.components || feature.components,
                    };
                })
            );
        },
        [setFloorPlanFeatures]
    );

    return {
        updateLabelFeatureProperties,
        updateLabel
    }
}