import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import Paragraph from '../../../../shared/UI/Paragraph'
import {BookingDrawDeskProps} from "../types";
import {BookingDesignerPopover} from "./layoutUtils";

const DEFAULT_COLUMNS = 1
const DEFAULT_ROWS = 1

function DrawDesks({ onSelected }: BookingDrawDeskProps) {
  const [columnCount, setColumnCount] = useState<number>(DEFAULT_COLUMNS)
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROWS)
  const [columns, setColumns] = useState<number>(1)
  const [rows, setRows] = useState<number>(1)

  useEffect(() => {
    setColumnCount(DEFAULT_COLUMNS)
    setRowCount(DEFAULT_ROWS)
  }, [])

  return (
    <BookingDesignerPopover width={`${columnCount * 50}px`} height="auto" x="0" y="90%">
      <Grid container>
        {Array(rowCount)
          .fill(0)
          .map((_r, rIdx) => (
            <Grid item xs={12}>
              <Grid container mt="3px">
                {Array(columnCount)
                  .fill(0)
                  .map((_m, cIdx) => (
                    <Grid item xs={12 / columnCount} height="40px">
                      <Box
                        height="100%"
                        margin="0 1px 1px 0"
                        border="1px solid #a1a1a1"
                        bgcolor={cIdx + 1 < columnCount && rIdx + 1 < rowCount ? '#e1e1e1' : '#fff'}
                        onMouseEnter={() => {
                          setColumns(cIdx + 1)
                          setRows(rIdx + 1)
                          if (cIdx >= DEFAULT_COLUMNS - 1) {
                            setColumnCount(cIdx + 2)
                          } else {
                            setColumnCount(DEFAULT_COLUMNS)
                          }
                          if (rIdx >= DEFAULT_ROWS - 1) {
                            setRowCount(rIdx + 2)
                          } else {
                            setRowCount(DEFAULT_ROWS)
                          }
                        }}
                        onClick={() => onSelected(columns, rows)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Paragraph>{`${columns} cols x ${rows} rows`}</Paragraph>
        </Grid>
      </Grid>
    </BookingDesignerPopover>
  )
}

export default DrawDesks
