import styled, { css } from 'styled-components'
import { Autorenew as AutorenewIcon } from '@mui/icons-material'

export interface DeskCircleProps {
  backgroundColor?: string
}

const getDraggingModeStyle = (props: {isDragging?: boolean }) => {
  if(props?.isDragging) {
      return ( 
        css` 
          cursor: move !important;
          opacity: 0.5;
        `
      )
   }
}

export const Group = styled.g<{ highlightColor: string, isDragging?: boolean }>`
   {
    @keyframes bookingGroupfadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.1;
      }
    }
    ${getDraggingModeStyle}
    ${props =>
      `&:hover g circle:nth-child(2) {
      animation: bookingGroupfadeIn 100ms;
      fill: ${props.highlightColor};
    }`}
  }
`
