// src/components/Designer/utils/zoneUtils.ts  

import { BookingFeature } from "../../../../services/booking/types";

export function parseAdditionalInfoToPoints(additionalInfo: string): [number, number][] {
    try {
        const info = JSON.parse(additionalInfo);
        const svg = info.svg[0];
        if (svg.type === 'polygon') {
            return svg.points;
        } if (svg.type === 'rect') {
            const { x, y, width, height } = svg;
            return [
                [x, y],
                [x + width, y],
                [x + width, y + height],
                [x, y + height],
            ];
        }
    } catch (e) {
        // Replace console.error with a proper logging mechanism if needed
        console.error('Error parsing additionalInfo:', e);
    }
    return [];
}

export function pointInPolygon(point: [number, number], polygon: [number, number][]): boolean {
    let inside = false;
    const [x, y] = point;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i, i += 1) {
        const xi = polygon[i][0]; const yi = polygon[i][1];
        const xj = polygon[j][0]; const yj = polygon[j][1];

        if ((yi > y) !== (yj > y)) {
            const xIntersect = ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (x < xIntersect) {
                inside = !inside;
            }
        }
    }
    return inside;
}

export function isDeskInZone(desk: BookingFeature, zonePoints: [number, number][]): boolean {
    const x = desk.x + desk.width / 2;
    const y = desk.y + desk.height / 2;
    return pointInPolygon([x, y], zonePoints);
}