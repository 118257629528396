import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import * as d3 from 'd3';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LabelFeature } from '../../../../services/booking/types';
import { useLabelFeatures } from "../hooks/useLabelFeatures";
import { useFloorplanFeatures } from "../hooks/useFloorplanFeatures";

interface DesignerTextProps {
    featureDetail: LabelFeature;
    svgRef: React.RefObject<SVGSVGElement>;
}

function DesignerText({
                          featureDetail,
                          svgRef,
                      }: Readonly<DesignerTextProps>) {
    const {
        updateLabel
    } = useLabelFeatures();

    const {
        updateFeaturePosition,
        deleteFeature
    } = useFloorplanFeatures();

    const textRef = useRef<SVGGElement | null>(null);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [text, setText] = useState<string>(featureDetail.label);
    const [color, setColor] = useState<string>(featureDetail.color);
    const [fontSize, setFontSize] = useState<number>(featureDetail.fontSize);
    const [chip, setChip] = useState<boolean>(featureDetail.chip || false);

    const [isStyleDialogOpen, setIsStyleDialogOpen] = useState<boolean>(false);

    // Refs to store the current position  
    const currentPosition = useRef<{ x: number; y: number }>({ x: featureDetail.x, y: featureDetail.y });

    // Offset between mouse position and item's position at the start of drag  
    const offset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    useEffect(() => {
        setText(featureDetail.label);
    }, [featureDetail.label]);

    useEffect(() => {
        setColor(featureDetail.color);
    }, [featureDetail.color]);

    useEffect(() => {
        setFontSize(featureDetail.fontSize);
    }, [featureDetail.fontSize]);

    useEffect(() => {
        setChip(featureDetail.chip || false);
    }, [featureDetail.chip]);

    useEffect(() => {
        if (!textRef.current || !svgRef.current) return;

        const svgElement = svgRef.current;

        const group = d3.select(textRef.current);

        group.selectAll('*').remove();

        const textElement = group
            .append('text')
            .attr('x', 0)
            .attr('y', 0)
            .attr('fill', color)
            .attr('font-size', fontSize)
            .attr('font-family', 'Poppins') // Adjust the font family as needed  
            .text(text)
            .style('cursor', 'move');

        const textBBox = textElement.node()?.getBBox();
        const textWidth = textBBox ? textBBox.width : 0;
        const textHeight = textBBox ? textBBox.height : 0;

        if (chip) {
            // Render chip background  
            group
                .insert('rect', 'text')
                .attr('x', -textWidth / 2 - 10)
                .attr('y', -textHeight / 2 - 5)
                .attr('width', textWidth + 20)
                .attr('height', textHeight + 10)
                .attr('fill', '#e0e0e0')
                .attr('rx', 5)
                .attr('ry', 5);
        }

        textElement
            .attr('x', 0)
            .attr('y', 0)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle');

        // Set the initial position of the group  
        group.attr('transform', `translate(${currentPosition.current.x}, ${currentPosition.current.y})`);

        // Drag behavior to move text  
        const drag = d3.drag<SVGGElement, unknown>()
            .on('start', (event) => {
                if (!svgElement) return;

                // Get the initial mouse coordinate in SVG space  
                const [x0, y0] = d3.zoomTransform(svgElement).invert([event.sourceEvent.clientX, event.sourceEvent.clientY]);

                // Calculate the offset between the mouse position and the item's position  
                offset.current.x = x0 - currentPosition.current.x;
                offset.current.y = y0 - currentPosition.current.y;
            })
            .on('drag', function handleDrag(event) {
                if (!svgElement) return;

                // Get the current mouse coordinate in SVG space  
                const [x0, y0] = d3.zoomTransform(svgElement).invert([event.sourceEvent.clientX, event.sourceEvent.clientY]);

                // Calculate the new position  
                const newX = x0 - offset.current.x;
                const newY = y0 - offset.current.y;

                // Update current position  
                currentPosition.current.x = newX;
                currentPosition.current.y = newY;

                // Apply the updated position  
                d3.select(this).attr('transform', `translate(${newX}, ${newY})`);
            })
            .on('end', (event) => {
                if (!svgElement) return;

                // Update the feature's position in the parent component  
                updateFeaturePosition(featureDetail.id, currentPosition.current.x, currentPosition.current.y);
            });

        // Apply drag behavior to the group  
        group.call(drag);

        if (isEditing) {
            // ... (existing code for editing the text)  
        }

        // Event handling  
        group.on('dblclick', (event) => {
            event.stopPropagation();
            setIsEditing(true);
        });

        group.on('contextmenu', (event) => {
            event.preventDefault();
            setIsStyleDialogOpen(true);
        });
    }, [
        featureDetail.id,
        featureDetail.x,
        featureDetail.y,
        text,
        color,
        fontSize,
        chip,
        svgRef,
        isEditing,
        updateFeaturePosition
    ]);

    const handleStyleDialogClose = () => {
        setIsStyleDialogOpen(false);
    };

    const handleStyleDialogSave = () => {
        updateLabel(featureDetail.id, { label: text });
        updateLabel(featureDetail.id, { color });
        updateLabel(featureDetail.id, { fontSize });
        updateLabel(featureDetail.id, { chip });
        setIsStyleDialogOpen(false);
    };

    const handleDelete = () => {
        deleteFeature(featureDetail.id);
        setIsStyleDialogOpen(false);
    };

    const handleChipToggle = (event: ChangeEvent<HTMLInputElement>) => {
        setChip(event.target.checked);
    };

    return (
        <>
            <g ref={textRef} />
            {/* Style Dialog */}
            <Dialog open={isStyleDialogOpen} onClose={handleStyleDialogClose}>
                <DialogTitle>Edit Text</DialogTitle>
                <DialogContent>
                    <TextField
                      label="Text"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Color"
                      type="color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                    />
                    <TextField
                      label="Font Size"
                      type="number"
                      value={fontSize}
                      onChange={(e) => setFontSize(Number(e.target.value))}
                      fullWidth
                      InputProps={{ inputProps: { min: 6, max: 100 } }} // Set min and max font size  
                      margin="normal"
                    />
                    <FormControlLabel
                      control={
                            <Checkbox
                              checked={chip}
                              onChange={handleChipToggle}
                              color="primary"
                            />
                        }
                      label="Show as Chip"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                    <Button onClick={handleStyleDialogClose}>Cancel</Button>
                    <Button
                      onClick={handleStyleDialogSave}
                      variant="contained"
                      color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignerText;