// Designer.tsx
import React, {useState, useEffect} from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText, TextField,
} from '@mui/material';
import BookingFloorplanView from '../Shared/BookingFloorplanView';
import {BookingAvailabilityColors} from '../consts';
import {useFloorplanSync} from './hooks/useFloorplanSync';
import DesignerZone from './components/DesignerZone';
import DesignerDesk from './components/DesignerDesk';
import DesignerOffice from './components/DesignerOffice';
import DesignerWall from './components/DesignerWall';
import DesignerText from './components/DesignerText';
import Toolbar from './components/Toolbar';
import {
    WallFeature,
    DeskFeature,
} from '../../../services/booking/types';
import Card from '../../../shared/layout/Card';
import {useDesignerContext} from './DesignerContext';
import {useDesigner} from "./hooks/useDesigner";
import {fetchFeatureComponents} from "./Utils/fetchFeatureComponents";

const DISPLAY_WIDTH = 1436;
const DISPLAY_HEIGHT = 600;
const LARGE_WIDTH = 1435;
const LARGE_HEIGHT = 599;

function Designer() {
    const {
        floorPlanFeatures,
        zones,
        availableFeatures,
        setAvailableFeatures,
        svgRef,
    } = useDesignerContext();

    const {
        handleBackgroundClick,
        handleSvgMouseUp,
        handleBulkEditDialogSave,
        handleOnSelected,
        handleSvgMouseDown,
        handleSvgMouseMove,
        handleSelectFeature,
        initializeZoom,
        isBulkEditDialogOpen,
        gRef,
        selectedFeatureIds,
        snapToGridWhileDragging,
        isSelecting,
        selectionRect,
        setIsBulkEditDialogOpen,
        bulkRotationAngle,
        setBulkRotationAngle,
        bulkSelectedFeatures,
        setBulkSelectedFeatures,
        bulkDeskDesc,
        setBulkDeskDesc,
    } = useDesigner();

    const [loadViewer, setLoadViewer] = useState(false);

    useFloorplanSync(setLoadViewer);

    useEffect(() => {
        fetchFeatureComponents(availableFeatures).then((data) => {
            setAvailableFeatures(data);
        });
    }, [setAvailableFeatures]);

    useEffect(() => {
        if (!loadViewer) {
            initializeZoom();
        }
    }, [loadViewer, initializeZoom]);

    useEffect(() => {
        console.log('floorPlanFeatures updated:', floorPlanFeatures);
    }, [floorPlanFeatures]);

    return (
        <>
            <Card padding="20px">
                <Toolbar
                  onSelected={handleOnSelected}
                  loadViewer={loadViewer}
                  setLoadViewer={setLoadViewer}
                />
                {loadViewer ? (
                    <Card padding="20px" style={{height: '600px', marginTop: '20px'}}>
                        <BookingFloorplanView onCancel={() => setLoadViewer(false)} />
                    </Card>
                ) : (
                    <Box
                      style={{
                            width: `${DISPLAY_WIDTH}px`,
                            height: `${DISPLAY_HEIGHT}px`,
                            border: '2px solid #e1e1e1',
                            borderRadius: '10px',
                            backgroundColor: '#f9f9f9',
                            marginTop: '20px',
                        }}
                    >
                        <svg
                          ref={svgRef as React.RefObject<SVGSVGElement>}
                          width={LARGE_WIDTH}
                          height={LARGE_HEIGHT}
                          viewBox={`0 0 ${LARGE_WIDTH} ${LARGE_HEIGHT}`}
                          xmlns="http://www.w3.org/2000/svg"
                          style={{backgroundColor: '#ffffff'}}
                          onMouseDown={handleSvgMouseDown}
                          onMouseMove={handleSvgMouseMove}
                          onMouseUp={handleSvgMouseUp}
                        >
                            <g ref={gRef}>
                                <rect
                                  x={0}
                                  y={0}
                                  width={LARGE_WIDTH}
                                  height={LARGE_HEIGHT}
                                  fill="#ffffff"
                                  onClick={handleBackgroundClick}
                                />
                                {zones.map((zone) => (
                                    <DesignerZone
                                      key={zone.id}
                                      zone={zone}
                                      svgRef={svgRef as React.RefObject<SVGSVGElement>}
                                    />
                                ))}
                                {floorPlanFeatures.map((feature) => {
                                    const isSelected = selectedFeatureIds.includes(feature.id);
                                    if (feature.typeId === 10) {
                                        // Office Feature  
                                        return (
                                            <DesignerOffice
                                              key={feature.id}
                                              featureDetail={feature}
                                              svgRef={svgRef as React.RefObject<SVGSVGElement>}
                                              snapToGrid={snapToGridWhileDragging}
                                            />
                                        );
                                    } 
                                    if (feature.typeId === 9) {
                                        // Text Feature  
                                        return (
                                            <DesignerText
                                              key={feature.id}
                                              featureDetail={feature}
                                              svgRef={svgRef as React.RefObject<SVGSVGElement>}
                                            />
                                        );
                                    } 
                                    if (feature.typeId === 7) {
                                        // Wall Feature  
                                        return (
                                            <DesignerWall
                                              key={feature.id}
                                              featureDetail={feature as WallFeature}
                                              svgRef={svgRef as React.RefObject<SVGSVGElement>}
                                            />
                                        );
                                    } 
                                    if (feature.typeId === 1) {
                                        // Desk Feature  
                                        return (
                                            <DesignerDesk
                                              key={feature.id}
                                              featureDetail={feature as DeskFeature}
                                              snapToGrid={snapToGridWhileDragging}
                                              isSelected={isSelected}
                                              onSelectFeature={handleSelectFeature}
                                              selectedFeatureIds={selectedFeatureIds}
                                              isActive
                                              requestedSlot={{bookingRange: [new Date(), new Date()]}}
                                              isBlockBooking={false}
                                              availabilityColor={BookingAvailabilityColors.AVAILABLE}
                                            />
                                        );
                                    } 
                                        return null;
                                    
                                })}
                                {/* Render selection rectangle */}
                                {isSelecting && selectionRect && (
                                    <rect
                                      x={selectionRect.x}
                                      y={selectionRect.y}
                                      width={selectionRect.width}
                                      height={selectionRect.height}
                                      fill="blue"
                                      fillOpacity={0.1}
                                      stroke="blue"
                                      strokeDasharray="4"
                                    />
                                )}
                            </g>
                        </svg>
                    </Box>
                )}
            </Card>
            {/* Bulk Edit Dialog */}
            <Dialog open={isBulkEditDialogOpen} onClose={() => setIsBulkEditDialogOpen(false)}>
                <DialogTitle>Edit Selected Desks</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Desk Description"
                        value={bulkDeskDesc}
                        onChange={(e) => setBulkDeskDesc(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="bulk-rotation-angle-label">Rotation Angle</InputLabel>
                        <Select
                          labelId="bulk-rotation-angle-label"
                          value={bulkRotationAngle}
                          onChange={(e) => setBulkRotationAngle(Number(e.target.value))}
                          label="Rotation Angle"
                        >
                            {[0, 45, 90, 135, 180, 225, 270, 315].map((angle) => (
                                <MenuItem key={angle} value={angle}>
                                    {angle}
°
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" sx={{minWidth: '550px'}}>
                        <InputLabel id="bulk-features-label">Features</InputLabel>
                        <Select
                          labelId="bulk-features-label"
                          multiple
                          value={bulkSelectedFeatures}
                          onChange={(e) => setBulkSelectedFeatures(e.target.value as number[])}
                          renderValue={(selected) =>
                                (selected as number[])
                                    .map(
                                        (id) =>
                                            availableFeatures.find((component) => component.id === id)?.name
                                    )
                                    .join(', ')
                            }
                        >
                            {availableFeatures.map((component) => (
                                <MenuItem key={component.id} value={component.id}>
                                    <Checkbox checked={bulkSelectedFeatures.includes(component.id)} />
                                    <ListItemText primary={component.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsBulkEditDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleBulkEditDialogSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
             { /* <br />
            <Card padding="20px">
                <pre>{JSON.stringify(floorPlanFeatures, null, 2)}</pre>
            </Card> */ }
        </>
    );
}

export default Designer;