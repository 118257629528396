import { useCallback } from "react";
import { WallFeature, BookingZone, BookingFeature } from "../../../../services/booking/types";
import { isDeskInZone, parseAdditionalInfoToPoints } from "../Utils/zoneUtils";
import { useDesignerContext } from '../DesignerContext';

export const useFloorplanFeatures = () => {
    const {
        setFloorPlanFeatures,
        featureIndex,
        setFeatureIndex,
        zones,
    } = useDesignerContext();

    const addFeature = useCallback((newFeature: BookingFeature) => {
        setFloorPlanFeatures((current) => {
            const updatedFeatures = [...current, newFeature];
            return updatedFeatures;
        });
        setFeatureIndex((index) => index + 1);
    }, [setFloorPlanFeatures, setFeatureIndex]);

    const updateFeaturePosition = useCallback(
        (id: number, x: number, y: number) => {
            setFloorPlanFeatures((currentFloorplanFeatures) => {
                const updatedFeatures = currentFloorplanFeatures.map((feature) => {
                    if (feature.id !== id) return feature;
                    let updatedFeature = { ...feature, x, y };

                    // For walls, update start and end points
                    if (feature.typeId === 7) {
                        const wallFeature = feature as WallFeature;
                        const deltaX = x - feature.x;
                        const deltaY = y - feature.y;

                        const newStartPoint = {
                            x: wallFeature.startPoint.x + deltaX,
                            y: wallFeature.startPoint.y + deltaY,
                        };
                        const newEndPoint = {
                            x: wallFeature.endPoint.x + deltaX,
                            y: wallFeature.endPoint.y + deltaY,
                        };

                        updatedFeature = {
                            ...wallFeature,
                            x,
                            y,
                            startPoint: newStartPoint,
                            endPoint: newEndPoint,
                        } as WallFeature;
                    }

                    // Now check if the feature is within any zone
                    let featureInZone = false;
                    let updatedZone: Partial<BookingZone> | undefined;

                    zones.find((zone) => {
                        const points = parseAdditionalInfoToPoints(zone.additionalInfo);
                        if (isDeskInZone(updatedFeature, points)) {
                            featureInZone = true;
                            updatedZone = { ...zone, status: 1 }; // Ensure status is a number
                            return true; // Stop the iteration
                        }
                        return false;
                    });

                    if (featureInZone) {
                        // Update the zone property
                        if (updatedFeature.typeId === 9) {
                            updatedFeature.fill = 'zone.colors.badge';
                        }
                        updatedFeature = { ...updatedFeature, zone: updatedZone };
                    } else if (updatedFeature.zone) {
                        // Remove the zone property if it exists
                        if (feature.typeId === 9) {
                            feature.fill = '';
                        }
                        const { zone, ...rest } = updatedFeature;
                        updatedFeature = rest;
                    }

                    return updatedFeature;
                });
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures, zones] // Added 'zones' to dependencies
    );

    const updateMultipleFeaturePositions = useCallback(
        (updates: { id: number; x: number; y: number }[]) => {
            setFloorPlanFeatures((currentFloorplanFeatures) => {
                const updatedFeatures = currentFloorplanFeatures.map((feature) => {
                    const updatedFeatureData = updates.find((update) => update.id === feature.id);
                    if (!updatedFeatureData) {
                        return feature;
                    }
                    let updatedFeature = { ...feature, x: updatedFeatureData.x, y: updatedFeatureData.y };

                    // For walls, update start and end points
                    if (feature.typeId === 7) {
                        const wallFeature = feature as WallFeature;
                        const deltaX = updatedFeatureData.x - feature.x;
                        const deltaY = updatedFeatureData.y - feature.y;

                        const newStartPoint = {
                            x: wallFeature.startPoint.x + deltaX,
                            y: wallFeature.startPoint.y + deltaY,
                        };
                        const newEndPoint = {
                            x: wallFeature.endPoint.x + deltaX,
                            y: wallFeature.endPoint.y + deltaY,
                        };

                        updatedFeature = {
                            ...wallFeature,
                            x: updatedFeatureData.x,
                            y: updatedFeatureData.y,
                            startPoint: newStartPoint,
                            endPoint: newEndPoint,
                        } as WallFeature;
                    }

                    // Now check if the feature is within any zone
                    let featureInZone = false;
                    let updatedZone: Partial<BookingZone> | undefined;

                    const foundZone = zones.find((zone) => {
                        const points = parseAdditionalInfoToPoints(zone.additionalInfo);
                        if (isDeskInZone(updatedFeature, points)) {
                            featureInZone = true;
                            updatedZone = { ...zone, status: 1 }; // Ensure status is a number
                            return true; // Stop the iteration
                        }
                        return false;
                    });

                    if (featureInZone) {
                        // Update the zone property
                        updatedFeature = { ...updatedFeature, zone: updatedZone };
                    } else if (updatedFeature.zone) {
                        // Remove the zone property if it exists
                        const { zone, ...rest } = updatedFeature;
                        updatedFeature = rest;
                    }

                    return updatedFeature;
                });
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures, zones]
    );

    const resizeFeature = useCallback(
        (id: number, width: number, height: number) => {
            setFloorPlanFeatures((currentFloorplanFeatures) => {
                const updatedFeatures = currentFloorplanFeatures.map((feature) => {
                    if (feature.id !== id) return feature;

                    // Update width and height
                    let updatedFeature = { ...feature, width, height };

                    // Now check if the feature is within any zone (in case resizing affects this)
                    let featureInZone = false;
                    let updatedZone: Partial<BookingZone> | undefined;

                    const foundZone = zones.find((zone) => {
                        const points = parseAdditionalInfoToPoints(zone.additionalInfo);
                        if (isDeskInZone(updatedFeature, points)) {
                            featureInZone = true;
                            updatedZone = { ...zone, status: 1 }; // Ensure status is a number
                            return true; // Stop the iteration
                        }
                        return false;
                    });

                    if (featureInZone) {
                        // Update the zone property
                        updatedFeature = { ...updatedFeature, zone: updatedZone };
                    } else if (updatedFeature.zone) {
                        // Remove the zone property if it exists
                        const { zone, ...rest } = updatedFeature;
                        updatedFeature = rest;
                    }

                    return updatedFeature;
                });
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures, zones]
    );

    const rotateFeature = useCallback(
        (id: number, rotationAngle: number) => {
            setFloorPlanFeatures((currentFloorplanFeatures) => {
                const updatedFeatures = currentFloorplanFeatures.map((feature) => {
                    if (feature.id !== id) return feature;

                    // Directly update the rotationAngle property
                    return {
                        ...feature,
                        rotationAngle,
                    };
                });
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures]
    );

    const deleteFeature = useCallback(
        (id: number) => {
            setFloorPlanFeatures((currentFeatures) => {
                const updatedFeatures = currentFeatures.filter((feature) => feature.id !== id);
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures]
    );

    const updateLabel = useCallback(
        (id: number, newLabel: string) => {
            // Update the label of the feature
            setFloorPlanFeatures((currentFeatures) => {
                const updatedFeatures = currentFeatures.map((feature) => {
                    if (feature.id !== id) {
                        return feature;
                    }

                    if (feature.typeId === 8) {
                        // Update zone name
                        return {
                            ...feature,
                            label: newLabel,
                            name: newLabel,
                        };
                    }
                    return {
                        ...feature,
                        label: newLabel,
                    };
                });
                return updatedFeatures;
            });
        },
        [setFloorPlanFeatures]
    );

    return {
        addFeature,
        updateFeaturePosition,
        resizeFeature,
        rotateFeature,
        deleteFeature,
        featureIndex,
        updateMultipleFeaturePositions,
        updateLabel
    };
};