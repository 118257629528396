import {useCallback, useState} from "react";
import {DeskFeature} from "../../../../services/booking/types";
import {useFloorplanFeatures} from "./useFloorplanFeatures";
import {useLabelFeatures} from "./useLabelFeatures";
import {useDesignerContext} from "../DesignerContext";

export const useDesignerDesk = (feature: DeskFeature) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedFeatures, setSelectedFeatures] = useState<number[]>([]);
    const [labelText, setLabelText] = useState<string>(feature.label);
    const [assetTag, setAssetTag] = useState<string>('');
    const [qrCode, setQrCode] = useState<string>('');
    const [rotationAngle, setRotationAngle] = useState<number>(feature.rotationAngle ?? 0);
    const [deskDescription, setDeskDescription] = useState<string>('');

    const {
        availableFeatures,
    } = useDesignerContext();

    const {
        deleteFeature,
        rotateFeature
    } = useFloorplanFeatures();

    const {
        updateLabelFeatureProperties
    } = useLabelFeatures();

    const getPortPosition = useCallback((): { x: number; y: number } => {
        // Desk dimensions
        const {width} = feature;
        const {height} = feature;
        const centerY = height / 2;

        // Port properties
        const portRadius = feature.ports?.[0]?.radius || 0;
        const portOverlapOffset = portRadius / 2;

        // Port fixed position
        const x = width + portOverlapOffset;
        const y = centerY;

        return {x, y};
    }, [feature.width, feature.height, feature.ports]);

    const getLabelPosition = useCallback(() => {
        // Desk dimensions
        const {width} = feature;
        const {height} = feature;

        // Label position at the center of the desk
        const x = width / 2;
        const y = height / 2;

        // Text alignment
        const textAnchor = 'middle';

        return {x, y, textAnchor};
    }, [feature.width, feature.height]);

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = () => {
        const selectedComponents = availableFeatures.filter((component) =>
            selectedFeatures.includes(component.id)
        );

        if (updateLabelFeatureProperties) {
            updateLabelFeatureProperties(feature.id, {
                label: labelText,
                assetTag,
                qrCode,
                deskDescription,
                components: selectedComponents,
            });
        }

        if (rotateFeature && rotationAngle !== feature.rotationAngle) {
            rotateFeature(feature.id, rotationAngle);
        }

        setIsDialogOpen(false);
    };

    const handleDelete = () => {
        deleteFeature(feature.id);
        setIsDialogOpen(false);
    };

    return {
        getPortPosition,
        getLabelPosition,
        handleDialogClose,
        handleDialogSave,
        handleDelete,
        setIsDialogOpen,
        isDialogOpen,
        selectedFeatures,
        setSelectedFeatures,
        labelText,
        setLabelText,
        assetTag,
        setAssetTag,
        qrCode,
        setQrCode,
        rotationAngle,
        setRotationAngle,
        deskDescription,
        setDeskDescription
    }
}