import { useCallback, useMemo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Box, Grid, Link,useMediaQuery, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import Card from '../../../shared/layout/Card'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import Button from '../../../shared/UI/Button'
import { enhancementsContent } from '../../../utils/constants'
import { EnhancementStub, SubmissionPeriod, SubmitStatus } from '../../../models/enhancement'
import NoResult from '../../../shared/UI/NoResult'
import StatusLabel from '../../../shared/UI/StatusLabel'
import { getLocalDateString } from '../../../utils/date-utils'
import { SytledEnhancementsGrid , NoResultComponent } from '../../MyActionsGrid/StyledMyActionsGrid'
import { isMobileDown } from '../../../theme/deviceChecks'


const baseUrl = '/enhancements'
const testid = 'enhancement-summary-'
const resolveToPath = (type: string, id: number) => {
  const resolver: { [key: string]: string } = {
    'on call': 'oncallrequest',
    'call out': 'calloutrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
  }
  return `${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}

const calculateStatusLabel = (row: EnhancementStub) => {
  switch(row.submitStatus) {
    case SubmitStatus.AMENDED:
      return 'Amended'
    case SubmitStatus.APPROVED:
      return 'Approved'
    case SubmitStatus.SUBMITTED:
      return 'Submitted'
    default:
      return row.submitStatus
  }
}

export type EnhancementsSummaryTableProps = {
  data?: EnhancementStub[]
  currentPeriod?: SubmissionPeriod
  pendingEnhancements: number
  disableSubmitButton: boolean
  isPeriodOpenForSubmissions: boolean
  totalEnhancementsToSubmit: number
  handleSubmitToPayroll: () => void
}

function headerText(testId: string, headerName?: string) {
  return (
    <div style={{ fontWeight: 'bold', color: 'black' }} data-testid={testId}>
      {headerName}
    </div>
  )
}

const EnhancementMobileColumns: GridColDef[] = [
  {
    field: 'displayName',
    headerName: 'Employee',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-displayName-${params.row.id}`}>
        <Link component="button" variant="body2">
          {params.value}
        </Link>
      </div>
    ),
  },
  {
    field: 'hours',
    headerName: 'Hours',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-hours-${params.row.id}`}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'enhancementType',
    headerName: 'Type',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-enhancementType-${params.row.id}`}>
        <TypeLabel label={getPalleteTypeByProp(params.value).label} type={params.value} />
      </div>
    ),
  },
  {
    field: 'comments',
    headerName: 'Comments',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-comments-${params.row.id}`}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'approvedBy',
    headerName: 'Approved By',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvedBy-${params.row.id}`}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvalDate-${params.row.id}`}>
        {params.row.approvalDate ? getLocalDateString(new Date(params.row.approvalDate)) : ''}
      </div>
    ),
    sortable: true,
},
  {
    field: 'submitStatus',
    headerName: 'Status',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText((params.field), params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-submitStatus-${params.row.id}`}>
        <StatusLabel status={calculateStatusLabel(params.row)} />
      </div>
    ),
  },
]



function CustomNoRowsOverlay() {
  return null 
}

export function EnhancementsSummaryTable({
  data,
  currentPeriod,
  pendingEnhancements,
  disableSubmitButton,
  isPeriodOpenForSubmissions,
  totalEnhancementsToSubmit,
  handleSubmitToPayroll,
}: EnhancementsSummaryTableProps) {
  const navigate = useNavigate()
  const { breakpoints } = useTheme()
  const mobile = useMediaQuery(isMobileDown())

  const handleRowClick = (type: string, id: number) => {
    if (currentPeriod?.year && currentPeriod?.period) {
      navigate(
        {
          pathname: baseUrl,
          search: `?${createSearchParams({
            year: currentPeriod?.year.toString(),
            period: currentPeriod?.period.toString(),
          })}`,
        },
        { replace: true }
      )
    }
    navigate(resolveToPath(type, id), {
      state: { previousLocation: baseUrl, params: { ...currentPeriod } },
    })
  }

  const pendingCount = useCallback(
    () =>
      pendingEnhancements > 0 ? (
        <span>
          <Typography component="span" fontSize='11px' fontWeight={600} color='#000000'>
            Approvals Pending
          </Typography>
          <Typography component="span" fontSize='12px' fontWeight={600} color='#555555' marginLeft='14px'>
            {pendingEnhancements}
          </Typography>
        </span>
      ) : (
        <></>
      ),
    [pendingEnhancements]
  )
  if (!data) {
    return <Typography variant="h5">{enhancementsContent.noData}</Typography>
  }

  const getSubmitButtonLabel = () => {
    if (isPeriodOpenForSubmissions && totalEnhancementsToSubmit === 0) {
      return enhancementsContent.submitToPayrollNothingToSubmit
    }

    if (disableSubmitButton) {
      return enhancementsContent.submitToPayrollPeriodClosed
    }

    return enhancementsContent.submitToPayrollActive(totalEnhancementsToSubmit)
  }

  const rows = data?.map((item, index) => ({ ...item, id: index })) || []

  const EnhancementColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Employee',
      headerClassName: 'header',
      flex: 1.5,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`${testid}row-${params.row.enhancementId}`}>
          <Link component="button" variant="body2">
            {params.value}
          </Link>
        </div>
      ),
    },
    {
      field: 'hours',
      headerName: 'Hours',
      headerClassName: 'header',
      flex: 0.5,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => <div data-testid={`cell-hours-${params.row.id}`}>{params.value}</div>,
    },
    {
      field: 'enhancementType',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-enhancementType-${params.row.id}`}>
          <TypeLabel label={getPalleteTypeByProp(params.value).label} type={params.value} />
        </div>
      ),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      headerClassName: 'header',
      flex: 2,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-comments-${params.row.id}`}>{params.value}</div>
      ),
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      headerClassName: 'header',
      flex: 1.5,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-approvedBy-${params.row.id}`}>{params.value}</div>
      ),
    },
    {
      field: 'approvalDate',
      headerName: 'Approval Date',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-approvalDate-${params.row.id}`}>
          {params.row.approvalDate ? getLocalDateString(new Date(params.row.approvalDate)) : ''}
        </div>
      ),
      sortable: true,
    },
    {
      field: 'submitStatus',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(params.field, params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-submitStatus-${params.row.id}`}>
          <StatusLabel status={calculateStatusLabel(params.row)} />
        </div>
      ),
    },
  ]

  return (
    <>
      <Card icon={pendingCount()} padding="24px 28px" noDivider>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box
              sx={{
                marginTop: '24px',
                minHeight: '100px',
                maxHeight: '250px',
                height: '500px',
                overflowX: mobile ? 'auto' : 'hidden', // Enable horizontal scrolling on mobile

                '& .MuiDataGrid-root': {
                  minHeight: '100px',
                },
              }}
              data-testid={`${testid}table`}
            >
              <SytledEnhancementsGrid
                rows={rows}
                columns={mobile ? EnhancementMobileColumns : EnhancementColumns}
                onRowClick={params =>
                  handleRowClick(params.row.enhancementType, params.row.enhancementId)
                }
                pinnedColumns={{ left: mobile ? ['displayName'] : [] }}
                sx={{
                  cursor: 'pointer',
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                rowHeight={52}
                headerHeight={48}
                pageSize={rows.length}
                hideFooter
                components={{
                  NoRowsOverlay: NoResultComponent,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            label={getSubmitButtonLabel()}
            type="submit"
            className="freetext"
            dataTestId="submit-to-payroll"
            onClick={handleSubmitToPayroll}
            disabled={disableSubmitButton}
          />
        </Grid>
      </Card>
    </>
  )
}
