import { Box, Grid, Stack } from '@mui/material'
import { useState, useEffect, useRef, useMemo , useCallback } from 'react'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'

import { addMonths, startOfMonth, subMonths, isSameMonth } from 'date-fns'
import { useDispatch, useSelector, batch } from 'react-redux'
import { ToggleButton, ToggleButtonGroup } from '../../../shared/UI/ToggleButton'
import theme from '../../../theme/theme'
import { RootStore } from '../../../redux/store'
import {
  setBookingFloorplanViewingDate,
  setBookingGridViewingDate,
  setDeskBookingDashboardResults,
  setDeskBookingLoading,
} from '../../../redux/reducers/deskBookingReducer'

import { BookingGridViewToggleProps } from './types'
import CardTitle from '../../../shared/UI/CardTitle'
import { dateToNumber } from '../utils/utils'

import {
  getDashboardBookings,
  onBookingGridViewDataLoaded,
} from '../bookingLogic'


const useDebouncedEffect = (effect:any, deps:any, delay:number) => {
  useEffect(() => {
      const handler = setTimeout(() => effect(), delay)

      return () => clearTimeout(handler)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}

interface toggleButtonSelectedDateProps {
  enabled: boolean,
  today: Date,
  newDate: Date
}

export function BookingGridViewToggle({ title }: BookingGridViewToggleProps) {

  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => () => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
  }, [])

  const dispatch = useDispatch()

  const [toggleButtonSelectedDate,
    setToggleButtonSelectedDate] = useState<toggleButtonSelectedDateProps>(
      {enabled: false, today:new Date(), newDate: new Date()})

  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const {
    gridViewingDate,
    showGridView,
    maxBookingDate,
  } = useSelector((state: RootStore) => state.deskBooking)

  useDebouncedEffect(() => batch(() => {

    const {
      enabled,
      today,
      newDate,
    } = toggleButtonSelectedDate

    if (!enabled || !newDate) {

      return
    }

    dispatch(setDeskBookingDashboardResults([]))
    dispatch(setDeskBookingLoading(true))

    const singleDayBookingQuery = false

    getDashboardBookings(
      employeeDetails.employeeId,
      isSameMonth(newDate, today) ? today : newDate,
      dispatch,
      onBookingGridViewDataLoaded,
      singleDayBookingQuery,
      abortControllerRef
    )

  }), [toggleButtonSelectedDate], 300)

  const handleViewAdvanceClick = useMemo(() => (navForward: boolean) => {

    const enabled = true
    const today = new Date()
    let newDate = today

    if (showGridView && gridViewingDate) {

      newDate = startOfMonth(
        navForward ? addMonths(gridViewingDate, 1) : subMonths(gridViewingDate, 1)
      )
      batch(() => {
        dispatch(setBookingFloorplanViewingDate(newDate))
        dispatch(setBookingGridViewingDate(newDate))
      })
    }

    if (newDate !== today) {
      setToggleButtonSelectedDate({enabled, today, newDate})
    }

  }, [showGridView, gridViewingDate])

  const shouldDisableIncrement = useCallback(() =>
    showGridView && gridViewingDate.getMonth() === maxBookingDate.getMonth(),
    [gridViewingDate, maxBookingDate, showGridView]
  )

  const shouldDisableDecrement = useCallback(() =>
    showGridView && dateToNumber(gridViewingDate) <= dateToNumber(new Date()),
    [gridViewingDate, showGridView]
  )

  return (
    <>
      <Grid container id="gridViewToggleBar" display="flex" justifyContent="flex-end">
        <Grid item xs display="flex" alignItems="center">
          <Box flexGrow={1}>
            <CardTitle title={title} />
          </Box>
        </Grid>
        <Grid item width="100px" display="flex" alignItems="center" pr={2}>
          <Stack direction="row" justifyContent="flex-end" flexGrow={5} alignItems="center" gap={2}>
            <ToggleButtonGroup
              value={null}
              exclusive
              bgColor={theme.palette.primary.main}
              minWidth="34px"
              onChange={(e, val) => handleViewAdvanceClick(val === 'Inc')}
            >
              <ToggleButton value="Dec" fixedColor="white" disabled={shouldDisableDecrement()}>
                <ChevronLeftIcon />
              </ToggleButton>
              <ToggleButton value="Inc" fixedColor="white" disabled={shouldDisableIncrement()}>
                <ChevronRightIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
