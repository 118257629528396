import { BookingFeature, DeskFeature, RectFeature, WallFeature, OfficeFeature, TextFeature, LabelFeature, ZoneFeature } from '../../../../services/booking/types';
import { BookingDeskPortPosition } from '../../Shared/enums';

// Function to check if TextFeature is within RectFeature or OfficeFeature  
export function isTextWithinRect(
    rect: { x: number; y: number; width: number; height: number },
    text: TextFeature
): boolean {
    const textX = text.x;
    const textY = text.y;

    return (
        textX >= rect.x &&
        textX <= rect.x + rect.width &&
        textY >= rect.y &&
        textY <= rect.y + rect.height
    );
}

export function convertRectFeatureToWallFeature(feature: RectFeature): WallFeature {
    const { x, y, width, height } = feature;

    let startPoint: { x: number; y: number };
    let endPoint: { x: number; y: number };
    let thickness: number;

    if (width >= height) {
        // Horizontal wall  
        thickness = height;
        startPoint = { x, y: y + height / 2 };
        endPoint = { x: x + width, y: y + height / 2 };
    } else {
        // Vertical wall  
        thickness = width;
        startPoint = { x: x + width / 2, y };
        endPoint = { x: x + width / 2, y: y + height };
    }

    return {
        ...feature,
        typeId: 7,
        type: 'Wall',
        startPoint,
        endPoint,
        thickness,
        width: 0,
        height: 0,
        x: 0,
        y: 0
    };
}

// Function to convert RectFeature to OfficeFeature  
export function convertRectFeatureToOfficeFeature(feature: RectFeature): OfficeFeature {
    return {
        ...feature,
        typeId: 10,
        type: 'Office',
    };
}

// Function to calculate rotation angle based on port position  
export function calculateRotationAngle(featureDetail: DeskFeature): number {
    const portPosition = Number(featureDetail.ports?.[0]?.x);

    switch (portPosition) {
        case BookingDeskPortPosition.TOP:
            return 270;
        case BookingDeskPortPosition.RIGHT:
            return 0;
        case BookingDeskPortPosition.BOTTOM:
            return 90;
        case BookingDeskPortPosition.LEFT:
            return 180;
        default:
            return 180;
    }
}

// Utility function to pick specific properties from an object if they are defined  
export function pick<T extends object>(obj: T, keys: (keyof T)[]): Partial<T> {
    return keys.reduce((result, key) => {
        if (obj && Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
            result[key] = obj[key];
        }
        return result;
    }, {} as Partial<T>);
}

// Utility function to normalize keys to lowercase
export function normalizeKeys<T extends object>(obj: T): Partial<Record<keyof T, any>> {
    const result: Partial<Record<keyof T, any>> = {};
    Object.keys(obj).forEach(key => {
        const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
        result[normalizedKey as keyof T] = obj[key as keyof T];
    });
    return result;
}

// Utility function to convert fontSize to a number
export function normalizeFontSize(fontSize: string | number): number {
    if (typeof fontSize === 'string') {
        const parsed = parseFloat(fontSize);
        if (!Number.isNaN(parsed)) {
            return parsed;
        }
    }
    return Number(fontSize);
}

export const processFeatures = (resultFeatures: any) => {
    const processedFeatures: BookingFeature[] = [];
    const processedFeatureIds = new Set<number>();
    const convertedRectFeatureIds = new Set<number>();
    const zones: ZoneFeature[] = [];

    // First, process RectFeatures to convert to Offices
    resultFeatures.features.forEach((feature: BookingFeature) => {
        if (feature.typeId === 4) {
            const rectFeature = feature as RectFeature;

            if (rectFeature.label && rectFeature.fill === '#BFCBC8') {
                // Convert to OfficeFeature
                const officeFeature = convertRectFeatureToOfficeFeature(rectFeature);
                processedFeatures.push(officeFeature);
                processedFeatureIds.add(rectFeature.id);
                convertedRectFeatureIds.add(rectFeature.id);
            }
        }
    });

    // Then, process TextFeatures and attempt to merge with RectFeatures
    resultFeatures.features.forEach((feature: BookingFeature) => {
        if (feature.typeId === 5) {
            const textFeature = feature as TextFeature;
            if (processedFeatureIds.has(textFeature.id)) return;

            // First, check if TextFeature is within an OfficeFeature
            const isWithinOffice = processedFeatures.some((otherFeature) => {
                if (otherFeature.typeId === 10) {
                    // OfficeFeature
                    const officeFeature = otherFeature as OfficeFeature;
                    // Check if the TextFeature is within the OfficeFeature
                    return isTextWithinRect(officeFeature, textFeature);
                }
                return false;
            });

            if (isWithinOffice) {
                // Do not process this TextFeature (leave it as is)
                processedFeatures.push(textFeature);
                processedFeatureIds.add(textFeature.id);
                return;
            }

            // Find a RectFeature that contains this TextFeature
            const containingRect = resultFeatures.features.find((otherFeature: BookingFeature) => {
                if (
                    otherFeature.typeId === 4 &&
                    !convertedRectFeatureIds.has(otherFeature.id) &&
                    !processedFeatureIds.has(otherFeature.id)
                ) {
                    const rectFeature = otherFeature as RectFeature;

                    // Check if RectFeature contains the TextFeature
                    if (isTextWithinRect(rectFeature, textFeature)) {
                        return true;
                    }
                }
                return false;
            });

            if (containingRect) {
                const rectFeature = containingRect as RectFeature;
                const labelFeature: LabelFeature = {
                    ...textFeature,
                    typeId: 9,
                    type: 'Label',
                    chip: true,
                    fill: textFeature.fill || rectFeature.fill,
                    fontSize: textFeature.fontSize ?? 16, // Include fontSize
                };
                processedFeatures.push(labelFeature);
                processedFeatureIds.add(textFeature.id);
                processedFeatureIds.add(rectFeature.id);
                convertedRectFeatureIds.add(rectFeature.id);
            } else {
                // Convert TextFeature to LabelFeature with chip: false
                const labelFeature: LabelFeature = {
                    ...textFeature,
                    typeId: 9,
                    type: 'Label',
                    chip: false,
                    fill: textFeature.fill,
                    fontSize: textFeature.fontSize ?? 16, // Include fontSize
                };
                processedFeatures.push(labelFeature);
                processedFeatureIds.add(textFeature.id);
            }
        }
    });

    // Now process remaining RectFeatures to convert to Walls
    resultFeatures.features.forEach((feature: BookingFeature) => {
        if (feature.typeId === 4) {
            const rectFeature = feature as RectFeature;
            if (processedFeatureIds.has(rectFeature.id)) return;

            if (
                (rectFeature.height === 8 || rectFeature.borderRadius === 0) &&
                rectFeature.fill !== 'zone.colours.badge' &&
                rectFeature.fill !== '#BFCBC8'
            ) {
                // Convert to WallFeature
                const wallFeature = convertRectFeatureToWallFeature(rectFeature);
                processedFeatures.push(wallFeature);
                processedFeatureIds.add(rectFeature.id);
                convertedRectFeatureIds.add(rectFeature.id);
            } else {
                // Keep RectFeature
                processedFeatures.push(rectFeature);
                processedFeatureIds.add(rectFeature.id);
            }
        }
    });

    // Now process DeskFeatures to set rotationAngle if missing
    resultFeatures.features.forEach((feature: BookingFeature) => {
        if (feature.typeId === 1) {
            const deskFeature = feature as DeskFeature;
            if (processedFeatureIds.has(deskFeature.id)) return;

            if (deskFeature.rotationAngle === undefined || deskFeature.rotationAngle === null) {
                const rotationAngle = calculateRotationAngle(deskFeature);
                const updatedDeskFeature = { ...deskFeature, rotationAngle };
                processedFeatures.push(updatedDeskFeature);
            } else {
                processedFeatures.push(deskFeature);
            }

            processedFeatureIds.add(deskFeature.id);
        }
    });

    // Finally, add any other features not yet processed
    resultFeatures.features.forEach((feature: BookingFeature) => {
        if (!processedFeatureIds.has(feature.id)) {
            processedFeatures.push(feature);
            processedFeatureIds.add(feature.id);
        }
    });

    // Map additionalInfo to feature variables
    processedFeatures.forEach((feature) => {
        if (feature.additionalInfo) {
            const trimmedInfo = feature.additionalInfo.trim();
            if (trimmedInfo === "") return;

            let additionalInfo;
            try {
                additionalInfo = JSON.parse(trimmedInfo);
            } catch (e) {
                // If JSON.parse fails, continue to the next feature
                return;
            }

            const normalizedInfo = normalizeKeys(additionalInfo);
            const labelFeatureInfo = pick(normalizedInfo, ['color', 'fontSize', 'chip', 'fontStyle']);
            const textFeatureInfo = pick(normalizedInfo, ['color', 'fontSize', 'chip', 'fontStyle']);

            switch (feature.typeId) {
                case 1: // DeskFeature
                    Object.assign(feature, pick(normalizedInfo, ['width', 'height']));

                    // Only assign rotationAngle if it's defined  
                    if (normalizedInfo.rotationAngle !== undefined) {
                        feature.rotationAngle = normalizedInfo.rotationAngle;
                    }
                    break;
                case 4: // RectFeature
                    Object.assign(feature, pick(normalizedInfo, ['width', 'height']));
                    break;
                case 7: // WallFeature
                    Object.assign(feature, pick(normalizedInfo, ['startPoint', 'endPoint', 'thickness']));
                    break;
                case 9: // LabelFeature
                    if (labelFeatureInfo.fontStyle && labelFeatureInfo.fontStyle.fontSize !== undefined) {
                        labelFeatureInfo.fontSize = normalizeFontSize(labelFeatureInfo.fontStyle.fontSize);
                    }
                    if (labelFeatureInfo.fontSize !== undefined || labelFeatureInfo.fontStyle === undefined) {
                        labelFeatureInfo.fontSize = normalizeFontSize(labelFeatureInfo.fontSize);
                    }
                    Object.assign(feature, labelFeatureInfo);
                    break;
                case 10: // OfficeFeature
                    Object.assign(feature, pick(normalizedInfo, ['width', 'height']));
                    break;
                case 8: // ZoneFeature
                    if(feature.zone !== undefined) {
                        const zoneInfo = normalizeKeys(JSON.parse(feature.zone.additionalInfo!));
                        Object.assign(feature, pick(zoneInfo, ['name', 'belongsTo', 'svg']));
                        zones.push(feature as ZoneFeature);
                    }
                    break;
                case 5: // TextFeature
                    if (textFeatureInfo.fontSize !== undefined) {
                        textFeatureInfo.fontSize = normalizeFontSize(textFeatureInfo.fontSize);
                    }
                    if (textFeatureInfo.fontStyle && textFeatureInfo.fontStyle.fontSize !== undefined) {
                        textFeatureInfo.fontStyle.fontSize = normalizeFontSize(textFeatureInfo.fontStyle.fontSize);
                    }
                    Object.assign(feature, textFeatureInfo);
                    break;
                default:
                    break;
            }
        }
    });

    return { processedFeatures, zones };
};