import { AnyAction, Dispatch } from 'redux';
import { UserClaims } from '@okta/okta-auth-js';
import { endOfMonth, format } from 'date-fns';
import store from '../../redux/store';
import { bookingService } from '../../services/booking/bookingService';
import {
  BookingCancellationPostBody,
  BookingCheckInOutPost,
  BookingEmployeeBooking,
  BookingFeature,
  BookingBookingPayload,
  BookingZone, DeskFeature,
} from '../../services/booking/types';
import { EmployeeDetailsResponse } from '../../types/employee';
import { BookingCancellationProps } from './Shared/types';
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer';
import { BaseResponse, ApiError } from '../../types/base-response';
import { dateToNumber } from './utils/utils';
import { WizardStep } from '../../shared/UI/WizardSteps/types';
import { BookingStatus, BookingWizardStepLabels, BookingSearchType } from './Shared/enums';
import { checkIsOpenFloorplanArea } from './Shared/bookingFloorplanLogic';
import {
  setCheckedIn,
  setDeskBookingDashboardResults,
  setDeskBookingTodaysBooking,
  setForwardDeskBookingsCount,
  setDeskBookingFocussedZoneID,
  setDeskBookingFeaturesForFloorplan,
  setMostUsedDesk,
  setDeskBookingLoading,
  setDeskBookingOpenFloorplan,
  setDeskBookingInfinityBookingsResults,
} from '../../redux/reducers/deskBookingReducer';
import {
  setBookingWizardBookingsState,
  setBookingWizardFinalSelectionState,
  setBookingWizardSelectedFeatureState,
} from '../../redux/reducers/deskBookingWizardReducer';
import {
  setDeskBookingSearchResults,
} from '../../redux/reducers/deskBookingSearchReducer';
import { normalizeFontSize, normalizeKeys, pick } from './Designer/Utils/toolbarUtils';

export const wizardSteps: WizardStep[] = [
  { index: BookingWizardStepLabels.SELECT_DESK, label: '1. Select a desk' },
  { index: BookingWizardStepLabels.SUMMARY, label: '2. Summary' },
  { index: BookingWizardStepLabels.ALTERNATIVES, label: '3. Alternatives' },
];

export const refreshForwardBookingsCount = () => {
  store.dispatch(setForwardDeskBookingsCount(undefined));
};

export const refreshMostUsedDesk = () => {
  store.dispatch(setMostUsedDesk(undefined));
};

export const refreshTodayBooking = () => {
  store.dispatch(setDeskBookingTodaysBooking({ id: 0 } as BookingEmployeeBooking));
};

export const onBookingGridViewDataLoaded = (booking: BookingEmployeeBooking | undefined) => {
  if (booking) {
    store.dispatch(setDeskBookingTodaysBooking(booking));
  }
  // this causing early load and flciker
  // store.dispatch(setDeskBookingLoading(false))
};

export const handleServerError = (response: BaseResponse) => {
  if (!response.errors || response.errors?.[0]) {
    const thisError: ApiError = {
      name: `${response.type} - ${response.title}`,
      description: `${response.detail}`,
    };

    response.errors = [thisError];
  }

  return response;
};

type MutableRefObject<T> = {
  current: T | null;
};

export const sortDashboardBookings = (bookings: BookingEmployeeBooking[]) =>
    bookings
        .filter(
            // Only show current and future bookings (for now)
            (f) =>
                dateToNumber(new Date(f.fromDate)) >= dateToNumber(new Date()) &&
                f.statusId === BookingStatus.ACCEPTED
        )
        .sort((a, b) => {
          const aDate = a.fromDate;
          const bDate = b.fromDate;
          if (aDate < bDate) {
            return -1;
          }
          if (aDate > bDate) {
            return 1;
          }
          return 0;
        });

export const getDashboardBookings = async (
    employeeId: number,
    dateFrom: Date,
    dispatch: Dispatch<AnyAction>,
    onDataLoaded: (employeeBooking?: BookingEmployeeBooking) => void,
    fullMonth: boolean,
    abortControllerRef?: MutableRefObject<AbortController> | null,
    dateTo?: Date,
    autoUpdate?: boolean,
    infinityMode?: boolean
) => {
  if (!employeeId) {
    return;
  }

  if (abortControllerRef) {
    // Abort any pending requests
    if (abortControllerRef.current && abortControllerRef.current !== null) {
      abortControllerRef.current.abort();
    }
  }

  // Create new abort controller
  const abortController = new AbortController();

  if (abortControllerRef) {
    /* eslint-disable no-param-reassign */
    abortControllerRef.current = abortController;
    /* eslint-enable no-param-reassign */
    // Call onSearch with new search term and abort controller
  }

  const dateEnd = dateTo || endOfMonth(dateFrom);

  return bookingService
      .getBookingByEmployeeId(
          abortController,
          employeeId,
          format(dateFrom, 'yyyy-MM-dd'),
          format(!fullMonth ? dateEnd : dateFrom, 'yyyy-MM-dd')
      )
      .then((result) => {
        const sortedPayload = result;
        sortedPayload.bookings = sortDashboardBookings(sortedPayload.bookings);

        let shouldUpdate = true;

        if (autoUpdate !== undefined && autoUpdate !== null && autoUpdate !== false)
          shouldUpdate = autoUpdate;

        if (shouldUpdate) {
          if (infinityMode) dispatch(setDeskBookingInfinityBookingsResults(sortedPayload.bookings));
          else dispatch(setDeskBookingDashboardResults(sortedPayload.bookings));
        }

        if (!fullMonth) setTimeout(() => dispatch(setDeskBookingLoading(false)), 13);

        const todaysBooking = sortedPayload.bookings.find(
            (f) => format(new Date(f.fromDate), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')
        );
        if (!todaysBooking) {
          Promise.resolve();
        }
        onDataLoaded(todaysBooking);

        return Promise.resolve({
          bookings: sortedPayload.bookings,
        });
      })
      .catch((err) => {
        if (!err.response) {
          return;
        }

        if (fullMonth) {
          setTimeout(() => dispatch(setDeskBookingLoading(false)), 300);
        }

        const response: BaseResponse = handleServerError(err.response.data);
        if (response.status === 500) {
          dispatch(showErrorMessage(`Load Employee ID:${employeeId} Desk Bookings failure`));
        }
      });
};

export const handleCancellation = async (
    isMobile: boolean,
    cancellationParams: BookingCancellationProps | null,
    employeeDetails: EmployeeDetailsResponse,
    gridViewingDate: Date,
    dispatch: Dispatch<AnyAction>,
    onError: (err: string) => void
) => {
  if (!cancellationParams) {
    return;
  }
  const body: BookingCancellationPostBody = {
    bookingId: cancellationParams.bookingID,
    reasonTypeId: 1,
    comments: 'User cancellation',
    createdBy: employeeDetails.employeeId,
    deskDetails: {
      deskName: cancellationParams.deskName,
      floorPlanName: cancellationParams.floorPlanName,
      location: cancellationParams.location,
    },
  };

  const cancelBooking = cancellationParams.byManager
      ? bookingService.cancelBookingByIdByManager
      : bookingService.cancelBookingById;

  cancelBooking(body)
      .then((result) => {
        cancellationParams.onCallBack(true);
        dispatch(
            showSuccessMessage(
                `You have successfully cancelled the booking for desk ${cancellationParams.deskName} ${
                    cancellationParams.floorPlanName ? `in ${cancellationParams.floorPlanName}` : ''
                }`
            )
        );
        refreshForwardBookingsCount();
        refreshMostUsedDesk();
        const singleDayBookingQuery = false;
        getDashboardBookings(
            employeeDetails.employeeId,
            gridViewingDate,
            dispatch,
            onBookingGridViewDataLoaded,
            singleDayBookingQuery
        );
        const todaysBookingId = store.getState().deskBooking.todaysBooking?.id;
        if (todaysBookingId === body.bookingId) {
          dispatch(setCheckedIn(false));
          refreshTodayBooking();
        }
      })
      .catch((err) => {
        cancellationParams.onCallBack(false);
        const response: BaseResponse = err.response.data;
        if (response.status === 500) {
          dispatch(showErrorMessage('Cancellation failure'));
        }
        response.errors.forEach((error) => {
          onError(error.name);
        });
      });
};

export const handleCheckIn = (
    bookingId: number,
    employeeId: number,
    featureLabel: string,
    dispatch: Dispatch<AnyAction>,
    onError: (err: string) => void
) => {
  const body: Partial<BookingCheckInOutPost> = {
    bookingID: bookingId,
    userId: employeeId,
    type: 'in',
    actionDate: new Date().toISOString(),
    deskName: featureLabel,
    anyIssues: '',
  };
  bookingService
      .checkInOut(body)
      .then(() => {
        dispatch(showSuccessMessage(`You have successfully checked in for desk ${featureLabel}`));
      })
      .catch((err) => {
        const response: BaseResponse = err.response.data;
        if (response.status === 500) {
          dispatch(showErrorMessage('Check-in failure'));
        }
        if (response.errors?.length > 0) {
          response.errors.forEach((error) => {
            onError(error.name);
          });
        }
      });
};

export const getDeskNameWithRow = (
    feature: BookingFeature & { hasBookings?: boolean | undefined }
) => {
  let deskName = '';
  if (feature.additionalInfo) {
    const additionInfoString = feature.additionalInfo.replace('\\\\\\\\', '');
    const additionalInfo = JSON.parse(additionInfoString);
    deskName = additionalInfo?.desc?.deskDesc;
  }
  deskName += feature?.label || '';
  return deskName;
};

export const handleResetBookingWizardFloorPlan = (dispatch: Dispatch<AnyAction>) => {
  dispatch(setDeskBookingSearchResults([]));
  dispatch(setBookingWizardSelectedFeatureState(undefined));
  dispatch(setBookingWizardBookingsState([]));
  dispatch(setBookingWizardFinalSelectionState([]));
  // dispatch(setDeskBookingSearchParams(undefined))
};

export const bookingTransformer = (booking: BookingEmployeeBooking) => {
  const { feature, statusId, id, employeeId, displayName, toDate, toTime, fromDate, fromTime } =
      booking;

  const { floorPlanId } = feature;

  const featureId = feature.id;

  return {
    id,
    employeeId,
    displayName,
    floorPlanId,
    featureId,
    featureType: {
      id: featureId,
      label: feature.label.replace(/^Desk$/i, 'Desks'),
    },
    fromDate,
    fromTime,
    toDate,
    toTime,
    createdByDate: '',
    statusId,
  };
};

export const uniqueBookings = (bookings: BookingBookingPayload[]) =>
    bookings.filter((o, i) => i === bookings.findIndex((f) => o.id === f.id));

interface BookingSelectedData {
  selectedFromTo: {
    from: string;
    to: string;
  };
  searchType: BookingSearchType;
}

export const handleSearch = async (
    dispatch: Dispatch<AnyAction>,
    onError: (err: string) => void,
    queryRange: boolean,
    zones: BookingZone[],
    features: BookingFeature[] | null | undefined,
    employeeDetails: EmployeeDetailsResponse,
    userInfo: UserClaims<Record<string, string | number | boolean>> | null,
    selectedData: BookingSelectedData,
    locationId: number,
    floorplanId: number,
    featureId: number | null,
    date: Date,
    dateTo?: Date
) => {
  const { selectedFromTo, searchType } = selectedData;

  if (!locationId || !floorplanId || !selectedFromTo) {
    return;
  }

  dispatch(setDeskBookingLoading(true));

  const isSingleSearchType = searchType === BookingSearchType.SINGLE;
  const setLocalBookings = (localBookings: BookingBookingPayload[]) => {
    if (isSingleSearchType) {
      dispatch(setBookingWizardBookingsState([]));
      dispatch(setDeskBookingSearchResults(localBookings));
    } else {
      dispatch(setDeskBookingSearchResults([]));
      dispatch(setBookingWizardBookingsState(localBookings));
    }
  };

  handleResetBookingWizardFloorPlan(dispatch);

  const controller = new AbortController();

  const getNewFloorPlanFeatures =
      features === undefined || features === null || floorplanId !== features?.[0].floorPlanId;

  const getFloorplanFeatures = getNewFloorPlanFeatures
      ? bookingService
          .getFeaturesByPlan(controller, floorplanId)
          .then((resultFeatures) => {
            const processedFeatures = resultFeatures.features.map((feature) => {
              if (feature.additionalInfo) {
                const trimmedInfo = feature.additionalInfo.trim();
                if (trimmedInfo === '') return feature;

                let additionalInfo;
                try {
                  additionalInfo = JSON.parse(trimmedInfo);
                } catch (e) {
                  // If JSON.parse fails, continue to the next feature
                  return feature;
                }

                const normalizedInfo = normalizeKeys(additionalInfo);
                const updatedFeature = { ...feature }; // Create a copy of the feature to avoid reassigning properties
                const labelFeatureInfo = pick(normalizedInfo, ['color', 'fontSize', 'chip', 'fontStyle']);
                const textFeatureInfo = pick(normalizedInfo, ['color', 'fontSize', 'chip', 'fontStyle']);
                
                switch (feature.typeId) {
                  case 1: // DeskFeature
                    Object.assign(updatedFeature, pick(normalizedInfo, ['width', 'height']));

                    // Only assign rotationAngle if it's defined
                    if (normalizedInfo.rotationAngle !== undefined) {
                      (updatedFeature as DeskFeature).rotationAngle = normalizedInfo.rotationAngle;
                    }
                    break;
                  case 4: // RectFeature
                    Object.assign(updatedFeature, pick(normalizedInfo, ['width', 'height']));
                    break;
                  case 7: // WallFeature
                    Object.assign(updatedFeature, pick(normalizedInfo, ['startPoint', 'endPoint', 'thickness']));
                    break;
                  case 9: // LabelFeature
                    if (labelFeatureInfo.fontStyle && labelFeatureInfo.fontStyle.fontSize !== undefined) {
                      labelFeatureInfo.fontSize = normalizeFontSize(labelFeatureInfo.fontStyle.fontSize);
                    }
                    if (labelFeatureInfo.fontSize !== undefined || labelFeatureInfo.fontStyle === undefined) {
                      labelFeatureInfo.fontSize = normalizeFontSize(labelFeatureInfo.fontSize);
                    }
                    Object.assign(updatedFeature, labelFeatureInfo);
                    break;
                  case 10: // OfficeFeature
                    Object.assign(updatedFeature, pick(normalizedInfo, ['width', 'height']));
                    break;
                  case 8: // ZoneFeature
                    Object.assign(updatedFeature, pick(normalizedInfo, ['name', 'belongsTo', 'svg']));
                    break;
                  case 5: // TextFeature
                    // Remove console statements
                    // console.log('TextFeature info before normalization:', textFeatureInfo);
                    if (textFeatureInfo.fontSize !== undefined) {
                      textFeatureInfo.fontSize = normalizeFontSize(textFeatureInfo.fontSize);
                    }
                    if (textFeatureInfo.fontStyle && textFeatureInfo.fontStyle.fontSize !== undefined) {
                      textFeatureInfo.fontStyle.fontSize = normalizeFontSize(textFeatureInfo.fontStyle.fontSize);
                    }
                    // console.log('TextFeature info after normalization:', textFeatureInfo);
                    Object.assign(updatedFeature, textFeatureInfo);
                    break;
                  default:
                    break;
                }
                return updatedFeature;
              }
              return feature;
            });
            return { ...resultFeatures, features: processedFeatures };
          })
          .catch((err) => {
            const response: BaseResponse = handleServerError(err.response.data);
            response.errors.forEach((error) => {
              dispatch(showErrorMessage(`Search Floorplan ${floorplanId} features failure`));
            });

            dispatch(setDeskBookingLoading(false));
          })
      : Promise.resolve(true);

  dispatch(
      setDeskBookingOpenFloorplan(checkIsOpenFloorplanArea(locationId, floorplanId))
  );

  const getByQuerySearchResults = bookingService
      .search(controller, {
        floorplanId,
        date: format(date, 'yyyy-MM-dd'),
        ...(dateTo && { dateTo: format(dateTo, 'yyyy-MM-dd') }),
        from: selectedFromTo?.from,
        to: selectedFromTo?.to,
      })
      .then((result) => result)
      .catch((err) => {
        const response: BaseResponse = handleServerError(err.response.data);
        if (response.status === 500) {
          dispatch(showErrorMessage(`Search Floorplan ${floorplanId} bookings failure`));
        }
        response.errors.forEach((error) => {
          onError(error.name);
        });

        dispatch(setDeskBookingLoading(false));
      });

  const singleDayBookingQuery = queryRange;
  const getByEmployeeData = getDashboardBookings(
      employeeDetails.employeeId,
      date,
      dispatch,
      onBookingGridViewDataLoaded,
      singleDayBookingQuery,
      null,
      dateTo
  );

  const rawServiceResponses = await Promise.allSettled([
    getFloorplanFeatures,
    getByQuerySearchResults,
    getByEmployeeData,
  ]);

  const serviceResponses = rawServiceResponses.filter(
      (res) => res.status === 'fulfilled'
  ) as PromiseFulfilledResult<any>[];

  if (features && !getNewFloorPlanFeatures) {
    dispatch(setDeskBookingFeaturesForFloorplan(features));
  }

  const newFloorPlanFeatures = serviceResponses[0].value.features;

  if (getNewFloorPlanFeatures && newFloorPlanFeatures) {
    dispatch(setDeskBookingFeaturesForFloorplan(newFloorPlanFeatures));
  }

  const localBookings = serviceResponses[1].value.bookings.filter(
      (f: BookingBookingPayload) => f.statusId === 2 && f.floorPlanId === floorplanId
  );

  const employeeBookings = (serviceResponses[2].value.bookings ?? [])
      .map((booking: BookingEmployeeBooking) => bookingTransformer(booking) as BookingBookingPayload)
      .filter(
          (f: BookingBookingPayload) =>
              f.statusId === 2 &&
              dateToNumber(f.fromDate) === dateToNumber(date) &&
              f.floorPlanId === floorplanId
      );

  const featureDoesNotExistOnFloorPlanFeatureSet = !features?.find(
      (f: BookingFeature) => f.id === featureId && f.floorPlanId === floorplanId
  );

  let theseFeatures = features;

  if (featureDoesNotExistOnFloorPlanFeatureSet) {
    theseFeatures = newFloorPlanFeatures;
  }

  let getFeatureZoneId  = theseFeatures?.find(
      (f: BookingFeature) => f.id === featureId && f.floorPlanId === floorplanId
  )?.zone?.id;

  if (featureDoesNotExistOnFloorPlanFeatureSet && featureId) {
    getFeatureZoneId = serviceResponses[2].value.bookings.find(
        (f: BookingEmployeeBooking) =>
            f.feature.id === featureId && f.feature.floorPlanId === floorplanId
    )?.feature.zone.id;
  }

  if (getFeatureZoneId) {
    dispatch(setDeskBookingFocussedZoneID(getFeatureZoneId));
  }

  const combinedDeskBookings = [...localBookings, ...employeeBookings as BookingBookingPayload[]];
  const uniqueDeskBookings = uniqueBookings(combinedDeskBookings);

  setLocalBookings(uniqueDeskBookings);

  setTimeout(() => dispatch(setDeskBookingLoading(false)), 300);
};