import {useCallback} from "react";
import {BookingZone, ZoneFeature} from "../../../../services/booking/types";
import {isDeskInZone, parseAdditionalInfoToPoints} from "../Utils/zoneUtils";
import {useDesignerContext} from "../DesignerContext";

export const useZones = () => {
    const {
        setFloorPlanFeatures,
        zones,
        setZones,
        floorPlanId,
        locationId,
        nextZoneId,
        setNextZoneId,
    } = useDesignerContext();
    
    const addZone = useCallback(() => {
        const newZone: ZoneFeature = {
            id: nextZoneId,
            floorPlanId,
            floorPlanCategoryId: 1,
            locationId,
            typeId: 8,
            type: 'Zone',
            statusId: 1,
            status: 'Available',
            label: 'New Zone',
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            fill: '#F2F2F2',
            borderRadius: 0,
            ports: [],
            createdById: 0,
            additionalInfo: JSON.stringify({
                svg: [
                    {
                        type: 'polygon',
                        points: [
                            [100, 100],
                            [500, 100],
                            [500, 500],
                            [100, 500],
                        ],
                    },
                ],
                colors: {badge: '#F2F2F2'},
            }),
            name: 'New Zone',
            belongsTo: 0,
        };
        setZones((prevZones) => [...prevZones, newZone]);
        setNextZoneId((id) => id + 1);

        setFloorPlanFeatures((prevFeatures) => [...prevFeatures, newZone]);
    }, [nextZoneId, setZones, setFloorPlanFeatures]);

    const updateZone = useCallback(
        (updatedZone: ZoneFeature) => {
            setZones((prevZones) => prevZones.map((zone) => (zone.id === updatedZone.id ? updatedZone : zone)));

            // Update features in this zone  
            const points = parseAdditionalInfoToPoints(updatedZone.additionalInfo);
            setFloorPlanFeatures((prevFeatures) =>
                prevFeatures.map((feature) => {
                    if (isDeskInZone(feature, points)) {
                        if (feature.typeId === 9) {
                            feature.fill = 'zone.colors.badge';
                        }
                        return {...feature, zone: {...updatedZone, status: 1} as Partial<BookingZone>};
                    } 
                    
                    if (feature.typeId !== 8 && feature.zone && (feature.zone.id === updatedZone.id || feature.zone.id === updatedZone.zone?.id) && !isDeskInZone(feature, points)) {
                        // Remove zone if desk is no longer inside  
                        if (feature.typeId === 9) {
                            feature.fill = '';
                        }
                        const {zone, ...rest} = feature;
                        return rest;
                    }
                    // Update the corresponding ZoneFeature  
                    if (feature.typeId === 8 && feature.id === updatedZone.id) {
                        return updatedZone;
                    }
                    return feature;
                })
            );
        },
        [setZones, setFloorPlanFeatures]
    );

    const deleteZone = useCallback(
        (id: number) => {
            setZones((currentZones) => currentZones.filter((zone) => zone.id !== id));

            // Also remove zone references from features and remove ZoneFeature  
            setFloorPlanFeatures((currentFeatures) =>
                currentFeatures
                    .filter((feature) => !(feature.typeId === 8 && feature.id === id)) // Remove the ZoneFeature  
                    .map((feature) => {
                        if (feature.zone && feature.zone.id === id) {
                            const {zone, ...rest} = feature;
                            return rest;
                        }
                        return feature;
                    })
            );
        },
        [setZones, setFloorPlanFeatures]
    );

    return {zones, addZone, updateZone, deleteZone};
};