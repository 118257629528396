import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from '@mui/material';
import { DesignerZoneProps, Point } from '../types';
import { parseAdditionalInfoToPoints } from '../Utils/zoneUtils';
import { ZoneFeature } from '../../../../services/booking/types';
import { useZones } from '../hooks/useZones';

const GRID_SIZE = 5;

// Converted to function declaration  
function DesignerZone({ zone }: DesignerZoneProps): React.ReactElement {
    const groupRef = useRef<SVGGElement | null>(null);

    const { updateZone, deleteZone } = useZones();

    // Refs to store initial positions - moved to top level  
    const initialDragState = useRef<{
        initialMousePos: [number, number];
        initialPoints: Point[];
    }>({
        initialMousePos: [0, 0],
        initialPoints: [],
    });

    const initialVertexDragState = useRef<{
        initialMousePos: [number, number];
        initialPoint: Point;
    }>({
        initialMousePos: [0, 0],
        initialPoint: [0, 0],
    });

    // State for the dialog  
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>(zone.name);
    const [belongsTo, setBelongsTo] = useState<number>(zone.belongsTo || 0);

    useEffect(() => {
        if (!groupRef.current) return;

        const svgGroup = d3.select<SVGGElement, unknown>(groupRef.current);
        let points: Point[] = parseAdditionalInfoToPoints(zone.additionalInfo);

        // Function to update the zone's additionalInfo  
        const updateZoneData = (): void => {
            const updatedZone: ZoneFeature = {
                ...zone,
                name,
                belongsTo,
                additionalInfo: JSON.stringify({
                    svg: [{ type: 'polygon', points }],
                    colors: { badge: '#20C5A0' },
                }),
            };
            updateZone(updatedZone);
        };

        // Clear any existing content  
        svgGroup.selectAll('*').remove();

        // Create the polygon  
        const polygon = svgGroup
            .append<SVGPolygonElement>('polygon')
            .attr('points', points.map((d) => d.join(',')).join(' '))
            .attr('fill', 'rgba(242, 242, 242, 0.5)') // Semi-transparent fill  
            .attr('stroke', '#000')
            .attr('stroke-width', 1)
            .call(
                d3
                    .drag<SVGPolygonElement, unknown>()
                    .on('start', (event) => {
                        if (!groupRef.current) return;
                        const svgElement = groupRef.current;

                        // Get initial mouse position in SVG coordinates  
                        const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                            event.sourceEvent.clientX,
                            event.sourceEvent.clientY,
                        ]);

                        initialDragState.current.initialMousePos = [mouseX, mouseY];
                        // Deep copy of points  
                        initialDragState.current.initialPoints = points.map((p) => [...p]);
                    })
                    .on('drag', (event) => {
                        if (!groupRef.current) return;
                        const svgElement = groupRef.current;

                        // Get current mouse position in SVG coordinates  
                        const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                            event.sourceEvent.clientX,
                            event.sourceEvent.clientY,
                        ]);

                        // Calculate deltas  
                        const dx = mouseX - initialDragState.current.initialMousePos[0];
                        const dy = mouseY - initialDragState.current.initialMousePos[1];

                        points = initialDragState.current.initialPoints.map(([x, y]) => [
                            x + dx,
                            y + dy,
                        ]);

                        polygon.attr('points', points.map((p) => p.join(',')).join(' '));

                        // Update vertices and edge midpoints  
                        updateVertices();
                        updateEdgeMidpoints();
                    })
                    .on('end', () => {
                        updateZoneData();
                    })
            );

        // Create vertices (circles) for each point  
        let vertices = svgGroup.selectAll<SVGCircleElement, Point>('circle.vertex').data(points);

        const enterVertices = vertices
            .enter()
            .append<SVGCircleElement>('circle')
            .attr('class', 'vertex')
            .attr('r', 5)
            .attr('fill', 'grey')
            .style('cursor', 'move')
            .on('contextmenu', (event, d) => {
                event.preventDefault();
                event.stopPropagation(); // Prevent event from bubbling up to svgGroup  
                // Remove point on right-click if there are more than 3 points  
                if (points.length > 3) {
                    points = points.filter((p) => p !== d);
                    updatePolygonAndVertices();
                    updateZoneData();
                }
            })
            .call(
                d3
                    .drag<SVGCircleElement, Point>()
                    .on('start', (event, d) => {
                        if (!groupRef.current) return;
                        const svgElement = groupRef.current;

                        // Get initial mouse position in SVG coordinates  
                        const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                            event.sourceEvent.clientX,
                            event.sourceEvent.clientY,
                        ]);

                        initialVertexDragState.current.initialMousePos = [mouseX, mouseY];
                        initialVertexDragState.current.initialPoint = [...d];
                    })
                    // Use regular function expression and annotate 'this'  
                    .on('drag', function (this: SVGCircleElement, event, d) {
                        if (!groupRef.current) return;
                        const svgElement = groupRef.current;

                        // Get current mouse position in SVG coordinates  
                        const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                            event.sourceEvent.clientX,
                            event.sourceEvent.clientY,
                        ]);

                        // Calculate deltas  
                        const dx = mouseX - initialVertexDragState.current.initialMousePos[0];
                        const dy = mouseY - initialVertexDragState.current.initialMousePos[1];

                        let x = initialVertexDragState.current.initialPoint[0] + dx;
                        let y = initialVertexDragState.current.initialPoint[1] + dy;

                        // Snap to grid  
                        x = Math.round(x / GRID_SIZE) * GRID_SIZE;
                        y = Math.round(y / GRID_SIZE) * GRID_SIZE;

                        const index = points.indexOf(d);

                        // Snapping behavior with neighboring points  
                        const prevPoint = points[(index - 1 + points.length) % points.length];
                        const nextPoint = points[(index + 1) % points.length];

                        // Snap to align with prevPoint if close in x or y  
                        if (Math.abs(x - prevPoint[0]) <= 5) {
                            x = prevPoint[0];
                        }
                        if (Math.abs(y - prevPoint[1]) <= 5) {
                            y = prevPoint[1];
                        }

                        // Snap to align with nextPoint if close in x or y  
                        if (Math.abs(x - nextPoint[0]) <= 5) {
                            x = nextPoint[0];
                        }
                        if (Math.abs(y - nextPoint[1]) <= 5) {
                            y = nextPoint[1];
                        }

                        // Update point  
                        d[0] = x;
                        d[1] = y;

                        // Update polygon path  
                        polygon.attr('points', points.map((p) => p.join(',')).join(' '));

                        // Update vertex position  
                        d3.select(this).attr('cx', x).attr('cy', y);

                        updateEdgeMidpoints();
                    })
                    .on('end', () => {
                        updateZoneData();
                    })
            );

        const updateVertices = (): void => {
            vertices = svgGroup.selectAll<SVGCircleElement, Point>('circle.vertex').data(points);

            vertices.attr('cx', (d) => d[0]).attr('cy', (d) => d[1]);

            vertices.exit().remove();

            vertices = vertices.merge(enterVertices);
        };

        // Edge midpoints for adding new points  
        let edgeMidpoints = svgGroup.selectAll<SVGCircleElement, [Point, Point]>(
            'circle.edge-midpoint'
        );

        const updateEdgeMidpoints = (): void => {
            const edges: [Point, Point][] = points.map((point, i) => [
                point,
                points[(i + 1) % points.length],
            ]);

            edgeMidpoints = svgGroup
                .selectAll<SVGCircleElement, [Point, Point]>('circle.edge-midpoint')
                .data(edges);

            edgeMidpoints.exit().remove();

            const enterEdgeMidpoints = edgeMidpoints
                .enter()
                .append<SVGCircleElement>('circle')
                .attr('class', 'edge-midpoint')
                .attr('r', 5)
                .attr('fill', '#168970')
                .style('cursor', 'pointer')
                // Use regular function expression and annotate 'this'  
                .on('mouseover', function (this: SVGCircleElement) {
                    d3.select(this).attr('fill', '#0C5747');
                })
                .on('mouseout', function (this: SVGCircleElement) {
                    d3.select(this).attr('fill', '#168970');
                })
                .on('click', (event, datum) => {
                    // Insert new point into points array  
                    const newPoint: Point = [
                        (datum[0][0] + datum[1][0]) / 2,
                        (datum[0][1] + datum[1][1]) / 2,
                    ];

                    // Snap new point to grid  
                    newPoint[0] = Math.round(newPoint[0] / GRID_SIZE) * GRID_SIZE;
                    newPoint[1] = Math.round(newPoint[1] / GRID_SIZE) * GRID_SIZE;

                    const index = points.indexOf(datum[1]);
                    points.splice(index, 0, newPoint);

                    updatePolygonAndVertices();
                    updateZoneData();
                });

            edgeMidpoints = edgeMidpoints.merge(enterEdgeMidpoints);

            edgeMidpoints
                .attr('cx', (d) => (d[0][0] + d[1][0]) / 2)
                .attr('cy', (d) => (d[0][1] + d[1][1]) / 2);
        };

        const updatePolygonAndVertices = (): void => {
            polygon.attr('points', points.map((d) => d.join(',')).join(' '));
            updateVertices();
            updateEdgeMidpoints();
        };

        // Initial calls to set up the shapes  
        updateVertices();
        updateEdgeMidpoints();

        svgGroup.on('contextmenu', (event) => {
            event.preventDefault();
            // Open dialog to edit zone properties  
            setIsDialogOpen(true);
        });

        // Clean up function (optional)  
        return () => {
            svgGroup.selectAll('*').remove();
        };
    }, [zone, updateZone, name, belongsTo]);

    const handleDialogClose = (): void => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = (): void => {
        const updatedZone: ZoneFeature = {
            ...zone,
            name,
            belongsTo,
        };
        updateZone(updatedZone);
        setIsDialogOpen(false);
    };

    return (
        <>
            {/* Self-closed empty component */}
            <g ref={groupRef} />
            {/* Dialog for editing zone properties */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Zone</DialogTitle>
                <DialogContent>
                    <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="normal" />
                    <TextField label="Belongs To" type="number" value={belongsTo} onChange={(e) => setBelongsTo(Number(e.target.value))} fullWidth margin="normal" />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            deleteZone(zone.id);
                            handleDialogClose();
                        }} 
                            color="error"
                    >
                        Delete
                    </Button>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignerZone;  