import React, {useCallback, useRef, useState} from "react";
import * as d3 from "d3";
import {BookingFeature, DeskFeature, WallFeature} from "../../../../services/booking/types";
import {BookingAvailabilityColors} from "../../consts";
import {useFloorplanFeatures} from "./useFloorplanFeatures";
import {useDesignerContext} from '../DesignerContext';

export const useDesigner = () => {
    const {
        floorPlanFeatures,
        setFloorPlanFeatures,
        featureIndex,
        floorPlanId,
        locationId,
        availableFeatures,
        svgRef,
        isSelecting,
        setIsSelecting,
        selectedFeatureIds,
        setSelectedFeatureIds,
        isBulkEditDialogOpen,
        setIsBulkEditDialogOpen
    } = useDesignerContext();

    const gRef = useRef<SVGGElement | null>(null);
    const originPoint = useRef<{ x: number; y: number }>({x: 0, y: 0});
    const [bulkRotationAngle, setBulkRotationAngle] = useState<number>(0);
    const [bulkDeskDesc, setBulkDeskDesc] = useState<string>('');
    const [bulkSelectedFeatures, setBulkSelectedFeatures] = useState<number[]>([]);
    const [selectionRect, setSelectionRect] = useState<{
        x: number;
        y: number;
        width: number;
        height: number;
    } | null>(null);
    const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false);
    const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(true);

    // Implement pan and zoom using D3 zoom behavior  
    const isPanningRef = useRef(false); // To track if panning is in progress  

    const {
        addFeature,
        deleteFeature,
    } = useFloorplanFeatures();

    const handleBackgroundClick = (event: React.MouseEvent<SVGRectElement>) => {
        if (!isPanningRef.current && !isSelecting) {
            setSelectedFeatureIds([]);
        }
    };

    const handleSnapToGridAfterDropChange = useCallback(() => {
        setSnapToGridAfterDrop(!snapToGridAfterDrop);
    }, [snapToGridAfterDrop]);

    const handleSnapToGridWhileDraggingChange = useCallback(() => {
        setSnapToGridWhileDragging(!snapToGridWhileDragging);
    }, [snapToGridWhileDragging]);

    const handleSvgMouseUp = (event: React.MouseEvent<SVGSVGElement>) => {
        if (!isSelecting) return;
        setIsSelecting(false);

        const svg = svgRef.current!;
        const point = svg.createSVGPoint();

        // Account for current zoom and pan transformations  
        const currentTransform = d3.zoomTransform(svg);

        point.x = (event.clientX - currentTransform.x) / currentTransform.k;
        point.y = (event.clientY - currentTransform.y) / currentTransform.k;

        const endX = point.x;
        const endY = point.y;

        const selectionBBox = {
            x: Math.min(originPoint.current.x, endX),
            y: Math.min(originPoint.current.y, endY),
            width: Math.abs(endX - originPoint.current.x),
            height: Math.abs(endY - originPoint.current.y),
        };

        const selectedFeatures = floorPlanFeatures.filter((feature) => {
            if (feature.typeId !== 1) {
                return false;
            }
            const featureBBox = {
                x: feature.x,
                y: feature.y,
                width: feature.width,
                height: feature.height,
            };
            return (
                featureBBox.x >= selectionBBox.x &&
                featureBBox.x + featureBBox.width <= selectionBBox.x + selectionBBox.width &&
                featureBBox.y >= selectionBBox.y &&
                featureBBox.y + featureBBox.height <= selectionBBox.y + selectionBBox.height
            );
        });

        setSelectedFeatureIds(selectedFeatures.map((f) => f.id));
        setSelectionRect(null);
    };

    const handleOpenBulkEditDialog = () => {
        if (selectedFeatureIds.length > 0) {
            // Set initial values based on the first selected desk  
            const firstSelectedDesk = floorPlanFeatures.find(
                (feature) => feature.id === selectedFeatureIds[0]
            ) as DeskFeature;

            setBulkRotationAngle(firstSelectedDesk.rotationAngle ?? 0);
            setBulkDeskDesc(firstSelectedDesk.desc?.deskDesc ?? '')
            setBulkSelectedFeatures(firstSelectedDesk.components?.map((c) => c.id) || []);
            setIsBulkEditDialogOpen(true);
        }
    };

    const handleBulkUpdateFeatures = useCallback(
        (updates: Partial<Pick<DeskFeature, 'rotationAngle' | 'components' | 'desc'>>) => {
            setFloorPlanFeatures((prevFeatures) =>
                prevFeatures.map((feature) => {
                    if (selectedFeatureIds.includes(feature.id)) {
                        if (feature.typeId === 1) {
                            // DeskFeature  
                            const updatedFeature: DeskFeature = {
                                ...feature,
                                rotationAngle: updates.rotationAngle ?? feature.rotationAngle,
                                components: updates.components ?? feature.components,
                                desc: {deskDesc: updates.desc?.deskDesc} ?? {deskDesc: feature.desc?.deskDesc},
                            };
                            return updatedFeature;
                        }
                        // Other features remain unchanged  
                        return feature;
                    }
                    return feature;
                })
            );
        },
        [selectedFeatureIds, setFloorPlanFeatures]
    );

    const handleBulkEditDialogSave = () => {
        // Apply changes to selected desks  
        const selectedComponents = availableFeatures.filter((component) =>
            bulkSelectedFeatures.includes(component.id)
        );

        handleBulkUpdateFeatures({
            rotationAngle: bulkRotationAngle,
            components: selectedComponents,
            desc: {deskDesc: bulkDeskDesc}
        });

        setIsBulkEditDialogOpen(false);
    };

    // Event handler for adding desks  
    const handleAddDesks = useCallback(
        (cols: number, rows: number) => {
            const desksBlockLocal: BookingFeature[] = [];
            let desksBlockLocalIndex = featureIndex;
            const gridSpacing = 100;
            for (let cIdx = 0; cIdx < cols; cIdx += 1) {
                for (let rIdx = 0; rIdx < rows; rIdx += 1) {
                    const newDesk: DeskFeature = {
                        id: desksBlockLocalIndex,
                        floorPlanId,
                        floorPlanCategoryId: 1,
                        locationId,
                        typeId: 1,
                        type: 'Desk',
                        statusId: 1,
                        status: 'Available',
                        label: '',
                        x: 225 + gridSpacing * cIdx,
                        y: 100 + gridSpacing * rIdx,
                        width: 68,
                        height: 95,
                        fill: '#BFCBC8',
                        borderRadius: 10,
                        ports: [
                            {
                                id: -1,
                                featureId: -1,
                                radius: 16,
                                x: '2',
                                y: '0',
                                fill: BookingAvailabilityColors.AVAILABLE,
                            },
                        ],
                        createdById: 0,
                        components: [],
                        additionalInfo: '',
                    };
                    desksBlockLocal.push(newDesk);
                    desksBlockLocalIndex += 1;
                }
            }

            desksBlockLocal.forEach((desk) => addFeature(desk));
        },
        [addFeature, featureIndex, floorPlanId, locationId]
    );

    // Memoize the function passed to onSelected  
    const handleOnSelected = useCallback(
        (cols: number, rows: number) => {
            handleAddDesks(cols, rows);
        },
        [handleAddDesks]
    );

    // Event handler for adding office  
    const handleAddOffice = useCallback(() => {
        const newOfficeFeature: BookingFeature = {
            id: featureIndex,
            floorPlanId,
            floorPlanCategoryId: 1,
            locationId,
            typeId: 10,
            type: 'OfficeOrMeeting',
            statusId: 1,
            status: 'Available',
            label: `Office`,
            x: 250,
            y: 250,
            width: 500,
            height: 500,
            fill: '#B2B2B2',
            borderRadius: 10,
            ports: [],
            createdById: 0,
            components: [],
            additionalInfo: '',
        };

        addFeature(newOfficeFeature);
    }, [addFeature, featureIndex, floorPlanId, locationId]);

    // Event handler for adding wall  
    const handleAddWall = useCallback(() => {
        const newWall: WallFeature = {
            id: featureIndex,
            height: 0,
            width: 0,
            floorPlanId,
            floorPlanCategoryId: 1,
            locationId,
            typeId: 7,
            type: 'Wall',
            statusId: 1,
            status: 'Available',
            label: `Wall`,
            x: 0,
            y: 0,
            startPoint: {x: 300, y: 300},
            endPoint: {x: 400, y: 300},
            fill: '#B2B2B2',
            thickness: 5,
            borderRadius: 0,
            ports: [],
            createdById: 0,
            components: [],
            additionalInfo: '',
        };

        addFeature(newWall);
    }, [addFeature, featureIndex, floorPlanId, locationId]);

    // Event handler for adding text label  
    const handleAddTextLabel = useCallback(() => {
        const newTextFeature: BookingFeature = {
            id: featureIndex,
            floorPlanId,
            floorPlanCategoryId: 1,
            locationId,
            typeId: 9,
            type: 'Text',
            statusId: 1,
            status: 'Available',
            label: 'New Label',
            color: '#000000',
            fontSize: 14,
            x: 100,
            y: 100,
            width: 0,
            height: 0,
            fill: '',
            borderRadius: 0,
            ports: [],
            createdById: 0,
            components: [],
            additionalInfo: '',
            chip: false,
        };

        addFeature(newTextFeature);
    }, [addFeature, featureIndex, floorPlanId, locationId]);

    // Event handler for adding text label with chip  
    const handleAddTextLabelWithChip = useCallback(() => {
        const newTextFeature: BookingFeature = {
            id: featureIndex,
            floorPlanId,
            floorPlanCategoryId: 1,
            locationId,
            typeId: 9,
            type: 'Text',
            statusId: 1,
            status: 'Available',
            label: 'New Label',
            color: '#000000',
            fontSize: 14,
            x: 100,
            y: 100,
            width: 0,
            height: 0,
            fill: '',
            borderRadius: 0,
            ports: [],
            createdById: 0,
            components: [],
            additionalInfo: '',
            chip: true,
        };

        addFeature(newTextFeature);
    }, [addFeature, featureIndex, floorPlanId, locationId]);

    // Handle selection rectangle drawing and selection logic  
    const handleSvgMouseDown = (event: React.MouseEvent<SVGSVGElement>) => {
        if (event.target !== svgRef.current || isPanningRef.current) {
            // Clicked on a feature or currently panning, not the background  
            return;
        }
        const svg = svgRef.current;
        const point = svg.createSVGPoint();

        // Account for current zoom and pan transformations  
        const currentTransform = d3.zoomTransform(svg);

        point.x = (event.clientX - currentTransform.x) / currentTransform.k;
        point.y = (event.clientY - currentTransform.y) / currentTransform.k;

        originPoint.current = {x: point.x, y: point.y};
        setSelectionRect({x: point.x, y: point.y, width: 0, height: 0});
        setIsSelecting(true);
    };

    const handleSvgMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
        if (!isSelecting || !selectionRect) return;
        const svg = svgRef.current!;
        const point = svg.createSVGPoint();

        // Account for current zoom and pan transformations  
        const currentTransform = d3.zoomTransform(svg);

        point.x = (event.clientX - currentTransform.x) / currentTransform.k;
        point.y = (event.clientY - currentTransform.y) / currentTransform.k;

        const newWidth = point.x - originPoint.current.x;
        const newHeight = point.y - originPoint.current.y;

        setSelectionRect({
            x: originPoint.current.x,
            y: originPoint.current.y,
            width: newWidth,
            height: newHeight,
        });
    };

    // Function to handle feature selection from child components  
    const handleSelectFeature = useCallback(
        (featureId: number, event: any) => {
            event.stopPropagation();
            if (event.ctrlKey || event.metaKey) {
                if (selectedFeatureIds.includes(featureId)) {
                    setSelectedFeatureIds(selectedFeatureIds.filter((id) => id !== featureId));
                } else {
                    setSelectedFeatureIds([...selectedFeatureIds, featureId]);
                }
            } else {
                setSelectedFeatureIds([featureId]);
            }
        },
        [selectedFeatureIds]
    );

    // Function to handle deleting selected features  
    const handleDeleteSelectedFeatures = useCallback(() => {
        selectedFeatureIds.forEach((id) => {
            deleteFeature(id);
        });
        setSelectedFeatureIds([]);
    }, [selectedFeatureIds, deleteFeature]);

    // Function to handle copying and pasting selected features  
    const handleCopyPasteSelectedFeatures = useCallback(() => {
        const maxId = Math.max(...floorPlanFeatures.map((f) => f.id), 0);
        let newId = maxId + 1;

        const newFeatures = floorPlanFeatures
            .filter((f) => selectedFeatureIds.includes(f.id))
            .map((f) => {
                const copiedFeature = {...f, id: newId, x: f.x + 20, y: f.y + 20};
                newId += 1;
                return copiedFeature;
            });

        setFloorPlanFeatures([...floorPlanFeatures, ...newFeatures]);
    }, [selectedFeatureIds, floorPlanFeatures, setFloorPlanFeatures]);


    const initializeZoom = useCallback(() => {
        if (svgRef.current && gRef.current) {
            const svg = d3.select(svgRef.current);
            const g = d3.select(gRef.current);

            // Define the zoom behavior with a custom filter  
            const zoomBehavior = d3
                .zoom<SVGSVGElement, unknown>()
                .filter((event) => {
                    // Only proceed if it's a wheel event or a mousedown event with the left button and no modifier keys  
                    if (event.type === 'wheel') {
                        return true
                    }
                    return event.type === 'mousedown' &&
                        event.button === 0 && // Left mouse button  
                        !event.shiftKey &&
                        !event.altKey &&
                        !event.ctrlKey &&
                        !event.metaKey;

                })
                .scaleExtent([0.1, 10]) // Adjust the scale extent as needed  
                .on('start', () => {
                    isPanningRef.current = true;
                })
                .on('zoom', (event) => {
                    // Apply the zoom transformation to the <g> element  
                    g.attr('transform', event.transform);
                })
                .on('end', () => {
                    isPanningRef.current = false;
                });

            // Attach the zoom behavior to the SVG  
            svg.call(zoomBehavior);

            // Cleanup on unmount  
            return () => {
                svg.on('.zoom', null);
            };
        }
    }, [svgRef, gRef]);

    return {
        handleBackgroundClick,
        handleSnapToGridAfterDropChange,
        handleSnapToGridWhileDraggingChange,
        handleSvgMouseUp,
        handleOpenBulkEditDialog,
        handleBulkEditDialogSave,
        handleBulkUpdateFeatures,
        handleAddDesks,
        handleOnSelected,
        handleAddOffice,
        handleAddWall,
        handleAddTextLabel,
        handleAddTextLabelWithChip,
        handleSvgMouseDown,
        handleSvgMouseMove,
        handleSelectFeature,
        handleDeleteSelectedFeatures,
        handleCopyPasteSelectedFeatures,
        initializeZoom,
        isBulkEditDialogOpen,
        gRef,
        selectedFeatureIds,
        snapToGridWhileDragging,
        isSelecting,
        selectionRect,
        setIsBulkEditDialogOpen,
        bulkRotationAngle,
        setBulkRotationAngle,
        bulkSelectedFeatures,
        setBulkSelectedFeatures,
        bulkDeskDesc,
        setBulkDeskDesc
    }
}