import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { DeskStatusColor } from '../../Desks/enums';
import { BookingAvailabilityColors } from '../../consts';
import { DeskFeature } from '../../../../services/booking/types';
import { DesignerDeskProps, FeatureDragPosition } from "../types";
import { useFloorplanFeatures } from "../hooks/useFloorplanFeatures";
import { useDesignerDesk } from "../hooks/useDesignerDesk";
import { useDesigner } from "../hooks/useDesigner";
import { useDesignerContext } from "../DesignerContext";
import {normalizeKeys} from "../Utils/toolbarUtils";

function DesignerDesk({
                          featureDetail,
                          snapToGrid,
                          isSelected,
                          selectedFeatureIds,
                      }: Readonly<DesignerDeskProps>) {
    const {
        floorPlanFeatures,
        availableFeatures,
        svgRef,
    } = useDesignerContext();

    const {
        handleSelectFeature,
    } = useDesigner();

    const {
        getPortPosition,
        getLabelPosition,
        handleDialogClose,
        handleDialogSave,
        handleDelete,
        setIsDialogOpen,
        isDialogOpen,
        selectedFeatures,
        setSelectedFeatures,
        labelText,
        setLabelText,
        assetTag,
        setAssetTag,
        qrCode,
        setQrCode,
        rotationAngle,
        setRotationAngle,
        deskDescription,
        setDeskDescription,
    } = useDesignerDesk(featureDetail);

    const {
        updateMultipleFeaturePositions,
        updateFeaturePosition,
        rotateFeature,
    } = useFloorplanFeatures();

    const objectRef = useRef<SVGGElement | null>(null);
    const [isDblClick, setIsDblClick] = useState<boolean>(false);
    const initialPositionsRef = useRef<{ [id: number]: FeatureDragPosition }>({});

    useEffect(() => {
        // Parse additionalInfo to get assetTag, qrCode, and deskDescription
        let parsedAdditionalInfo: { assetTag?: string; qrCode?: string; deskDesc?: string } = {};
        try {
            const additionalInfo = featureDetail.additionalInfo ? JSON.parse(featureDetail.additionalInfo) : {};
            // Normalize field names using normalizeKeys function
            parsedAdditionalInfo = normalizeKeys(additionalInfo);
        } catch (error) {
            // Replace console.error with a proper logging mechanism if needed
            console.error('Error parsing additionalInfo:', error);
        }

        const updatedFeatureDetail = { ...featureDetail };

        if (rotationAngle === 90 || rotationAngle === 270) {
            updatedFeatureDetail.height = 95;
            updatedFeatureDetail.width = 68;
        }

        setLabelText(updatedFeatureDetail.label);
        setAssetTag(parsedAdditionalInfo.assetTag ?? '');
        setQrCode(parsedAdditionalInfo.qrCode ?? '');
        setDeskDescription(parsedAdditionalInfo.deskDesc ?? '');

        // Set selected features from featureDetail.components
        setSelectedFeatures(updatedFeatureDetail.components?.map((component) => component.id) || []);

        // Set Rotation Angle
        setRotationAngle(updatedFeatureDetail.rotationAngle ?? 0);
    }, [featureDetail, setAssetTag, setLabelText, setQrCode, setDeskDescription, setSelectedFeatures]);

    useEffect(() => {
        if (objectRef.current) {
            const selection = d3.select(objectRef.current);

            selection
                .on('click', (event: MouseEvent) => {
                    handleSelectFeature(featureDetail.id, event);
                })
                .on('dblclick', (event: MouseEvent) => {
                    const targetElement = event.target as HTMLElement;
                    if (
                        targetElement.tagName === 'text' ||
                        targetElement.tagName === 'INPUT' ||
                        targetElement.closest('foreignObject')
                    ) {
                        // Do not rotate if the double click was on the label or input
                        return;
                    }

                    event.stopPropagation();

                    setIsDblClick(true);

                    if (rotateFeature) {
                        const currentRotation = featureDetail.rotationAngle ?? 0;
                        const newRotation = (currentRotation + 45) % 360;
                        rotateFeature(featureDetail.id, newRotation);
                    }

                    setTimeout(() => {
                        setIsDblClick(false);
                    }, 100);
                })
                .on('contextmenu', (event: MouseEvent) => {
                    event.preventDefault();
                    if (selectedFeatureIds.length > 1 && isSelected) {
                        // Do nothing; bulk edit is handled elsewhere
                    } else {
                        // Open individual edit dialog
                        setIsDialogOpen(true);
                    }
                });
        }
    }, [featureDetail, rotateFeature, handleSelectFeature, isSelected, selectedFeatureIds, setIsDialogOpen]);

    // Updated Drag behavior
    useEffect(() => {
        if (objectRef.current) {
            if (isDblClick) {
                return;
            }
            const grid = 5; // Grid size for snapping

            const dragBehavior = d3
                .drag<SVGGElement, unknown>()
                .on('start', (event) => {
                    if (!svgRef.current) return;
                    const svgElement = svgRef.current;

                    // Store initial positions and offsets of selected features
                    initialPositionsRef.current = {};
                    const selectedIds = selectedFeatureIds.includes(featureDetail.id)
                        ? selectedFeatureIds
                        : [featureDetail.id];

                    // Get initial mouse position in SVG coordinates
                    const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                        event.sourceEvent.clientX,
                        event.sourceEvent.clientY,
                    ]);

                    selectedIds.forEach((id) => {
                        const f = floorPlanFeatures.find((feature) => feature.id === id) as DeskFeature;
                        if (f) {
                            initialPositionsRef.current[id] = {
                                x: f.x,
                                y: f.y,
                                offsetX: mouseX - f.x,
                                offsetY: mouseY - f.y,
                            };
                        }
                    });
                })
                .on('drag', (event) => {
                    if (!svgRef.current) return;
                    const svgElement = svgRef.current;

                    // Get current mouse position in SVG coordinates
                    const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                        event.sourceEvent.clientX,
                        event.sourceEvent.clientY,
                    ]);

                    const selectedIds = Object.keys(initialPositionsRef.current).map(Number);

                    selectedIds.forEach((id) => {
                        const initialPos = initialPositionsRef.current[id];

                        let newX = mouseX - initialPos.offsetX;
                        let newY = mouseY - initialPos.offsetY;

                        if (snapToGrid) {
                            newX = Math.round(newX / grid) * grid;
                            newY = Math.round(newY / grid) * grid;
                        }

                        const movingFeature = floorPlanFeatures.find((feature) => feature.id === id) as DeskFeature;

                        if (movingFeature && d3.select(`#desk-${id}`).node()) {
                            const currentRotationAngle = movingFeature.rotationAngle || 0;
                            const rotationCenterX = movingFeature.width / 2;
                            const rotationCenterY = movingFeature.height / 2;

                            // Update the desk position
                            d3.select(`#desk-${id}`).attr(
                                'transform',
                                `translate(${newX}, ${newY}) rotate(${currentRotationAngle}, ${rotationCenterX}, ${rotationCenterY})`
                            );

                            // Update the label position
                            const labelX = newX + rotationCenterX;
                            const labelY = newY + rotationCenterY;

                            d3.select(`#desk-label-${id}`)
                                .attr('x', labelX)
                                .attr('y', labelY);

                            // Update current position
                            initialPos.x = newX;
                            initialPos.y = newY;
                        }
                    });
                })
                .on('end', () => {
                    const updates = Object.keys(initialPositionsRef.current).map((idStr) => {
                        const id = Number(idStr);
                        const initialPos = initialPositionsRef.current[id];
                        return { id, x: initialPos.x, y: initialPos.y };
                    });

                    if (updates.length > 0) {
                        updateMultipleFeaturePositions(updates);
                    }

                    initialPositionsRef.current = {};
                });

            d3.select(objectRef.current)
                .attr('id', `desk-${featureDetail.id}`)
                .call(dragBehavior);
        }
    }, [
        featureDetail,
        selectedFeatureIds,
        floorPlanFeatures,
        snapToGrid,
        isDblClick,
        updateFeaturePosition,
        updateMultipleFeaturePositions,
        svgRef,
    ]);

    useEffect(() => {
        if (objectRef.current) {
            const currentRotationAngle = featureDetail.rotationAngle ?? 0;
            const rotationCenterX = featureDetail.width / 2;
            const rotationCenterY = featureDetail.height / 2;

            d3.select(objectRef.current).attr(
                'transform',
                `translate(${featureDetail.x}, ${featureDetail.y}) rotate(${currentRotationAngle}, ${rotationCenterX}, ${rotationCenterY})`
            );
        }
    }, [featureDetail.x, featureDetail.y, featureDetail.rotationAngle, featureDetail.width, featureDetail.height]);

    const PORT_BORDER_RADIUS =
        featureDetail.ports?.length && featureDetail.ports?.[0].radius ? Number(featureDetail.ports?.[0].radius) * 1.5 : 0;

    const position = getPortPosition();
    const { x: labelX, y: labelY, textAnchor } = getLabelPosition();

    return (
        <>
            <g ref={objectRef} id={`desk-${featureDetail.id}`} style={{ cursor: 'pointer' }}>
                <rect
                    x={0}
                    y={0}
                    width={featureDetail.width}
                    height={featureDetail.height}
                    rx={featureDetail.borderRadius}
                    fill={isSelected ? '#7d8583' : DeskStatusColor.ACTIVE}
                    stroke={isSelected ? 'black' : 'none'}
                    strokeWidth={isSelected ? 4 : 0}
                />
                {Boolean(featureDetail.ports?.length) && (
                    <g>
                        <circle
                            cx={position.x}
                            cy={position.y}
                            r={PORT_BORDER_RADIUS}
                            fill="white"
                            stroke="black"
                            strokeWidth="1"
                            strokeDasharray="2,2"
                        />
                        <circle
                            cx={position.x}
                            cy={position.y}
                            r={PORT_BORDER_RADIUS - 6}
                            fill="white"
                        />
                        <circle
                            cx={position.x}
                            cy={position.y}
                            r={featureDetail.ports?.[0].radius}
                            fill={BookingAvailabilityColors.AVAILABLE}
                        />
                    </g>
                )}
            </g>
            <text
                id={`desk-label-${featureDetail.id}`}
                x={featureDetail.x + labelX}
                y={featureDetail.y + labelY}
                fill="white"
                fontFamily="Poppins"
                fontSize="22px"
                fontWeight="bold"
                textAnchor={textAnchor}
                style={{ dominantBaseline: 'middle', pointerEvents: 'none' }}
            >
                {labelText}
            </text>
            {/* Individual Desk Dialog */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Desk</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Label"
                        value={labelText}
                        onChange={(e) => setLabelText(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Asset Tag"
                        value={assetTag}
                        onChange={(e) => setAssetTag(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="QR Code"
                        value={qrCode}
                        onChange={(e) => setQrCode(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Desk Description"
                        value={deskDescription}
                        onChange={(e) => setDeskDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="rotation-angle-label">Rotation Angle</InputLabel>
                        <Select
                            labelId="rotation-angle-label"
                            value={rotationAngle}
                            onChange={(e) => setRotationAngle(Number(e.target.value))}
                            label="Rotation Angle"
                        >
                            {[0, 45, 90, 135, 180, 225, 270, 315].map((angle) => (
                                <MenuItem key={angle} value={angle}>
                                    {angle}
                                    °
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="features-label">Features</InputLabel>
                        <Select
                            labelId="features-label"
                            multiple
                            value={selectedFeatures}
                            onChange={(e) => setSelectedFeatures(e.target.value as number[])}
                            renderValue={(selected) =>
                                (selected as number[])
                                    .map(
                                        (id) => availableFeatures.find((component) => component.id === id)?.name
                                    )
                                    .join(', ')
                            }
                            label="Features"
                        >
                            {availableFeatures.map((component) => (
                                <MenuItem key={component.id} value={component.id}>
                                    <Checkbox checked={selectedFeatures.includes(component.id)} />
                                    <ListItemText primary={component.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignerDesk;