import { useDispatch, useSelector, batch } from 'react-redux'
import { useState, useEffect, useMemo, useRef, useCallback, memo, createRef } from 'react'
import { addMonths, getMonth, isSameMonth, startOfMonth, format } from 'date-fns'
import { Box, Grid, Fade } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { RootStore } from '../../../redux/store'
import { getDeskName, cleanUsername } from '../utils/utils'
import { BookingScrollContainer } from '../components'
import { BookingContainer } from '../MyBookings'
import CardTitle from '../../../shared/UI/CardTitle'
import { BookinGridViewProps } from './types'
import {
  BookingEmployeeBooking,
} from '../../../services/booking/types'
import { BookingListMaxMonths, BookingStatus } from './enums'
import {
  setDeskBookingShowFloorplan,
  setDeskBookingShowGridView,
  setDeskBookingFocussedZoneID,
  setBookingFloorplanViewingDate,
  setDeskBookingLoading,
  setShowScrollToTopButton,
  setForwardDeskBookingsCount,
} from '../../../redux/reducers/deskBookingReducer'
import noDesk from '../../../assets/NoDesk.svg'
import Paragraph from '../../../shared/UI/Paragraph'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import { NoDesk } from './components'
import { MONTHS } from '../../../utils/constants'
import {
  setDeskBookingSearchParams,
  setDeskBookingShowSearch,
} from '../../../redux/reducers/deskBookingSearchReducer'
import { showComponents, defaultZoneIdIfNoneSelected } from '../utils'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'
import LazyCollapse from '../../../shared/layout/LazyCollapse'
import ScrollToTopButton from '../../../shared/UI/ScrollToTopButton'
import {
  getDashboardBookings,
  sortDashboardBookings,
  onBookingGridViewDataLoaded,
} from '../bookingLogic'
import { checkIsOpenFloorplanArea } from './bookingFloorplanLogic'

const LazyCollapseMemo = memo(LazyCollapse)
const BookingContainerMemo = memo(BookingContainer)
const ScrollToTopButtonMemo = memo(ScrollToTopButton)

const { CANCELLED, ACCEPTED } = BookingStatus

const {
  BOOKINGS_MAX_MONTHS,
  BOOKINGS_MAX_BOOKINGS,
  BOOKINGS_MAX_THROTTLE,
  BOOKINGS_MIN_CHECK,
  BOOKINGS_MIN_DISPLAY,
  BOOKINGS_DELAY_FACTOR,
  BOOKINGS_SEEK_ATLEAST,
  BOOKINGS_SEEK_BOOKING
} = BookingListMaxMonths

export default function BookingScrollView({
  onCancel,
  onDataLoaded,
  onBookMoreDesks,
  onShowMeShared,
}: BookinGridViewProps) {
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const dispatch = useDispatch()

  const { floorplans, zones, infinityBookingsResults, bookingIsLoading, forwardDeskBookingsCount } =
  useSelector((state: RootStore) => state.deskBooking)

  const scrollToTopRef = createRef<HTMLElement | null>()
  const [scrollPos, setScrollPos] = useState(false)

  const employeeFloorplans = useMemo(
    () =>
      floorplans.filter(f => f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY],
    [employeeDetails.location.id, floorplans]
  )

  const employeeZones = useMemo(
    () =>
      zones.filter(
        z =>
          employeeFloorplans.some(s => s.id === z.floorPlanId) &&
          z.belongsTo === employeeDetails.departmentId
      ),
    [employeeDetails.departmentId, employeeFloorplans, zones]
  )

  const [firstLoaded, setFirstLoad] = useState<boolean>(false)
  const [onLoaded, setOnLoaded] = useState<boolean>(false)
  const [scrolledBackToTopReset, setScrolledBackToTopReset] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [monthNum, setMonthNum] = useState<number>(0)
  const [monthEmptyNum, setMonthEmptyNum] = useState<number>(0)
  const [monthLoaded, setMonthLoaded] = useState<Array<number>>([])
  const [monthLoadedName, setMonthLoadedName] = useState<string>(format(new Date(), 'LLLL'))
  const [localBookings, setLocalBookings] = useState<Array<BookingEmployeeBooking>>([])

  const abortControllerRef = useRef<AbortController | null>(null)

  const singleDayBookingQuery = false
  const autoUpdate = true
  const infinityMode = true

  const jumpToNextMonth = useCallback(() => setMonthNum((no) => no + 1), [])

  const observer = useRef(
    new IntersectionObserver(
        (entries) => {
            const first = entries[0]
            if (first.isIntersecting && monthNum < BOOKINGS_MAX_MONTHS) {
              jumpToNextMonth()
            }
        }, {
          root: null,
          threshold: [0.75, 1.0],
        })
  )

  const lastElement = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()

      if (node) observer.current.observe(node)
    },
    [loading, observer]
  )

  const fetchUserBookingsMonth = useCallback(() => {

    setLoading(true)

    const today = new Date()
    const monthShift = addMonths(today, monthNum)
    const theMonth = getMonth(monthShift)
    // const lastMonthLimit = getMonth(addMonths(today, BOOKINGS_MAX_MONTHS))
    // const isLastMonth = theMonth === lastMonthLimit

    monthLoaded[monthNum] = theMonth // format(theMonth, 'LLLL')
    setMonthLoaded(monthLoaded)
    setMonthLoadedName(MONTHS[theMonth])

    getDashboardBookings(
      employeeDetails.employeeId,
      isSameMonth(theMonth, today) ? today : startOfMonth(monthShift),
      dispatch,
      onBookingGridViewDataLoaded,
      singleDayBookingQuery,
      abortControllerRef,
      undefined,
      autoUpdate,
      infinityMode
    ).then((bookings) => {

      if (bookings?.bookings.length === 0 || !bookings?.bookings) setMonthEmptyNum(monthNum)

      setLoading(false)
    })

  }, [monthNum, monthLoaded])

  useEffect(() => {

    const localBookingsFilter = infinityBookingsResults &&
      infinityBookingsResults?.results.filter(f => f.statusId === ACCEPTED)

    if (localBookingsFilter) {

      const latestBookings = sortDashboardBookings([
        ...localBookings, ...localBookingsFilter
      ])

      setLocalBookings(latestBookings)
    }

  }, [infinityBookingsResults?.results])

  const bookingsMonthIsLessOrEqualThanMax:boolean = useMemo(() =>
    monthNum < BOOKINGS_MAX_MONTHS,
  [monthNum])
  const bookingsMonthIsMoreThanMin:boolean = useMemo(() =>
    monthNum > BOOKINGS_MIN_CHECK,
  [monthNum])
  const bookingsLessThanDiaplayMin:boolean = useMemo(() =>
    localBookings.length < BOOKINGS_MIN_DISPLAY,
  [localBookings])
  const bookingsEmpty:boolean = useMemo(() =>
   !localBookings.length,
  [localBookings])
  const bookingsNotEmptyLessThanFour:boolean = useMemo(() =>
    localBookings.length < 4 && !bookingsEmpty,
  [localBookings, bookingsEmpty])
  const bookingsLessThanMaxBookings:boolean = useMemo(() =>
    localBookings.length < BOOKINGS_MAX_BOOKINGS,
  [localBookings])
  const noFowardDesksBookedCheckReady:boolean = useMemo(() =>
  forwardDeskBookingsCount !== undefined,
  [forwardDeskBookingsCount])
  const noFowardDesksBooked:boolean = useMemo(() =>
    (forwardDeskBookingsCount ?? 0) === 0,
  [forwardDeskBookingsCount])

  const isLastBooking = (bookingsResult: Array<BookingEmployeeBooking>, bookingsIndex: number) => {

    const bookingsLength = bookingsResult.length
    const adjustLastBookings = (bookingsLength > BOOKINGS_SEEK_ATLEAST ? BOOKINGS_SEEK_BOOKING : 0)
    const seekLastBooking = (bookingsLength - adjustLastBookings)

    return seekLastBooking === bookingsIndex + 1 ? lastElement: null
  }

  const isBookingDateMonth = (bookingDate: string) => format(new Date(bookingDate), "M")

  const isNextMonth = useCallback((thisBooking: BookingEmployeeBooking, localMonthBookings: Array<BookingEmployeeBooking>, bookingsIndex: number) => {

    const thisBookingFromDateRaw = thisBooking.fromDate

    if (bookingsIndex > localMonthBookings.length) return true

    if (monthLoaded[0] === getMonth(new Date(thisBookingFromDateRaw))) return false

    if (bookingsIndex > 0 && monthNum > 0 && !localMonthBookings[bookingsIndex-1]) return true

    if (bookingsIndex === 0 && monthNum === 0) return false

    if (bookingsIndex === 0) return true

    const thisBookingFromDate = isBookingDateMonth(thisBookingFromDateRaw)
    const thisPreviousBooking = localMonthBookings[bookingsIndex-1]
    const previousBookingFromDate = isBookingDateMonth(thisPreviousBooking.fromDate)

    return bookingsIndex > 0 && thisBookingFromDate !== previousBookingFromDate

  }, [monthNum && monthLoaded])

  useEffect(() => {

    if (noFowardDesksBookedCheckReady && noFowardDesksBooked) return

    const fetchTurboMs = BOOKINGS_DELAY_FACTOR * monthNum
    const fetchThrottle = fetchTurboMs > BOOKINGS_MAX_THROTTLE ? BOOKINGS_MAX_THROTTLE : fetchTurboMs

    setTimeout(() => {

      if (monthNum < BOOKINGS_MAX_MONTHS) {
        fetchUserBookingsMonth()
      }
    }, fetchThrottle)

  }, [monthNum, noFowardDesksBookedCheckReady && noFowardDesksBooked])

  useEffect(() => {

    const loadedLocalBookings = !loading && !bookingIsLoading && infinityBookingsResults

    if (loadedLocalBookings) {

      onDataLoaded()
      setFirstLoad(true)
    }

  }, [infinityBookingsResults && !bookingIsLoading])

  useEffect(() => {

    if (noFowardDesksBookedCheckReady && noFowardDesksBooked) return

    const monthNumMismatch = monthEmptyNum !== monthNum
    const combinedMonthNumDelay = monthNum + monthEmptyNum * BOOKINGS_DELAY_FACTOR

    setTimeout(() => {
      if ((monthNumMismatch || bookingIsLoading) && bookingsLessThanMaxBookings) {

        jumpToNextMonth()
      }
    }, combinedMonthNumDelay)

  }, [
    monthEmptyNum && monthNum,
    infinityBookingsResults && localBookings,
    !onLoaded && bookingIsLoading,
    noFowardDesksBookedCheckReady && noFowardDesksBooked
  ])

  const scrollButtonOff = () => {
    setScrolledBackToTopReset(true)
    dispatch(setShowScrollToTopButton(false))
    setScrollPos(false)
  }

  const scrollButtonOnClick = useCallback(() => {

    if (!scrollToTopRef.current || !scrollPos) return

    try {
      scrollToTopRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    } catch {
      scrollToTopRef.current.scrollTop = 0
    }

    scrollButtonOff()
  }, [scrollToTopRef])

  const handleScrollTop = useCallback(() => {

    if (scrollToTopRef.current && !scrollPos && scrolledBackToTopReset &&
      scrollToTopRef?.current?.scrollTop <= 20) {
        setTimeout(() => setScrolledBackToTopReset(false), 500)
        return
    }

    if (scrollToTopRef.current && !scrolledBackToTopReset &&
      scrollToTopRef.current.scrollTop > 20) {
      if (!scrollPos) {
        setScrollPos(true)
        dispatch(setShowScrollToTopButton(true))
      }
    } else if (scrollPos && scrollToTopRef.current &&
      scrollToTopRef.current.scrollTop < 30) {
      scrollButtonOff()
    }
  }, [scrollToTopRef, scrolledBackToTopReset, scrollPos])

  useEffect(() => {
    const temp = scrollToTopRef.current
    temp?.addEventListener("scroll", handleScrollTop)

    return () => temp?.removeEventListener("scroll", handleScrollTop)
  }, [scrollToTopRef])

  const FirstInitialPageLoadInfinityBookingsResultsIsNull:boolean = useMemo(() =>
    infinityBookingsResults === null,
  [infinityBookingsResults])
  const RouteCompLoadInfinityBookingsResultsIsEmptyFirstMontOnly:boolean = useMemo(() =>
    infinityBookingsResults?.results.length === -1 && monthNum === 0,
  [infinityBookingsResults, monthNum])
  const RouteCompLoadInfinityBookingsResultsIsEmptyFirstMonth:boolean = useMemo(() =>
    infinityBookingsResults?.results.length === 0 && monthNum === 0,
  [infinityBookingsResults, monthNum])

  useEffect(() => {

    if (FirstInitialPageLoadInfinityBookingsResultsIsNull) setOnLoaded(true)
    if (RouteCompLoadInfinityBookingsResultsIsEmptyFirstMontOnly) fetchUserBookingsMonth()
    if (RouteCompLoadInfinityBookingsResultsIsEmptyFirstMonth) jumpToNextMonth()

    scrollButtonOff()
  }, [])

  return (
    <BookingScrollContainer id="gridview" ref={scrollToTopRef}>
      <Box
        style={{
          position: "fixed",
          right: '43%',
          background: 'transparent',
          color: '#747474',
          opacity: 0.5
        }}
        sx={{
          bottom: {
            xs: 40, sm: 10
          }
        }}
        onClick={() => scrollButtonOnClick()}
      >
        <ScrollToTopButtonMemo />
      </Box>
      {localBookings &&
        <Fade in={!bookingIsLoading} appear={false} timeout={200}>
          <Box
            width="auto"
            overflow="show"
            height='100%'
          >
            <TransitionGroup>
              {
              localBookings.map((m, index) => {
                  const { feature } = m
                  const featureZone = feature.zone
                  const deskName = getDeskName(feature.additionalInfo, feature.label)
                  const floorplanId = feature.floorPlanId
                  const floorplan = floorplans.find(f => f.id === floorplanId)
                  const fromDate = new Date(m.fromDate)
                  const isOpenFloorplanArea = checkIsOpenFloorplanArea(floorplan?.locationId || 0, floorplanId)
                  const openFloorplanAreaOrZone = isOpenFloorplanArea ? '' : featureZone?.name || ''
                  const checkForInvalidBooking = isOpenFloorplanArea ? false : !featureZone?.id && (m.department !== "" || feature.additionalInfo === "")
                  const deskNameStartsWithFloorNumber = /^\d/.test(deskName)

                  if (m.statusId !== CANCELLED) {
                    return (
                      <LazyCollapseMemo
                        key={`lc-${deskName}-${m.id}-`}
                        in={firstLoaded && !localBookings.filter(f => f.id !== m.id)} //  && !bookingIsLoading
                      >
                        <Box
                          key={`dc-${deskName}-${m.id}-`}
                          ref={isLastBooking(localBookings, index)}
                        > 
                          {isNextMonth(m, localBookings, index) && localBookings.filter(f => f.id === m.id) &&
                          <Grid key={`mc-${deskName}-${m.id}-`} xs={12} sm={12} mb={2} container display="flex" justifyContent="flex-end">
                            <Grid item xs display="flex" alignItems="center">
                              <Box flexGrow={1}>
                                <CardTitle title={format(fromDate,  "MMMM")} />
                              </Box>
                            </Grid>
                          </Grid>}
                          <BookingContainerMemo
                            key={`bc-${deskName}-${m.id}-`}
                            bookingID={m.id}
                            date={fromDate}
                            dateFrom={new Date(`${m.fromDate} ${m.fromTime}`)}
                            dateTo={new Date(`${m.fromDate} ${m.toTime}`)}
                            location={!isOpenFloorplanArea && !deskNameStartsWithFloorNumber ? `${floorplan?.name}` : `${floorplan?.locationName}`}
                            zone={openFloorplanAreaOrZone}
                            invalidBooking={checkForInvalidBooking}
                            featureLabel={deskName}
                            components={
                              feature.components ? feature.components?.map(c => c.name).join(', ') : ''
                            }
                            isCheckedIn={Boolean(m.checkInOut.length)}
                            bookedFor={
                              cleanUsername(m.displayName) !== cleanUsername(userInfo?.name)
                                ? m.displayName
                                : ''
                            }
                            onCancel={() => {
                              onCancel?.({
                                bookingID: m.id,
                                deskName,
                                floorPlanName: floorplan?.name || '',
                                location: floorplan?.locationName || '',
                                date: fromDate,
                                byManager: false,
                                onCallBack: success => {
                                  if (success) {
                                    setLocalBookings([...localBookings.filter(f => f.id !== m.id)])
                                    if (forwardDeskBookingsCount)
                                      dispatch(setForwardDeskBookingsCount(forwardDeskBookingsCount - 1))
                                  }
                                },
                              })
                            }}
                            onShowMe={() => {

                              const { fromTime, toTime } = m
                              const { locationId, floorPlanId, zone } = feature

                              const zoneId: number =
                                zone?.id ||
                                zones.find(
                                  f =>
                                    f.floorPlanId === floorPlanId
                                )?.id ||
                                defaultZoneIdIfNoneSelected(employeeZones) ||
                                0

                              const thisShowMeSearchParams = {
                                locationId,
                                floorplanId: floorPlanId,
                                date: fromDate,
                                from: fromTime,
                                to: toTime,
                                zoneId,
                                autoSearch: true,
                              }

                              batch(() => {
                                showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })

                                dispatch(setDeskBookingLoading(true))
                                dispatch(setDeskBookingFocussedZoneID(zoneId))
                                dispatch(setBookingFloorplanViewingDate(fromDate))
                                dispatch(setDeskBookingSearchParams(thisShowMeSearchParams))

                                dispatch(setDeskBookingShowSearch(false))
                                dispatch(setDeskBookingShowFloorplan(true))
                                dispatch(setDeskBookingShowGridView(false))
                              })

                              onShowMeShared(thisShowMeSearchParams)
                            }}
                          />
                        </Box>
                      </LazyCollapseMemo>
                    )
                  }

                  return ''
                })
              }
            </TransitionGroup>
          </Box>
        </Fade>
      }

      {!noFowardDesksBooked && bookingsLessThanDiaplayMin && bookingsMonthIsLessOrEqualThanMax &&
        <>
          <Fade in appear timeout={400}>
            <Box display="flex" height="auto" flexDirection="column" justifyContent="top">
              <Box display="flex" alignItems="center" flexDirection="column">
                <LoadingIndicator show alignItems="center" margin="20px" />
                <Paragraph size="16px" weight="bold" color="#2c2965">
                  Loading desk bookings from
                  <br />
                  {monthLoadedName}
                  ...
                </Paragraph>
              </Box>
            </Box>
          </Fade>
          {!noFowardDesksBooked && bookingsMonthIsMoreThanMin && bookingsLessThanDiaplayMin && !localBookings[0] &&
            <Fade in appear timeout={3000}>
              <Box display="flex" height="auto" marginTop="20px" flexDirection="column">
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Paragraph size="13px" weight="bold" color="#2c2965">
                    ...almost found them...
                  </Paragraph>
                </Box>
              </Box>
            </Fade>
          }
        </>
      }

      {!bookingIsLoading && loading && infinityBookingsResults?.results[0] && monthNum > 0 &&
        <Fade in appear timeout={1000}>
          <Box display="flex" alignItems="center" marginTop="20px" flexDirection="column">
            <Paragraph size="16px" weight="bold" color="#2c2965">
              Loading next month&nbsp;
              {monthLoadedName}
              ...
            </Paragraph>
          </Box>
        </Fade>
      }

      {!bookingIsLoading && infinityBookingsResults && bookingsNotEmptyLessThanFour &&
        <Fade in appear timeout={8000}>
          <Box display="flex" position="absolute" top="450px" left="12%" height="270px" justifyContent="center">
            <Box display="flex" alignItems="center" flexDirection="column" onClick={() => onBookMoreDesks?.()}>
              <NoDesk src={noDesk} />
              <Paragraph size="16px" weight="bold" color="#2c2965">
                Have you booked enough desks?
              </Paragraph>
            </Box>
          </Box>
        </Fade>
      }

      {(
        noFowardDesksBookedCheckReady &&
        noFowardDesksBooked &&
        (
          bookingsEmpty || !localBookings ||
          firstLoaded && bookingsEmpty
        )
      ) &&
        <Fade in appear timeout={3000}>
          <Box display="flex" height="370px" flexDirection="column" justifyContent="center">
            <Box display="flex" alignItems="center" flexDirection="column">
              <NoDesk src={noDesk} />
              <Paragraph size="16px" weight="bold" color="#2c2965">
                You don&apos;t have any desks booked...
              </Paragraph>
            </Box>
          </Box>
        </Fade>
      }

    </BookingScrollContainer>
  )

}
