import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from '@mui/material';
import {WallFeature} from '../../../../services/booking/types';
import {useFloorplanFeatures} from "../hooks/useFloorplanFeatures";
import {useWallFeatures} from "../hooks/useWallFeatures";
import {DesignerWallProps} from "../types";
import {useDesignerContext} from "../DesignerContext";


const DEFAULT_SNAP_THRESHOLD = 5;

function DesignerWall({
                          featureDetail,
                          svgRef,
                          snapThreshold = DEFAULT_SNAP_THRESHOLD,
                      }: Readonly<DesignerWallProps>) {
    const {
        floorPlanFeatures
    } = useDesignerContext();

    const {
        deleteFeature
    } = useFloorplanFeatures();

    const {
        updateWallPoints,
        updateWallStyle
    } = useWallFeatures()

    const allWalls = floorPlanFeatures.filter((f) => f.typeId === 7) as WallFeature[];
    const objectRef = useRef<SVGGElement | null>(null);
    const startPointRef = useRef<{ x: number; y: number }>({...featureDetail.startPoint});
    const endPointRef = useRef<{ x: number; y: number }>({...featureDetail.endPoint});

    // State for the dialog  
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [strokeColor, setStrokeColor] = useState<string>(featureDetail.fill || '#000000');
    const [thickness, setThickness] = useState<number>(featureDetail.thickness || 5);

    // Refs to D3 elements  
    const rectRef = useRef<d3.Selection<SVGRectElement, unknown, null, undefined> | null>(null);
    const startHandleRef = useRef<d3.Selection<SVGCircleElement, unknown, null, undefined> | null>(
        null
    );
    const endHandleRef = useRef<d3.Selection<SVGCircleElement, unknown, null, undefined> | null>(
        null
    );

    // Ref for Shift key state  
    const isShiftPressedRef = useRef<boolean>(false);

    // Refs for drag state  
    const dragStateRef = useRef<{
        initialMousePos: { x: number; y: number };
        initialStartPoint: { x: number; y: number };
        initialEndPoint: { x: number; y: number };
    }>({
        initialMousePos: {x: 0, y: 0},
        initialStartPoint: {x: 0, y: 0},
        initialEndPoint: {x: 0, y: 0},
    });

    // Global Shift key detection  
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                isShiftPressedRef.current = true;
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                isShiftPressedRef.current = false;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    // Function to update positions  
    const updatePositions = () => {
        if (!rectRef.current || !startHandleRef.current || !endHandleRef.current) return;

        const {x: x1, y: y1} = startPointRef.current;
        const {x: x2, y: y2} = endPointRef.current;

        const angle = Math.atan2(y2 - y1, x2 - x1);
        const length = Math.hypot(x2 - x1, y2 - y1);

        rectRef.current
            .attr('x', x1)
            .attr('y', y1 - thickness / 2)
            .attr('width', length)
            .attr('height', thickness)
            .attr('transform', `rotate(${(angle * 180) / Math.PI}, ${x1}, ${y1})`);

        startHandleRef.current
            .attr('cx', startPointRef.current.x)
            .attr('cy', startPointRef.current.y);

        endHandleRef.current.attr('cx', endPointRef.current.x).attr('cy', endPointRef.current.y);
    };

    // Initialize D3 elements  
    useEffect(() => {
        if (!objectRef.current || !svgRef.current) return;

        const group = d3.select(objectRef.current);

        // Clear any existing content  
        group.selectAll('*').remove();

        // Draw the rectangle representing the wall  
        const rect = group
            .append('rect')
            .attr('class', 'wall-rect')
            .attr('fill', strokeColor)
            .style('cursor', 'move');

        rectRef.current = rect;

        // Add draggable circles at start and end points (handles)  
        const handleRadius = 6;

        const startHandle = group
            .append('circle')
            .attr('class', 'handle start')
            .attr('r', handleRadius)
            .attr('fill', 'grey')
            .style('cursor', 'pointer');

        const endHandle = group
            .append('circle')
            .attr('class', 'handle end')
            .attr('r', handleRadius)
            .attr('fill', 'grey')
            .style('cursor', 'pointer');

        startHandleRef.current = startHandle;
        endHandleRef.current = endHandle;

        // Initial position update  
        updatePositions();

        // Function to project a point onto a line segment  
        const projectPointOntoLineSegment = (
            px: number,
            py: number,
            x1: number,
            y1: number,
            x2: number,
            y2: number
        ): { x: number; y: number; distance: number } => {
            const A = px - x1;
            const B = py - y1;
            const C = x2 - x1;
            const D = y2 - y1;

            const dot = A * C + B * D;
            const lenSq = C * C + D * D;
            let param = -1;

            if (lenSq !== 0) {
                param = dot / lenSq;
            }

            let xx;
            let yy;

            if (param < 0) {
                xx = x1;
                yy = y1;
            } else if (param > 1) {
                xx = x2;
                yy = y2;
            } else {
                xx = x1 + param * C;
                yy = y1 + param * D;
            }

            const dx = px - xx;
            const dy = py - yy;
            const distance = Math.hypot(dx, dy);

            return {x: xx, y: yy, distance};
        };

        // Function to snap points to nearby walls (including along their paths)  
        const snapToNearbyWalls = (point: { x: number; y: number }): { x: number; y: number } => {
            let closestPoint = point;
            let minDistance = snapThreshold;

            allWalls.forEach((wall) => {
                if (wall.id !== featureDetail.id) {
                    // Get wall coordinates
                    const { x: x1, y: y1 } = wall.startPoint;
                    const { x: x2, y: y2 } = wall.endPoint;

                    // Project point onto wall segment
                    const { x: xx, y: yy, distance } = projectPointOntoLineSegment(
                        point.x,
                        point.y,
                        x1,
                        y1,
                        x2,
                        y2
                    );

                    if (distance < minDistance) {
                        minDistance = distance;
                        closestPoint = { x: xx, y: yy };
                    }
                }
            });
            return closestPoint;
        };

        // Drag behavior for the start handle  
        const startHandleDrag = d3
            .drag<SVGCircleElement, unknown>()
            .on('start', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Store initial mouse position and start point  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                dragStateRef.current.initialMousePos = {x: mouseX, y: mouseY};
                dragStateRef.current.initialStartPoint = {...startPointRef.current};
            })
            .on('drag', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get current mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                // Calculate deltas  
                const dx = mouseX - dragStateRef.current.initialMousePos.x;
                const dy = mouseY - dragStateRef.current.initialMousePos.y;

                let x = dragStateRef.current.initialStartPoint.x + dx;
                let y = dragStateRef.current.initialStartPoint.y + dy;

                const shiftPressed = isShiftPressedRef.current;

                // Apply shift constraints  
                if (shiftPressed) {
                    const dx1 = x - endPointRef.current.x;
                    const dy1 = y - endPointRef.current.y;

                    if (Math.abs(dx1) > Math.abs(dy1)) {
                        y = endPointRef.current.y;
                    } else {
                        x = endPointRef.current.x;
                    }
                }

                // Snap to nearby walls (including along their paths)  
                ({x, y} = snapToNearbyWalls({x, y}));

                // Update dragged point  
                startPointRef.current = {x, y};

                // Update positions  
                updatePositions();
            })
            .on('end', () => {
                // Update parent state  
                updateWallPoints(featureDetail.id, startPointRef.current, endPointRef.current);
            });

        startHandle.call(startHandleDrag);

        // Drag behavior for the end handle  
        const endHandleDrag = d3
            .drag<SVGCircleElement, unknown>()
            .on('start', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Store initial mouse position and end point  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                dragStateRef.current.initialMousePos = {x: mouseX, y: mouseY};
                dragStateRef.current.initialEndPoint = {...endPointRef.current};
            })
            .on('drag', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get current mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                // Calculate deltas  
                const dx = mouseX - dragStateRef.current.initialMousePos.x;
                const dy = mouseY - dragStateRef.current.initialMousePos.y;

                let x = dragStateRef.current.initialEndPoint.x + dx;
                let y = dragStateRef.current.initialEndPoint.y + dy;

                const shiftPressed = isShiftPressedRef.current;

                // Apply shift constraints  
                if (shiftPressed) {
                    const dx1 = x - startPointRef.current.x;
                    const dy1 = y - startPointRef.current.y;

                    if (Math.abs(dx1) > Math.abs(dy1)) {
                        y = startPointRef.current.y;
                    } else {
                        x = startPointRef.current.x;
                    }
                }

                // Snap to nearby walls (including along their paths)  
                ({x, y} = snapToNearbyWalls({x, y}));

                // Update dragged point  
                endPointRef.current = {x, y};

                // Update positions  
                updatePositions();
            })
            .on('end', () => {
                // Update parent state  
                updateWallPoints(featureDetail.id, startPointRef.current, endPointRef.current);
            });

        endHandle.call(endHandleDrag);

        // Drag behavior for moving the entire wall  
        const wallDrag = d3
            .drag<SVGRectElement, unknown>()
            .on('start', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Store initial mouse position  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                dragStateRef.current.initialMousePos = {x: mouseX, y: mouseY};
                dragStateRef.current.initialStartPoint = {...startPointRef.current};
                dragStateRef.current.initialEndPoint = {...endPointRef.current};
            })
            .on('drag', (event) => {
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get current mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                // Calculate deltas  
                const dx = mouseX - dragStateRef.current.initialMousePos.x;
                const dy = mouseY - dragStateRef.current.initialMousePos.y;

                // Update start and end points  
                startPointRef.current = {
                    x: dragStateRef.current.initialStartPoint.x + dx,
                    y: dragStateRef.current.initialStartPoint.y + dy,
                };
                endPointRef.current = {
                    x: dragStateRef.current.initialEndPoint.x + dx,
                    y: dragStateRef.current.initialEndPoint.y + dy,
                };

                // Update positions  
                updatePositions();
            })
            .on('end', () => {
                // Snap to nearby walls (including along their paths)  
                startPointRef.current = snapToNearbyWalls(startPointRef.current);
                endPointRef.current = snapToNearbyWalls(endPointRef.current);

                // Update positions after snapping  
                updatePositions();

                // Update parent state  
                updateWallPoints(featureDetail.id, startPointRef.current, endPointRef.current);
            });

        rect.call(wallDrag);

        // Right-click event handler  
        group.on('contextmenu', (event) => {
            event.preventDefault();
            setIsDialogOpen(true);
        });

        group.on('dblclick', (event) => {
            event.stopPropagation();
            setIsDialogOpen(true);
        });
    }, [
        svgRef,
        featureDetail.id,
        thickness,
        strokeColor,
        updateWallPoints,
        allWalls,
        snapThreshold
    ]);

    // Update positions when startPoint or endPoint change  
    useEffect(() => {
        startPointRef.current = {...featureDetail.startPoint};
        endPointRef.current = {...featureDetail.endPoint};

        updatePositions();
    }, [featureDetail.startPoint, featureDetail.endPoint]);

    // Update styles when fill or thickness change  
    useEffect(() => {
        if (rectRef.current) {
            rectRef.current
                .attr('fill', strokeColor)
                .attr('height', thickness); // Ensure height is set on style updates  
        }
    }, [strokeColor, thickness]);

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = () => {
        updateWallStyle(featureDetail.id, {fill: strokeColor, thickness});
        setIsDialogOpen(false);
    };

    const handleDelete = () => {
        deleteFeature(featureDetail.id);
        setIsDialogOpen(false);
    };

    return (
        <>
            <g ref={objectRef} />
            {/* Dialog for editing wall properties and deleting */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Wall</DialogTitle>
                <DialogContent>
                    <TextField
                      label="Color"
                      type="color"
                      value={strokeColor}
                      onChange={(e) => setStrokeColor(e.target.value)}
                      fullWidth
                      InputLabelProps={{shrink: true}}
                      margin="normal"
                    />
                    <TextField
                      label="Thickness"
                      type="number"
                      value={thickness}
                      onChange={(e) => setThickness(Number(e.target.value))}
                      fullWidth
                      InputProps={{inputProps: {min: 1}}}
                      margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignerWall;