import {useCallback} from "react";
import {WallFeature} from "../../../../services/booking/types";
import {useDesignerContext} from "../DesignerContext";

export const useWallFeatures = () => {
    const {
        setFloorPlanFeatures,
    } = useDesignerContext();

    const updateWallPoints = useCallback(
        (id: number, newStartPoint: { x: number; y: number }, newEndPoint: { x: number; y: number }) => {
            setFloorPlanFeatures((currentFloorplanFeatures) => currentFloorplanFeatures.map((feature) => {
                    if (feature.id !== id) return feature;
                    if (feature.typeId === 7) {
                        const wallFeature = feature as WallFeature;
                        return {
                            ...wallFeature,
                            startPoint: newStartPoint,
                            endPoint: newEndPoint,
                        };
                    }
                    return feature;
                }));
        },
        [setFloorPlanFeatures]
    );

    const updateWallStyle = useCallback(
        (id: number, style: { fill?: string; thickness?: number }) => {
            setFloorPlanFeatures((currentFloorplanFeatures) =>
                currentFloorplanFeatures.map((feature) => {
                    if (feature.id !== id) return feature;

                    if (feature.typeId === 7) {
                        const wallFeature = feature as WallFeature;
                        return {
                            ...wallFeature,
                            fill: style.fill !== undefined ? style.fill : wallFeature.fill,
                            thickness: style.thickness !== undefined ? style.thickness : wallFeature.thickness,
                        };
                    }
                    return feature;
                })
            );
        },
        [setFloorPlanFeatures]
    );
    
    return {
        updateWallPoints,
        updateWallStyle,
    }
}