import {SelectChangeEvent} from "@mui/material";
import {useDispatch} from "react-redux";
import {bookingService} from "../../../../services/booking/bookingService";
import {BaseResponse} from "../../../../types/base-response";
import {handleServerError} from "../../bookingLogic";
import {showErrorMessage} from "../../../../redux/reducers/snackbarReducer";
import {setDeskBookingLoading} from "../../../../redux/reducers/deskBookingReducer";
import {processFeatures} from "../Utils/toolbarUtils";
import {useDesignerContext} from "../DesignerContext";

export const useToolbar = () => {
    const dispatch = useDispatch();
    const {
        setFloorPlanFeatures,
        setZones,
        setFloorPlanId,
        setLocationId,
        setSelectedFloorPlanName
    } = useDesignerContext();

    const handleFloorPlanChange = async (
        event: SelectChangeEvent,
        floorPlans: any[]
    ) => {
        const selectedPlanId = event.target.value;

        setFloorPlanId(0);
        setSelectedFloorPlanName('');
        setFloorPlanFeatures([]);
        setZones([]);
        setLocationId(0);

        if (selectedPlanId === '') {
            return;
        }
        
        const selectedPlan = floorPlans.find((plan) => plan.id === Number(selectedPlanId));
        setFloorPlanId(Number(selectedPlanId));
        setSelectedFloorPlanName(selectedPlan ? selectedPlan.name : '');
        setLocationId(Number(selectedPlan.locationId))

        const controller = new AbortController();
        const floorplanId = Number(selectedPlanId);
        try {
            const resultFeatures = await bookingService.getFeaturesByPlan(controller, floorplanId);
            const processedFeatures = processFeatures(resultFeatures);
            setFloorPlanFeatures(processedFeatures.processedFeatures);
            setZones(processedFeatures.zones)
        } catch (err) {
            const response: BaseResponse = handleServerError((err as any).response?.data);
            response?.errors?.forEach((_) => {
                dispatch(showErrorMessage(`Search Floorplan ${floorplanId} features failure`));
            });

            dispatch(setDeskBookingLoading(false));
        }
    };

    return {
        handleFloorPlanChange
    }
}