import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FloorPlanRequest } from "../../../../types/floorPlanRequest";
import { bookingService } from "../../../../services/booking/bookingService";
import { BaseResponse } from "../../../../types/base-response";
import { showErrorMessage, showSuccessMessage } from "../../../../redux/reducers/snackbarReducer";
import { EditorRequest } from "../../../../types/editorRequest";
import { DeskFeature, LabelFeature, OfficeFeature, WallFeature, ZoneFeature } from "../../../../services/booking/types";
import { useDesignerContext } from "../DesignerContext";
import { processFeatures } from "../Utils/toolbarUtils";

export const useFloorPlan = () => {
    const {
        floorPlanFeatures,
        setFloorPlanFeatures,
        floorPlanId,
        setFloorPlanId,
        locationId,
        setLocationId,
        selectedFloorPlanName,
        setFloorPlans,
        setSelectedFloorPlanName,
        setZones
    } = useDesignerContext();

    const dispatch = useDispatch();

    const handleSaveOrUpdateFloorPlan = async (request: FloorPlanRequest, isUpdate: boolean) => {
        if (isUpdate) {
            return bookingService.updateFloorPlan(request);
        }

        return bookingService.createFloorPlan(request);
    };

    const fetchAndSetFloorPlans = async (selectedFloorPlanId = 0) => {
        try {
            const floorPlans = await bookingService.getFloorPlans();
            setFloorPlans(floorPlans);
            if (selectedFloorPlanId) {
                setFloorPlanId(selectedFloorPlanId);
            } else {
                setFloorPlanId(0);
                setSelectedFloorPlanName('');
            }
        } catch (error) {
            dispatch(showErrorMessage('Unable to fetch floor plans'));
        }
    };

    const fetchAndSetFloorPlanFeatures = async (planId: number) => {
        try {
            const controller = new AbortController();
            const resultFeatures = await bookingService.getFeaturesByPlan(controller, planId);
            const processedFeatures = processFeatures(resultFeatures);
            setFloorPlanFeatures(processedFeatures.processedFeatures);
            setZones(processedFeatures.zones);
        } catch (error) {
            dispatch(showErrorMessage(`Unable to fetch floor plan features`));
        }
    };

    const saveFloorPlan = useCallback(async () => {
        const categoryId = 1; // Set to desks 
        const location = Number(locationId);
        let resultFloorPlanId = 0;

        const isUpdate = floorPlanId !== 0;
        let existingFloorPlan = null;

        if (isUpdate) {
            existingFloorPlan = await bookingService.getFloorPlan(floorPlanId).catch(() => null);
        }

        const request: FloorPlanRequest = {
            id: isUpdate ? Number(floorPlanId) : undefined,
            name: selectedFloorPlanName,
            categoryId,
            locationId: location,
            image: 'image'
        };

        await handleSaveOrUpdateFloorPlan(request, isUpdate && !!existingFloorPlan)
            .then((result) => {
                setFloorPlanId(result);
                resultFloorPlanId = result;
            })
            .catch((err) => {
                const response: BaseResponse = err.response?.data;
                response?.errors?.forEach(() => {
                    dispatch(showErrorMessage(`Unable to ${isUpdate ? 'update' : 'save'} floor plan`));
                });
            });

        const mapIdsToFeatures = (features: any[], locId: number, fpId: number) => features.map(feature => ({
            ...feature,
            locationId: feature.locationId || locId,
            floorplanId: feature.floorplanId || fpId
        }));

        if (floorPlanId !== 0) {
            resultFloorPlanId = floorPlanId;
        }

        const deskFeatures = mapIdsToFeatures(floorPlanFeatures.filter(feature => feature.typeId === 1), location, resultFloorPlanId);
        const zoneFeatures = mapIdsToFeatures(floorPlanFeatures.filter(feature => feature.typeId === 8), location, resultFloorPlanId);
        const wallFeatures = mapIdsToFeatures(floorPlanFeatures.filter(feature => feature.typeId === 7), location, resultFloorPlanId);
        const officeFeatures = mapIdsToFeatures(floorPlanFeatures.filter(feature => feature.typeId === 10), location, resultFloorPlanId);
        const labelFeatures = mapIdsToFeatures(floorPlanFeatures.filter(feature => feature.typeId === 9), location, resultFloorPlanId);

        const editorRequest: EditorRequest = {
            floorPlanId: resultFloorPlanId,
            locationId: location,
            categoryId,
            deskFeatures: deskFeatures as DeskFeature[],
            zoneFeatures: zoneFeatures as ZoneFeature[],
            wallFeatures: wallFeatures as WallFeature[],
            officeFeatures: officeFeatures as OfficeFeature[],
            labelFeatures: labelFeatures as LabelFeature[],
        };

        await bookingService.editorCreate(editorRequest)
            .then((result) => {
                if (result.id) {
                    dispatch(showSuccessMessage('Floor plan has been successfully saved.'));
                    fetchAndSetFloorPlans(resultFloorPlanId);
                    fetchAndSetFloorPlanFeatures(resultFloorPlanId);
                }
            })
            .catch((err) => {
                const response: BaseResponse = err.response?.data;
                response?.errors?.forEach(() => {
                    dispatch(showErrorMessage(`Unable to save floor plan features`));
                });
            });
    }, [floorPlanFeatures, dispatch, floorPlanId, locationId, selectedFloorPlanName]);

    const deleteFloorPlan = useCallback(async () => {
        await bookingService.editorDelete(floorPlanId)
            .then(() => {
                dispatch(showSuccessMessage('Floor plan has been successfully deleted.'));
                setFloorPlanFeatures([]);
                setZones([]);
                setFloorPlanId(0);
                setLocationId(0);

                fetchAndSetFloorPlans();
            })
            .catch((err) => {
                const response: BaseResponse = err.response?.data;
                response?.errors?.forEach(() => {
                    dispatch(showErrorMessage(`Unable to delete floor plan`));
                });
            });
    }, [floorPlanId, dispatch, setFloorPlanFeatures, setFloorPlanId, setLocationId]);

    return {
        saveFloorPlan,
        deleteFloorplan: deleteFloorPlan
    }
}