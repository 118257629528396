import {bookingService} from "../../../../services/booking/bookingService";
import {BookingComponent} from "../../../../services/booking/types";

export async function fetchFeatureComponents(availableFeatures: BookingComponent[]) {
    
    if (availableFeatures.length === 0) {
        const components = await bookingService.getAllComponents();
        return components.components;
    }
    
    return []
}