import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from '@mui/material';
import { useFloorplanFeatures } from "../hooks/useFloorplanFeatures";
import { DesignerOfficeProps } from "../types";

function DesignerOffice({
                            featureDetail,
                            svgRef,
                            snapToGrid,
                        }: Readonly<DesignerOfficeProps>) {
    const {
        updateLabel,
        resizeFeature,
        updateFeaturePosition,
        deleteFeature
    } = useFloorplanFeatures();
    const groupRef = useRef<SVGGElement | null>(null);

    const [isEditingLabel, setIsEditingLabel] = useState<boolean>(false);
    const [labelText, setLabelText] = useState<string>(featureDetail.label);

    // State for the dialog  
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(featureDetail.width);
    const [height, setHeight] = useState<number>(featureDetail.height);
    const [fillColor, setFillColor] = useState<string>(featureDetail.fill);

    // Refs to store current position and size  
    const currentX = useRef(featureDetail.x);
    const currentY = useRef(featureDetail.y);
    const currentWidth = useRef(featureDetail.width);
    const currentHeight = useRef(featureDetail.height);

    // Refs for moving and resizing states  
    const moveOffset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
    const resizeOffset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    // Initial state ref for resizing  
    const initialResizeState = useRef<{
        initialX: number;
        initialY: number;
        initialWidth: number;
        initialHeight: number;
    }>({
        initialX: 0,
        initialY: 0,
        initialWidth: 0,
        initialHeight: 0,
    });

    useEffect(() => {
        setLabelText(featureDetail.label);
    }, [featureDetail.label]);

    useEffect(() => {
        setWidth(featureDetail.width);
        setHeight(featureDetail.height);
        setFillColor(featureDetail.fill);
    }, [featureDetail.width, featureDetail.height, featureDetail.fill]);

    useEffect(() => {
        // Update refs when featureDetail changes  
        currentX.current = featureDetail.x;
        currentY.current = featureDetail.y;
        currentWidth.current = featureDetail.width;
        currentHeight.current = featureDetail.height;

        if (!groupRef.current || !svgRef.current) return;

        const group = d3.select(groupRef.current);

        // Clear previous content  
        group.selectAll('*').remove();

        // Draw rectangle  
        const rect = group
            .append<SVGRectElement>('rect')
            .attr('class', 'office-rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', currentWidth.current)
            .attr('height', currentHeight.current)
            .attr('fill', fillColor)
            .attr('rx', featureDetail.borderRadius)
            .style('cursor', 'move');

        // Initialize position  
        group.attr('transform', `translate(${currentX.current}, ${currentY.current})`);

        // Draw label  
        const labelX = currentWidth.current / 2;
        const labelY = currentHeight.current / 2;
        const textAnchor = 'middle';

        if (isEditingLabel) {
            // Render input field inside foreignObject for editing  
            const foreignObject = group
                .append('foreignObject')
                .attr('x', labelX - 50)
                .attr('y', labelY - 10)
                .attr('width', 100)
                .attr('height', 20)
                .on('dblclick', (e) => { e.stopPropagation(); });

            const inputSelection = foreignObject
                .append('xhtml:input')
                .attr('type', 'text')
                .attr('value', labelText)
                .style('width', '100%')
                .style('font-size', '12px')
                .on('input', function handleInput() {
                    setLabelText((this as HTMLInputElement).value);
                })
                .on('blur', function handleBlur() {
                    if (updateLabel) {
                        updateLabel(featureDetail.id, (this as HTMLInputElement).value);
                    }
                    setIsEditingLabel(false);
                    updateElements();
                })
                .on('keydown', function handleKeyDown(event) {
                    if (event.key === 'Enter') {
                        (this as HTMLInputElement).blur();
                    }
                });

            // Focus the input field  
            const inputNode = inputSelection.node() as HTMLInputElement | null;
            if (inputNode) {
                inputNode.focus();
            }
        } else {
            // Render label text  
            group
                .append('text')
                .attr('x', labelX)
                .attr('y', labelY)
                .attr('text-anchor', textAnchor)
                .attr('dominant-baseline', 'middle')
                .attr('fill', 'white')
                .attr('font-size', '22px')
                .attr('font-family', 'Poppins')
                .style('cursor', 'text')
                .text(labelText)
                .on('dblclick', (event) => {
                    event.stopPropagation(); // Prevent triggering other dblclick events  
                    setIsEditingLabel(true);
                });
        }

        // Draw resize handles at the corners  
        const handleSize = 6;
        const handlesData = [
            { name: 'top-left', cursor: 'nwse-resize' },
            { name: 'top-right', cursor: 'nesw-resize' },
            { name: 'bottom-right', cursor: 'nwse-resize' },
            { name: 'bottom-left', cursor: 'nesw-resize' },
        ];

        const handles = group
            .selectAll<SVGCircleElement, typeof handlesData[0]>('circle.handle')
            .data(handlesData)
            .enter()
            .append('circle')
            .attr('class', 'handle')
            .attr('r', handleSize)
            .attr('fill', 'grey')
            .style('cursor', (d) => d.cursor);

        // Function to update rectangle, handles, and label  
        const updateElements = () => {
            // Update rectangle  
            rect
                .attr('width', currentWidth.current)
                .attr('height', currentHeight.current)
                .attr('fill', fillColor);

            // Update label position  
            group
                .select('text')
                .attr('x', currentWidth.current / 2)
                .attr('y', currentHeight.current / 2);

            group
                .select('foreignObject')
                .attr('x', currentWidth.current / 2 - 50)
                .attr('y', currentHeight.current / 2 - 10);

            // Update handles positions  
            handles
                .attr('cx', (d) => {
                    if (d.name.includes('right')) {
                        return currentWidth.current;
                    }
                    return 0;

                })
                .attr('cy', (d) => {
                    if (d.name.includes('bottom')) {
                        return currentHeight.current;
                    }
                    return 0;

                });

            // Update group position  
            group.attr('transform', `translate(${currentX.current}, ${currentY.current})`);
        };

        // Initial update  
        updateElements();

        // Drag behavior for resizing  
        const dragResize = d3
            .drag<SVGCircleElement, typeof handlesData[0]>()
            .on('start', (event) => {
                event.sourceEvent.stopPropagation(); // Prevent dragMove from firing  

                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Store initial position and size  
                initialResizeState.current.initialX = currentX.current;
                initialResizeState.current.initialY = currentY.current;
                initialResizeState.current.initialWidth = currentWidth.current;
                initialResizeState.current.initialHeight = currentHeight.current;

                // Get initial mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                resizeOffset.current.x = mouseX;
                resizeOffset.current.y = mouseY;
            })
            .on('drag', (event, d) => {
                if (isEditingLabel) return; // Prevent resizing while editing label  
                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get current mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                // Calculate deltas  
                const dx = mouseX - resizeOffset.current.x;
                const dy = mouseY - resizeOffset.current.y;

                let newX = initialResizeState.current.initialX;
                let newY = initialResizeState.current.initialY;
                let newWidth = initialResizeState.current.initialWidth;
                let newHeight = initialResizeState.current.initialHeight;

                if (d.name === 'top-left') {
                    newX += dx;
                    newY += dy;
                    newWidth -= dx;
                    newHeight -= dy;
                } else if (d.name === 'top-right') {
                    newY += dy;
                    newWidth += dx;
                    newHeight -= dy;
                } else if (d.name === 'bottom-right') {
                    newWidth += dx;
                    newHeight += dy;
                } else if (d.name === 'bottom-left') {
                    newX += dx;
                    newWidth -= dx;
                    newHeight += dy;
                }

                // Enforce minimum size  
                const minSize = 20;
                if (newWidth < minSize) {
                    if (d.name.includes('left')) {
                        newX += (newWidth - minSize);
                    }
                    newWidth = minSize;
                }
                if (newHeight < minSize) {
                    if (d.name.includes('top')) {
                        newY += (newHeight - minSize);
                    }
                    newHeight = minSize;
                }

                // Update refs  
                currentX.current = newX;
                currentY.current = newY;
                currentWidth.current = newWidth;
                currentHeight.current = newHeight;

                // Update elements without snapping  
                updateElements();
            })
            .on('end', () => {
                let finalX = currentX.current;
                let finalY = currentY.current;
                let finalWidth = currentWidth.current;
                let finalHeight = currentHeight.current;

                // Snap to grid if necessary  
                if (snapToGrid) {
                    const gridSize = 5;
                    finalX = Math.round(finalX / gridSize) * gridSize;
                    finalY = Math.round(finalY / gridSize) * gridSize;
                    finalWidth = Math.round(finalWidth / gridSize) * gridSize;
                    finalHeight = Math.round(finalHeight / gridSize) * gridSize;

                    // Update refs  
                    currentX.current = finalX;
                    currentY.current = finalY;
                    currentWidth.current = finalWidth;
                    currentHeight.current = finalHeight;

                    // Update elements to snapped positions  
                    updateElements();
                }

                // Call the resize callback  
                if (resizeFeature) {
                    resizeFeature(featureDetail.id, finalWidth, finalHeight);
                }
                // Update position as well  
                if (updateFeaturePosition) {
                    updateFeaturePosition(featureDetail.id, finalX, finalY);
                }
            });

        handles.call(dragResize);

        // Drag behavior for moving the entire office  
        const dragMove = d3
            .drag<SVGGElement, unknown>()
            .on('start', (event) => {
                if (isEditingLabel) return; // Prevent moving while editing label  

                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get initial mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                // Calculate offset between mouse and feature position  
                moveOffset.current.x = mouseX - currentX.current;
                moveOffset.current.y = mouseY - currentY.current;
            })
            .on('drag', (event) => {
                if (isEditingLabel) return; // Prevent moving while editing label  

                if (!svgRef.current) return;
                const svgElement = svgRef.current;

                // Get current mouse position in SVG coordinates  
                const [mouseX, mouseY] = d3.zoomTransform(svgElement).invert([
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                ]);

                const newX = mouseX - moveOffset.current.x;
                const newY = mouseY - moveOffset.current.y;

                // Update refs  
                currentX.current = newX;
                currentY.current = newY;

                // Update position without snapping  
                updateElements();
            })
            .on('end', () => {
                let finalX = currentX.current;
                let finalY = currentY.current;

                // Snap to grid if necessary  
                if (snapToGrid) {
                    const gridSize = 10;
                    finalX = Math.round(finalX / gridSize) * gridSize;
                    finalY = Math.round(finalY / gridSize) * gridSize;

                    // Update refs  
                    currentX.current = finalX;
                    currentY.current = finalY;

                    // Update position with snapping  
                    updateElements();
                }

                // Update featureDetail position  
                if (updateFeaturePosition) {
                    updateFeaturePosition(featureDetail.id, finalX, finalY);
                }
            });

        group.call(dragMove);

        // Prevent unintended behaviors  
        group.on('mousedown', (event) => {
            event.stopPropagation();
        });

        group.on('contextmenu', (event) => {
            event.preventDefault();
            setIsDialogOpen(true);
        });

        group.on('dblclick', (event) => {
            const targetElement = event.target as HTMLElement;
            if (
                targetElement.tagName === 'text' ||
                targetElement.tagName === 'INPUT' ||
                targetElement.closest('foreignObject')
            ) {
                // Do not trigger when double-clicking the label or input  
                return;
            }
            event.stopPropagation(); // Prevent unwanted behaviors  
        });
    }, [
        featureDetail,
        snapToGrid,
        updateFeaturePosition,
        resizeFeature,
        deleteFeature,
        labelText,
        isEditingLabel,
        updateLabel,
        fillColor,
        svgRef,
    ]);

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = () => {
        if (updateLabel) {
            updateLabel(featureDetail.id, labelText);
        }
        if (resizeFeature) {
            resizeFeature(featureDetail.id, width, height);
        }
        if (updateFeaturePosition) {
            updateFeaturePosition(featureDetail.id, currentX.current, currentY.current);
        }
        // Update fill color  
        const updatedFeatureDetail = { ...featureDetail, fill: fillColor };
        setIsDialogOpen(false);
    };

    const handleDelete = () => {
        deleteFeature(featureDetail.id);
        setIsDialogOpen(false);
    };

    return (
        <>
            <g ref={groupRef} />
            {/* Dialog for editing properties and deleting */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Office</DialogTitle>
                <DialogContent>
                    <TextField
                      label="Label"
                      value={labelText}
                      onChange={(e) => setLabelText(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Width"
                      type="number"
                      value={width}
                      onChange={(e) => setWidth(Number(e.target.value))}
                      fullWidth
                      InputProps={{ inputProps: { min: 20 } }}
                      margin="normal"
                    />
                    <TextField
                      label="Height"
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(Number(e.target.value))}
                      fullWidth
                      InputProps={{ inputProps: { min: 20 } }}
                      margin="normal"
                    />
                    <TextField
                      label="Color"
                      type="color"
                      value={fillColor}
                      onChange={(e) => setFillColor(e.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button
                      onClick={handleDialogSave}
                      variant="contained"
                      color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignerOffice;