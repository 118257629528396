// DesignerPage.tsx
import React from 'react';
import Designer from './Designer';
import { DesignerProvider } from './DesignerContext';

function DesignerPage() {
    return (
        <DesignerProvider>
            <Designer />
        </DesignerProvider>
    );
}

export default DesignerPage;