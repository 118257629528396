import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeskProps } from './types'
import { DeskStatusColor, DeskStatusID } from './enums'
import { Group } from './components'
import { RootStore } from '../../../redux/store'
import { getUserInitials, cleanUsername } from '../utils/utils'
import localCss from '../booking.module.css'
import {
    deskColour,
    getAvailability,
    calculateAccessPosition, // Import the function here  
} from './deskRectangleLogic'
import { BookingWizardSteps } from '../BlockBooking/enums'
import { BookingAvailabilityColors } from '../consts'
import { DeskFeature } from "../../../services/booking/types";
import {calculateRotationAngle} from "../Designer/Utils/toolbarUtils";

function DeskRectangle({
                           featureDetail,
                           existingBookings,
                           requestedSlot,
                           isActive,
                           availabilityColor,
                           isBlockBooking,
                           onActionMouseEnter,
                       }: DeskProps) {
    const objectRef = useRef<SVGGElement | null>(null)
    const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
    const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
    const {
        selectedFeature,
        selectedAlternativeFeatureID,
        currentStep: currentWizardStep,
    } = useSelector((state: RootStore) => state.deskBookingWizard)
    const deskFeature = featureDetail as DeskFeature;

    if(deskFeature.rotationAngle === undefined || deskFeature.rotationAngle === 0){
        deskFeature.rotationAngle = calculateRotationAngle(deskFeature);
    }

    const { submissionsInProgress } = useSelector((state: RootStore) => state.deskBookingStatus)

    // NOTE: No provision has yet been made for more than 1 port    
    const PORT_BORDER_RADIUS = featureDetail.ports?.length
        ? Number(featureDetail.ports?.[0].radius) * 1.5
        : 0

    const thisBooking = Number(existingBookings?.length) > 0 && existingBookings?.[0] || false
    const thisEmployee = thisBooking && thisBooking.employeeId === employeeDetails.employeeId // Where the current user made the booking    
    const thisBookingUsername = thisBooking && cleanUsername(thisBooking.displayName)
    const thisLoggedInUsername = cleanUsername(userInfo?.name)

    const isOwnBooking = () => thisBooking ? thisEmployee &&
        thisBookingUsername === thisLoggedInUsername // This is where the booking is made one's self    
        : false
    const isOwnBookingFor = () => thisBooking ? thisEmployee &&
        thisBookingUsername !== thisLoggedInUsername // Where the booking is made for a third party    
        : false

    const rotationCenterX = featureDetail.width / 2;
    const rotationCenterY = featureDetail.height / 2;

    const getPortPosition = (): { x: number; y: number } => {
        if (deskFeature.rotationAngle === 0) {
            // Call calculateAccessPosition with both required arguments  
            const accessPosition = calculateAccessPosition(featureDetail, PORT_BORDER_RADIUS);

            // Adjust x and y to be relative to the Group's coordinate system  
            const x = accessPosition.x - featureDetail.x;
            const y = accessPosition.y - featureDetail.y;

            return { x, y };
        } 
            // Existing position calculation when rotationAngle is defined  
            const {width} = featureDetail;
            const {height} = featureDetail;
            const centerY = height / 2;

            const portRadius = featureDetail.ports?.[0]?.radius || 0;
            const portOverlapOffset = portRadius / 2;

            const x = width + portOverlapOffset;
            const y = centerY;

            return { x, y };
        
    };

    const position = getPortPosition();

    const labelGlobalX = featureDetail.x + featureDetail.width / 2;
    const labelGlobalY = featureDetail.y + featureDetail.height / 2;
    const textAnchor = 'middle';

    const bookingDetails = getAvailability(
        requestedSlot.bookingRange,
        employeeDetails.employeeId,
        existingBookings
    )

    const isBlockBookingAndNotSelectedFeature = () =>
        isBlockBooking && !selectedFeature
    const isBlockBookingAndSelectedFeatureMatch = () =>
        isBlockBooking && selectedFeature?.id === featureDetail.id
    const isOwnBookingDuringBlockBookingWizard = () =>
        isOwnBooking() && currentWizardStep === BookingWizardSteps.STEP_2_SELECT_DESK

    const getDeskFillColor = () => {
        if (
            isBlockBookingAndNotSelectedFeature() ||
            isBlockBookingAndSelectedFeatureMatch() ||
            isOwnBookingDuringBlockBookingWizard()
        ) {
            return DeskStatusColor.ACTIVE
        }

        return deskColour(featureDetail, isOwnBooking(), isOwnBookingFor())
    }

    const shouldHighlightDesk = () => {
        if (
            isBlockBooking &&
            currentWizardStep === BookingWizardSteps.STEP_2_SELECT_DESK &&
            selectedFeature?.id === featureDetail.id
        ) {
            return true
        }
        if (isBlockBooking && currentWizardStep === BookingWizardSteps.STEP_4_ALTERNATIVES) {
            if (selectedAlternativeFeatureID === featureDetail.id) {
                return true
            }
        }
        return false
    }

    const submissionInProgress = () =>
        submissionsInProgress.some(s =>
            s === featureDetail.id
        )

    const featureDetailLocationId = () =>
        featureDetail?.locationId === 1

    const globalTextX = featureDetail.x + position.x;
    const globalTextY = featureDetail.y + position.y;

    // Adjust these offsets based on the rotation angle
    let textOffsetX = -12;
    let textOffsetY = PORT_BORDER_RADIUS * 1.25;
    let svgOffsetX = -12;
    let svgOffsetY = PORT_BORDER_RADIUS * 1.25;

    if (deskFeature.rotationAngle === 45) {
        textOffsetX = -12.5;
        textOffsetY = PORT_BORDER_RADIUS * 1.25;
        svgOffsetX = -12.5;
        svgOffsetY = PORT_BORDER_RADIUS * 1.25;
    } else if (deskFeature.rotationAngle === 225){
        textOffsetX = -72;
        textOffsetY = PORT_BORDER_RADIUS * -1.2;
        svgOffsetX = -72;
        svgOffsetY = PORT_BORDER_RADIUS * -1.2;
    }     
    else if (deskFeature.rotationAngle === 90) {
        textOffsetX = -42;
        textOffsetY = PORT_BORDER_RADIUS * 1.8;
        svgOffsetX = -42;
        svgOffsetY = PORT_BORDER_RADIUS * 1.8;
    } else if(deskFeature.rotationAngle === 270) {
        textOffsetX = -42;
        textOffsetY = PORT_BORDER_RADIUS * -1.7;
        svgOffsetX = -42;
        svgOffsetY = PORT_BORDER_RADIUS * -1.7;
    }    
    else if (deskFeature.rotationAngle === 135) {
        textOffsetX = -71.5;
        textOffsetY = PORT_BORDER_RADIUS * 1.25;
        svgOffsetX = -71.5;
        svgOffsetY = PORT_BORDER_RADIUS * 1.25;
    } else if(deskFeature.rotationAngle === 315) {
        textOffsetX = -12.5;
        textOffsetY = PORT_BORDER_RADIUS * -1.20;
        svgOffsetX = -12.5;
        svgOffsetY = PORT_BORDER_RADIUS * -1.20;
    }     
    else if (deskFeature.rotationAngle === 180) {
        textOffsetX = -84;
        textOffsetY = PORT_BORDER_RADIUS * 0.04;
        svgOffsetX = -84;
        svgOffsetY = PORT_BORDER_RADIUS * 0.04;
    } else if(deskFeature.rotationAngle === 360){
        textOffsetX = -84;
        textOffsetY = PORT_BORDER_RADIUS * 0.04;
        svgOffsetX = -84;
        svgOffsetY = PORT_BORDER_RADIUS * 0.04;
    } else if(deskFeature.rotationAngle === 0){
        textOffsetX = 0;
        textOffsetY = PORT_BORDER_RADIUS * 0.04;
        svgOffsetX = 0;
        svgOffsetY = PORT_BORDER_RADIUS * 0.04;
    }
    
    return (
        <>
            <Group
              id={`desk_${featureDetail.id}`}
              ref={objectRef}
              style={{ overflow: 'visible' }}
              opacity={submissionInProgress() ? 0.5 : 1}
              onClick={e => {
                    onActionMouseEnter?.(
                        e.currentTarget,
                        bookingDetails,
                        isOwnBooking() || false,
                        availabilityColor,
                        existingBookings
                    )
                }}
              highlightColor={isOwnBooking() ? DeskStatusColor.OWN_BOOKING : availabilityColor}
              transform={`translate(${featureDetail.x}, ${featureDetail.y}) rotate(${deskFeature.rotationAngle}, ${rotationCenterX}, ${rotationCenterY})`}
            >
                <rect
                  y={0}
                  x={0}
                  width={featureDetail.width}
                  height={featureDetail.height}
                  rx={featureDetail.borderRadius}
                  fill={getDeskFillColor()}
                  className={isActive ? '' : localCss.bookingZoneUnfocussedDesk}
                />
                {shouldHighlightDesk() && (
                    <rect
                      y={0}
                      x={0}
                      width={featureDetail.width}
                      height={featureDetail.height}
                      rx={featureDetail.borderRadius}
                      fill={getDeskFillColor()}
                      strokeWidth={4}
                      stroke="#7D7D7D"
                    />
                )}
                {featureDetail.statusId === DeskStatusID.VACANT && Boolean(featureDetail.ports?.length) && (
                    <g>
                        <circle
                          cx={position.x}
                          cy={position.y}
                          r={PORT_BORDER_RADIUS}
                          fill="white"
                        />
                        <circle
                          cx={position.x}
                          cy={position.y}
                          r={PORT_BORDER_RADIUS - 6}
                          fill="white"
                        />
                        <circle
                          cx={position.x}
                          cy={position.y}
                          r={featureDetail.ports?.[0].radius}
                          fill={isOwnBooking() ? DeskStatusColor.OWN_BOOKING : availabilityColor}
                          cursor={availabilityColor === BookingAvailabilityColors.NOT_ACTIVE ? '' : 'pointer'}
                          className={isActive ? '' : localCss.bookingZoneUnfocussedDesk}
                        />
                    </g>
                )}
            </Group>
            {/* Render the label outside the transformed group to keep it upright */}
            {featureDetail.label && (
                <text
                  x={labelGlobalX}
                  y={labelGlobalY}
                  fontFamily="Poppins"
                  fontSize="19px"
                  fontWeight="bold"
                  fill="white"
                  style={{ userSelect: 'none' }}
                  textAnchor={textAnchor}
                  alignmentBaseline="middle"
                >
                    {featureDetail.label}
                </text>
            )}
            {/* Render the text and svg outside the group */}
            {featureDetail.statusId === DeskStatusID.VACANT && Boolean(featureDetail.ports?.length) && (
                <>
                    {!isOwnBooking() && Number(existingBookings?.length) === 1 && (
                        <text
                          x={globalTextX + textOffsetX}
                          y={globalTextY + textOffsetY}
                          textAnchor="middle"
                          fill="white"
                          alignmentBaseline="middle"
                          fontFamily="Poppins"
                          fontSize="13px"
                          cursor={availabilityColor === BookingAvailabilityColors.NOT_ACTIVE ? '' : 'pointer'}
                        >
                            {isBlockBooking ? '' : getUserInitials(existingBookings?.[0].displayName || '')}
                        </text>
                    )}

                    {!isBlockBooking && isOwnBooking() && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={featureDetailLocationId() ? '16' : '14'}
                          height={featureDetailLocationId() ? '24' : '20'}
                          viewBox="0 0 16.991 24.567"
                          x={globalTextX + svgOffsetX - (featureDetailLocationId() ? 8 : 7)}
                          y={globalTextY + svgOffsetY - (featureDetailLocationId() ? 12 : 10)}
                        >
                            <path
                              data-name="Path 2426"
                              d="M8.5,0A8.541,8.541,0,0,0,0,8.6c0,6.449,8.5,15.968,8.5,15.968s8.5-9.52,8.5-15.968A8.541,8.541,0,0,0,8.5,0m0,11.669A3.071,3.071,0,1,1,11.53,8.6,3.054,3.054,0,0,1,8.5,11.669"
                              fill="#fff"
                            />
                        </svg>
                    )}
                </>
            )}
        </>
    )
}

export default DeskRectangle