import React, { useState, useEffect } from 'react';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Switch,
    Box,
    Typography,
    Tooltip,
    ButtonGroup,
    Menu,
    MenuItem as DropdownMenuItem,
    SelectChangeEvent,
} from '@mui/material';
import DeskIcon from '@mui/icons-material/Desk';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StraightenIcon from '@mui/icons-material/Straighten';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CropDinIcon from '@mui/icons-material/CropDin';
import SaveIcon from '@mui/icons-material/Save';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useDispatch } from 'react-redux';
import DrawDesks from '../Utils/DrawDesk';
import { BookingToolbarOptions, GroupBoxProps } from '../types';
import { bookingService } from '../../../../services/booking/bookingService';
import {
    setDeskBookingFloorplansState,
} from '../../../../redux/reducers/deskBookingReducer';
import { BaseResponse } from '../../../../types/base-response';
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer';
import BookingErrorMessage from "../../utils/BookingErrorMessage";
import { useDesigner } from "../hooks/useDesigner";
import { useFloorPlan } from "../hooks/useFloorPlan";
import { useZones } from "../hooks/useZones";
import { useToolbar } from "../hooks/useToolbar";
import { useDesignerContext } from "../DesignerContext";
import {BookingFeature} from "../../../../services/booking/types";

function GroupBox({ label, children }: GroupBoxProps) {
    return (
        <Box
          border={1}
          borderColor="grey.400"
          borderRadius={2}
          p={2}
          m={1}
          mt={2}
          ml={2}
          position="relative"
        >
            <Typography
              variant="caption"
              component="div"
              position="absolute"
              top={-10}
              left={10}
              bgcolor="background.paper"
              px={1}
            >
                {label}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                {children}
            </Grid>
        </Box>
    );
}

function Toolbar({ onSelected, loadViewer, setLoadViewer }: Readonly<BookingToolbarOptions>) {
    const [snapOptions, setSnapOptions] = useState<{ snapDrag: boolean }>({
        snapDrag: true,
    });
    const [showDrawDesks, setShowDrawDesks] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const {
        handleFloorPlanChange
    } = useToolbar();

    const {
        floorPlanId,
        setFloorPlanId,
        locationId,
        setLocationId,
        selectedFloorPlanName,
        setSelectedFloorPlanName,
        floorPlans,
        setFloorPlans,
        locations,
        setLocations,
        floorPlanFeatures,
        setFloorPlanFeatures,
        setZones,
    } = useDesignerContext();

    const {
        handleOpenBulkEditDialog,
        handleAddOffice,
        handleAddWall,
        handleAddTextLabel,
        handleDeleteSelectedFeatures,
        handleCopyPasteSelectedFeatures,
        handleSnapToGridWhileDraggingChange
    } = useDesigner();

    const {
        deleteFloorplan,
        saveFloorPlan
    } = useFloorPlan()

    const {
        addZone
    } = useZones()

    useEffect(() => {
        bookingService
            .getFloorPlans()
            .then((result) => {
                setFloorPlans(result);
                dispatch(setDeskBookingFloorplansState(result.floorPlans));
                setFloorPlanId(0);
                setSelectedFloorPlanName('');
            })
            .catch((err) => {
                const response: BaseResponse = err.response?.data;
                response?.errors?.forEach((error) => {
                    dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />));
                });
            });

        bookingService
            .getLocations()
            .then((result) => {
                setLocations(result);
            })
            .catch((err) => {
                const response: BaseResponse = err.response?.data;
                response?.errors?.forEach((error) => {
                    dispatch(showErrorMessage(<BookingErrorMessage name={error.name} />));
                });
            });
    }, [dispatch, setFloorPlanId, setFloorPlans, setLocations, setSelectedFloorPlanName]);

    const handleOnSelectedDesks = (cols: number, rows: number) => {
        onSelected(cols, rows);
        setTimeout(() => {
            setShowDrawDesks(false);
        }, 100);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFloorPlanChangeWrapper = (event: SelectChangeEvent<string>) => {
        handleFloorPlanChange(
            event,
            floorPlans.floorPlans
        ).then(() => { /* Do something if needed */ });
    };

    const handleExport = () => {
        const data = {
            floorPlanName: selectedFloorPlanName,
            floorPlanId,
            locationId,
            floorPlanFeatures
        };
        const json = JSON.stringify(data, null, 2);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selectedFloorPlanName}.json`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target;
        const file = input.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = e.target?.result as string;
                    const data = JSON.parse(json);
                    setSelectedFloorPlanName(data.floorPlanName);
                    setFloorPlanId(data.floorPlanId);
                    setLocationId(data.locationId);
                    setFloorPlanFeatures(data.floorPlanFeatures);
                    const zones = data.floorPlanFeatures.filter((feature: BookingFeature) => feature.typeId === 8);
                    setZones(zones);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <Grid container spacing={2} alignItems="center" p="10px">
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="floorplan-select-label">Floor Plan</InputLabel>
                    <Select
                      labelId="floorplan-select-label"
                      id="floorplan-select"
                      value={String(floorPlanId)}
                      onChange={(event: SelectChangeEvent<string>) => {
                            handleFloorPlanChangeWrapper(event)
                        }}
                      label="Floor Plan"
                    >
                        <MenuItem value="">Clear</MenuItem>
                        {floorPlans.floorPlans.map((plan) => (
                            <MenuItem key={plan.id} value={plan.id.toString()}>
                                {plan.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="floorplanName"
                  inputMode="text"
                  label="Floor Plan Name"
                  variant="outlined"
                  fullWidth
                  value={selectedFloorPlanName}
                  onChange={(e) => setSelectedFloorPlanName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="location-select-label">Location</InputLabel>
                    <Select
                      labelId="location-select-label"
                      id="location-select"
                      value={String(locationId)}
                      onChange={(event: SelectChangeEvent<string>) => {
                            setLocationId(Number(event.target.value))
                        }}
                      label="Location"
                    >
                        <MenuItem value="">Clear</MenuItem>
                        {locations.locations.map((location) => (
                            <MenuItem key={location.id} value={location.id.toString()}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                {/* Group for file operations */}
                <GroupBox label="Editor Operations">
                    <Grid item>
                        <Tooltip title="Save">
                            <Button
                              variant="contained"
                              style={{ margin: 0, padding: '12px' }}
                              onClick={() => saveFloorPlan()}
                            >
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Delete">
                            <Button
                              variant="contained"
                              style={{ margin: 0, padding: '12px' }}
                              onClick={deleteFloorplan}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <ButtonGroup variant="contained">
                            <Tooltip title="Import/Export">
                                <Button
                                  style={{ margin: 0, padding: '12px' }}
                                  onClick={handleMenuClick}
                                >
                                    <ImportExportIcon />
                                </Button>
                            </Tooltip>
                            <Button
                              size="small"
                              aria-controls={anchorEl ? 'split-button-menu' : undefined}
                              aria-expanded={anchorEl ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={handleMenuClick}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Menu
                          id="split-button-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                            <DropdownMenuItem onClick={() => {
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = 'application/json';
                                input.onchange = (e) => handleImport(e as unknown as React.ChangeEvent<HTMLInputElement>);
                                input.click();
                            }}
                            >
                                Import
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={handleExport}>
                                Export
                            </DropdownMenuItem>
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Tooltip title={loadViewer ? "Edit" : "Preview"} placement="right">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setLoadViewer(!loadViewer)}
                              sx={{
                                    padding: '12px',
                                    minWidth: 'auto',
                                    width: '46px',
                                    height: '46px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {loadViewer ? <EditIcon /> : <PreviewIcon />}
                            </Button>
                        </Tooltip>
                    </Grid>
                </GroupBox>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    {/* Group for adding elements to the floorplan */}
                    <GroupBox label="Add Elements">
                        <Grid item>
                            <Tooltip title="Add Desks" placement="top">
                                <Button
                                  variant="contained"
                                  style={{ margin: 0, padding: '12px' }}
                                  onMouseLeave={() => setShowDrawDesks(false)}
                                  onClick={() => setShowDrawDesks(true)}
                                >
                                    <DeskIcon />
                                    {showDrawDesks && <DrawDesks onSelected={handleOnSelectedDesks} />}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Office" placement="top">
                                <Button variant="contained" style={{ margin: 0, padding: '12px' }} onClick={handleAddOffice}>
                                    <MeetingRoomIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Wall" placement="top">
                                <Button variant="contained" style={{ margin: 0, padding: '12px' }} onClick={handleAddWall}>
                                    <StraightenIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Zone" placement="top">
                                <Button variant="contained" style={{ margin: 0, padding: '12px' }} onClick={addZone}>
                                    <CropDinIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Text Label" placement="top">
                                <Button
                                  variant="contained"
                                  style={{ margin: 0, padding: '12px' }}
                                  onClick={handleAddTextLabel}
                                >
                                    <TextFieldsIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Snap to Grid" placement="top">
                                <Switch
                                  id="snapToGridWhileDragging"
                                  checked={snapOptions.snapDrag}
                                  onChange={() => {
                                        setSnapOptions((current) => ({
                                            ...current,
                                            snapDrag: !snapOptions.snapDrag,
                                        }));
                                        handleSnapToGridWhileDraggingChange();
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </GroupBox>
                    {/* Group for actions on multiple selected desks */}
                    <GroupBox label="Desk Selection Actions">
                        <Grid item>
                            <Tooltip title="Edit Selected Features">
                                <Button
                                  variant="contained"
                                  style={{ margin: 0, padding: '12px' }}
                                  onClick={handleOpenBulkEditDialog}
                                >
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Copy Selected Features">
                                <Button
                                  variant="contained"
                                  style={{ margin: 0, padding: '12px' }}
                                  onClick={handleCopyPasteSelectedFeatures}
                                >
                                    <ContentCopyIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Delete Selected Features">
                                <Button
                                  variant="contained"
                                  style={{ margin: 0, padding: '12px' }}
                                  onClick={handleDeleteSelectedFeatures}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </GroupBox>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Toolbar;