import {useEffect} from 'react';
import {useDispatch, batch} from 'react-redux';
import {
    setDeskBookingDashboardResults,
    setDeskBookingFeaturesForFloorplan, setDeskBookingFloorplansState,
    setDeskBookingLoading, setDeskBookingLocationsState,
    setDeskBookingOpenFloorplan,
    setDeskBookingShowFloorplan,
} from "../../../../redux/reducers/deskBookingReducer";
import {
    setDeskBookingSearchParams,
    setDeskBookingSearchResults
} from "../../../../redux/reducers/deskBookingSearchReducer";
import {useDesignerContext} from "../DesignerContext";

export const useFloorplanSync = (
    setLoadViewer: (load: boolean) => void
) => {
    const {
        floorPlanFeatures
    } = useDesignerContext();
    const dispatch = useDispatch();
    useEffect(() => {
        if (floorPlanFeatures.length > 0) {
            batch(() => {
                dispatch(setDeskBookingLoading(false))
                dispatch(setDeskBookingOpenFloorplan(true));
                dispatch(setDeskBookingShowFloorplan(true));
                dispatch(setDeskBookingLocationsState([{id: 1, name: 'test'}]));
                dispatch(setDeskBookingFloorplansState([{
                    id: 1,
                    locationId: 1,
                    name: 'tEST',
                }]));
                dispatch(setDeskBookingFeaturesForFloorplan(floorPlanFeatures));
                dispatch(setDeskBookingSearchParams({
                    date: new Date('2023-10-01T00:00:00Z'),
                    from: '09:00:00',
                    to: '17:00:00',
                    floorplanId: 1,
                    locationId: 1,
                    autoSearch: false
                }))
                dispatch(setDeskBookingSearchResults([
                    {
                        id: 1,
                        employeeId: 123,
                        employeeName: 'John Doe',
                        displayName: 'John Doe',
                        floorPlanId: 1,
                        featureId: 1,
                        featureType: {id: 1, label: 'Desk'},
                        fromDate: '2023-10-01',
                        fromTime: '09:00:00',
                        toDate: '2023-10-01',
                        toTime: '17:00:00',
                        createdByDate: '2023-09-30',
                        status: 'Accepted',
                        statusId: 1,
                    }]))
                dispatch(setDeskBookingDashboardResults([{
                    createdBy: {
                        id: 1,
                        name: 'Admin',
                    },
                    id: 1,
                    employeeId: 123,
                    displayName: 'John Doe',
                    fromDate: '2023-10-01',
                    fromTime: '09:00:00',
                    toDate: '2023-10-01',
                    toTime: '17:00:00',
                    statusId: 1,
                    feature: {
                        id: 1,
                        floorPlanId: 1,
                        floorPlanCategoryId: 1,
                        locationId: 1,
                        typeId: 1,
                        type: 'Desk',
                        statusId: 1,
                        status: 'Available',
                        label: 'Desk 1',
                        x: 100,
                        y: 100,
                        width: 50,
                        height: 50,
                        fill: '#BFCBC8',
                        borderRadius: 10,
                        ports: [
                            {
                                id: 1,
                                featureId: 1,
                                x: '10',
                                y: '10',
                                radius: 5,
                                fill: '#FF0000',
                            },
                        ],
                        createdById: 1,
                        additionalInfo: '',
                    },
                    floorPlan: {
                        id: 1,
                        locationId: 1,
                        name: 'Main Office',
                    },
                    zone: {
                        id: 1,
                        name: 'Zone A',
                        floorPlanId: 1,
                        belongsTo: 1,
                        additionalInfo: '{"svg": [{"type": "polygon", "points": [[100, 100], [200, 100], [200, 200], [100, 200]]}]}'
                    },
                    department: 'Engineering',
                    checkInOut: [
                        {
                            id: 1,
                            bookingId: 1,
                            userId: 123,
                            type: 'CheckIn',
                            actionDate: new Date('2023-10-01T09:00:00Z'),
                            createdDate: new Date('2023-10-01T09:00:00Z'),
                            anyIssues: 'None',
                        },
                        {
                            id: 2,
                            bookingId: 1,
                            userId: 123,
                            type: 'CheckOut',
                            actionDate: new Date('2023-10-01T17:00:00Z'),
                            createdDate: new Date('2023-10-01T17:00:00Z'),
                            anyIssues: 'None',
                        },
                    ],
                }]))
            });
            console.log('Dispatched featuresForFloorplan', floorPlanFeatures);
        }
    }, [floorPlanFeatures, dispatch, setLoadViewer]);
};  